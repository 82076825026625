import React, {useContext, useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { Card, Button } from 'react-bootstrap';
import { Row, Col, Alert, Schema, Form } from 'rsuite';
import {StringType, NumberType, BooleanType } from "schema-typed";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_PATH } from "../constants";
import Axios from "axios";
import { AuthContext } from "../../auth/AuthContext";



function TopicsEditAlumno(props) {
  const history = useHistory();
  const formRef = useRef() 
  const [tags, setTags] = useState([])
  const [validar, setValidar] = useState(false)
  const { user } = useContext(AuthContext);
  useEffect(() => {
      hacer()
  }, [])
 
    const hacer = () => {
        if(props.setHome[0].themes === null){
            setValidar(false)
            history.replace({ pathname: "/topics-alumno"})
        }else{
            setValidar(true)
            const {themes} = props.setHome[0]
            const array = themes === null ? null: themes.split(",");
            setTags(array)
        }
    }
   function UrlPath() {
        if(validar === false){
            return (
                <div></div>
         );  
        }else{
            const stringData = tags === null ? null: tags.join(', ')
         
           const onSubmit = async () => { 
             if(stringData === ''){
               Alert.warning("enter at least one topic.",1000)
               return
             }else{
                try { 
                     let topic = await Axios.post(`${BASE_PATH}/Account/update/user`,
                     {
                       "id":  user.userId,
                       "themes": stringData
                     })
                         if (topic.status === 200){ 
                          props.editSucces()
                          Alert.success('edited topics')
                     }
                 } catch (error) { 
                   console.log(error);
                 }
               }
             }
             return (
                 <>
                    <Row>
                        <Col>
                        <h6>Setting your interests will allow for a better experience <br/> throughout the app.</h6> 
                        <h5 className="heavy text-secundario">Press enter for each topic</h5>
                        </Col>
                        <Col > 
                            <ReactTagInput 
                                placeholder="."
                                tags={tags} 
                                onChange={(newTags) => setTags(newTags)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center mt-4">
                                <Button   onClick={onSubmit}>
                                    Done
                                </Button>
                        </Col>
                    </Row>
                </>
             );
        }
   }

 
  return (
         <div>            
             <UrlPath />
         </div>
    
  )
}
    
    export default TopicsEditAlumno;