import { Rate } from 'antd';
import React, { useContext, useState, useEffect  } from 'react'
import { Panel, Row, Col,  Avatar, Alert } from 'rsuite';
import { Badge, Button } from 'react-bootstrap';
import { BsFillCalendarFill, BsFillCaretRightFill } from "react-icons/bs";

import Location from '../../assets/images/location.gif'
import { AuthContext } from '../../auth/AuthContext';
import Axios from 'axios';
import { BASE_PATH } from '../constants';
import Modal from 'antd/lib/modal/Modal';
import { Session } from './Session';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const StartSessionLoad = (props) => {
    
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [isModalVisibled, setIsModalVisibled] = useState(false);
  const [appointmentt, setAppointment] = useState([]);
  const [studenId, setStudenId] = useState();
  
  const close = () => {
    setIsModalVisibled(false);
  } 
  
  const handleSessionID = () => {
    setIsModalVisibled(true);
  };  
  const handleOk = () => {
    setIsModalVisibled(false);
  };
  const handleCancel = () => {
    setIsModalVisibled(false);
  };
  const [estado, setEstado] = useState(true)
  const handleOktutor = () => {
    setIsModalVisibled(false);
  };
  useEffect(() => {
    idEstudiante()
    setInterval(()=>{
      abrirmodal();    
  }, 10000)
  return new Promise(() => {
    setTimeout(() => {
        regresarHome()
    }, 65000);
});
  }, []) 


  const idEstudiante = () => {
    setStudenId(props.location.alumno.alumno)
  }
  console.log(props.location.llamada.llamada.appointmentId)
   const abrirmodal = async () => { 
     try {
      let resid = await Axios.get(`${BASE_PATH}/user/${user.userId}`)
      if (resid.status === 200) {
        let res = await Axios.get(`${BASE_PATH}/User/appointment/pending/${resid.data.studentId}`, {
        })
        if (res.status === 200) {
            setAppointment(res.data.filter(el=>el.statusAppointmentId === 2 && el.appointmentId === props.location.llamada.llamada.appointmentId))
             if(res.data.filter(el=>el.statusAppointmentId === 2  && el.appointmentId === props.location.llamada.llamada.appointmentId).length > 0){
              handleSessionID()
             }else{
               
             }
          
      }
      }
    } catch (e) {
        console.log(e)
    }
   
                  
   } 
   const regresarHome = async () => {
    try {
     let resid = await Axios.get(`${BASE_PATH}/user/${user.userId}`)
     if (resid.status === 200) {
       let res = await Axios.get(`${BASE_PATH}/User/appointment/pending/${resid.data.studentId}`, {
       })
       if (res.status === 200) {
           setAppointment(res.data.filter(el=>el.statusAppointmentId === 0 && el.appointmentId === props.location.llamada.llamada.appointmentId))
            if(res.data.filter(el=>el.statusAppointmentId === 0 && el.appointmentId === props.location.llamada.llamada.appointmentId).length > 0){
                Alert.warning('no response from the tutor')
                history.replace({ pathname: "/home-alumno", estado: {estado: estado}})
            }else{
              
            }
         
     }
     }
   } catch (e) {
       console.log(e)
   }
  
                 
  } 

    return (
        <div
        className="d-flex justify-content-center
        d-flex align-items-center">
            <section
            style={{
              marginTop: '15%'
            }}
            className="text-center">
                <h5>
                Waiting for a response from
                </h5> 
                <h2
                className="heavy">
                    ...
                </h2>
                <Row>
                    <Col>
                        <img src={Location} height='300px'/>
                    </Col>
                </Row>
                <h4>
                "Try to practice your
                  pronunciation with
                  complex words"
                </h4>
            </section>    
            <Modal 
                visible={isModalVisibled} 
                onOk={handleOk} 
                onCancel={handleCancel} 
                footer={null}>
                  <Session   setStudenId={studenId}  setAppointment={appointmentt[appointmentt.length - 1] && appointmentt[appointmentt.length - 1]} />
            </Modal>
        </div>
    )
}

export default StartSessionLoad;