import axios from 'axios';
import React, {useRef, useState} from 'react'
import {  Card, Button} from 'react-bootstrap';
import { Form, FormControl, Row, Col, Grid, FormGroup, ControlLabel, Schema, Alert} from 'rsuite';
import {StringType, DateType} from "schema-typed";
import Connect from '../../assets/images/connect.gif'
import { BASE_PATH } from '../constants';

export const ConnectAcademy = (props) => {
    const formRef = useRef()
    const initForm = {
        email: "",
        code: ""
    }
    const [form, setForm] = useState(initForm)
  
    const model = Schema.Model({
        email:StringType().isRequired("Campo obligatorio."),
        code:StringType().isRequired("Campo obligatorio."),
    })
    const onSubmit = async () => {
        if(!formRef.current.check()){
            Alert.warning("Campos requeridos vacíos.",1000)
            return
        }else{
            try {
                let res = await axios.put(`https://apiargo.azurewebsites.net/api/Academy/student/validation`,{
                    "appUserId": props.user,
                    "email": form.email,
                    "code": form.code
                })
                if (res.status === 200) {
                    Alert.success("Connect.", 1000)
                    props.onSuccess()
                }
            } catch (e) {
                console.log(e)
                if(e.response){
                    if (e.response.status === 404 || e.response.status === 400) {
                      //  console.log(e.response.data.message);
                        Alert.warning(e.response.data.message,2000)
                    }
    
                }
            }
        }
    }

    const sendCode = async() => {
        console.log('Prueba de codigo')
        try {
            let result = await axios.get(`${BASE_PATH}/Academy/student/getToken/${props.user}`)
            console.log(result)
            if(result.status === 200){
                Alert.success('The code has been sent to your email, please check', 2000)
            }else{
                Alert.warning('Server failure, try again')
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Form fluid
        ref={formRef}
        fluid
        onChange={(formValue)=>setForm(formValue)}
        model={model}
        formValue={form}   
        > 
            <Row>
                <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
                    <img src={Connect} height='200px'/>
                </Col>
                <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center ">
                     <h2
                    className="heavy">
                    Connect
                    </h2>
                </Col>
                <Col md={24} className="">
                     <h4>
                          Your results will be shared with an organization
                    </h4>
                </Col>
            </Row>
            <Grid fluid>
                <FormGroup>
                    <Row>
                        <Col md={24}>
                           <ControlLabel>Email</ControlLabel>
                           <FormControl name={"email"} size="lg"  placeholder={"Email"} />
                        </Col>
                    </Row>
                </FormGroup>
                <Row>
                    <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center " >
                         <Button
                             className="heavy" 
                            onClick={sendCode}>
                            Get a Code
                        </Button>
                    </Col>
                </Row>
                <FormGroup>
                    <Row  className="mt-4" style={{
                        padding:'2rem',
                        background:'rgba(0, 0, 0, 0.049)',
                        borderRadius:'20px'
                        }}>
                        <Col md={24} >
                           <ControlLabel>Code</ControlLabel>
                           <FormControl name={"code"} size="lg"  placeholder={""} />
                        </Col>
                    </Row>
                </FormGroup> 
                <Row>
                    <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center " >
                        <Button
                            className="heavy" type="primary" onClick={onSubmit} >
                            Verify and Register
                        </Button>
                    </Col>
                </Row>
            </Grid>
        </Form>
    )
}
