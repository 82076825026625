import React from 'react'
import { Row, Col, Image, Badge, Navbar, Nav, Button } from 'react-bootstrap';
import * as moment from "moment";
import { Alert } from 'rsuite';
import axios from 'axios';
import { BASE_PATH } from '../constants';

export const CancelarLlamada = (props) => {
    const fecha = moment(props.setEliminar.date).format('lll');

    const onCancel = async() => {
        try{
            let cancelar = await axios.put(`${BASE_PATH}/Tutor/appointment/${props.setEliminar.appointmentId}`,
            {
                "appointmentId": props.setEliminar.appointmentId,
                "status": 4,
            })
            if (cancelar.status === 200){ 
                Alert.success('Appointment canceled.', 1000)
                props.onSuccess()
            }
        }catch (e) {
            console.log(e)
            Alert.warning('server error.', 1000)
        }
    }
    return (
        <div>
           <Row className="mt-4 rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
                <Col className="d-flex align-items-center"
                lg="2" md="auto" xs="3">
                    <Image src={props.setEliminar.imageProfile&&props.setEliminar.imageProfile}
                    roundedCircle 
                    className="avatar"/>
                </Col>
                <Col lg="8"  xs="7">
                    <p>
                        <h6
                        style={{
                            fontSize: '15px',
                            color: 'black'}}>
                            {fecha}
                        </h6>
                        <br/>
                        <h6
                        style={{
                            marginTop:'-1rem',
                            fontSize: '12px',
                            color: 'grey'}}>
                            {props.setEliminar.name}  
                        </h6>
                        </p>
                </Col>
            </Row> 
            <Row className="mt-4 rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
                <Col md="auto" xs="12" className="">
                    <h6>Do you want to cancel this appointment?</h6>
                </Col>
            </Row>
            <Row className="mt-4 rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
                <Col md="auto" xs="6" >
                    <Button onClick={ () => props.cerrar()}>Go Back</Button>
                </Col>  
                <Col md="auto" xs="6" >
                    <Button  className="color" onClick={onCancel}>Cancel</Button>
                </Col>
            </Row>
        </div>
    )
}
