import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Axios from 'axios';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import { Icon, Grid } from 'rsuite';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import logo from '../../assets/images/logow.png';
import './VideoCall.css'
import * as moment from "moment";
import Participant from './Participant';
import Video from 'twilio-video';
import { BASE_PATH } from '../constants';
import Countdown from 'react-countdown-now';
import { render } from '@testing-library/react';

export const CallTutorTwilio = (props) => {
    const history = useHistory()
    const [room, setRoom] = useState(null)
    const [mute, setMute] = useState(false)
    const [video, setVideo] = useState(false)
    const [end, setEnd] = useState(false)
    const [participants, setParticipants] = useState([]);
    const [mensaje, setMensaje] = useState(false);
    const roomName = props.location.room.room;
    const [token, setToken] = useState(props.location.state.data.token)
    const [hang, setHang] = useState(false)
    const [p, setP] = useState(false)
   /* const renderer = ({ hours, minutes, seconds, completed }) => {
        if (minutes <= 59 && minutes >=1) {
            setP('1')
             return <span>Restan {minutes}:{seconds} en llamada.</span>;
         
         
        }else if(minutes === 0 && seconds === 1){     
            setP('2')
            return <span>Restan {minutes}:{seconds} en llamada.</span>;
        }else {
            // Render a countdown
            return <></>;
        }
    };*/
    let seconds = 5;
    const secondPassed = () => seconds--;
    const unMuteVideo = () => {
        if (room != null) {
            room.localParticipant.videoTracks.forEach(videoTracks => {
                setVideo(false)
                videoTracks.track.enable();
            });
        }
    }

    const unMuteAudio = () => {
        if (room != null) {
            room.localParticipant.audioTracks.forEach(audioTrack => {
                setMute(false)
                audioTrack.track.enable();
            });
        }
    }

    const muteAudio = () => {
        if (room != null) {
            console.log(room)
            room.localParticipant.audioTracks.forEach(audioTrack => {
                setMute(true)
                audioTrack.track.disable();
            });
        }
    }
    const [pp, setPpp] = useState(false)
    useEffect(() => {

        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
            setPpp()
        };
        const participantDisconnected = async participant => { 
          
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );  
            setToken(null)
            try {
                let tutor = await Axios.put(`${BASE_PATH}/Tutor/appointment/${props.location.id.appointment.appointmentId}`,
                    {
                        "appointmentId": props.location.id.appointment.appointmentId,
                        "status": 2
                    },{
                    })
                    if(tutor.status===200){
                let conversations = await Axios.post(`${BASE_PATH}/Conversations/sessions`,
                    {
                        "urlStreamming": props.location.state.data.token,
                        "duration": 60,
                        "studentId": props.location.id.appointment.studentId,
                        "tutorId": props.location.tutor.idTutor.tutorId,
                        "AppointmentId": props.location.id.appointment.appointmentId
                    })
                if (conversations.status === 200) {
                    console.log('Me desconecto HOLA')
                    setRoom(currentRoom => {
                        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                            currentRoom.localParticipant.tracks.forEach(trackPublication => {
                                trackPublication.track.stop();
                            });
                            console.log('Me desconecto')
                            currentRoom.disconnect();
                            // window.location.href="dashboard-doctor#/"
                            return null;
                        } else {
                            return currentRoom;
                        }
                    });
                    history.replace({ pathname: "/review-tutor", alumno: { alumno: props.location.id.appointment }, tutor: { tutor: props.location.tutor.idTutor }, idConversacion: { id: conversations.data } })
                }
            }
            } catch (error) {
                console.log(error);
            }
        };
        Video.connect(token, {
            name: roomName
        }).then(room => {
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        });

        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(trackPublication => {
                        trackPublication.track.stop();
                    });
                    console.log('Me desconecto')
                    currentRoom.disconnect();
                    // window.location.href="dashboard-doctor#/"
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
    }, [roomName, hang]);

    useEffect(() => {
       /*  return new Promise(() => {
             setTimeout(() => {
                 endCall()
             }, 3600000);
         }); */
        return() =>{
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(trackPublication => {
                        trackPublication.track.stop();
                    });
                    console.log('Me desconecto 2')
                    currentRoom.disconnect();
                    // window.location.href="dashboard-doctor#/"
                    return null;
                } else {
                    return currentRoom;
                }
            });
        }
    }, [])

   

    const endCall = async() => {
        try {
            let conversations = await Axios.post(`${BASE_PATH}/Conversations/sessions`,
                {
                    "urlStreamming": props.location.state.data.token,
                    "duration": 60,
                    "studentId": props.location.id.appointment.studentId,
                    "tutorId": props.location.tutor.idTutor.tutorId,
                    "AppointmentId": props.location.id.appointment.appointmentId
                })
            if (conversations.status === 200) {
                history.replace({ pathname: "/review-tutor", alumno: { alumno: props.location.id.appointment }, tutor: { tutor: props.location.tutor.idTutor }, idConversacion: { id: conversations.data } })
            }
        } catch (error) {
            console.log(error);
        }
    }
    const remoteParticipants = participants.map(participant => (
        <Participant key={participant.sid} participant={participant} />
    ));
    const navigate = (path) => {
        window.location.href = "dashboard-doctor#/" + path
    }

    const onSubmit = async () => {
        try {
            let conversations = await Axios.post(`${BASE_PATH}/Conversations/sessions`,
                {
                    "urlStreamming": props.location.state.data.token,
                    "duration": 60,
                    "studentId": props.location.id.appointment.studentId,
                    "tutorId": props.location.tutor.idTutor.tutorId,
                    "AppointmentId": props.location.id.appointment.appointmentId
                })
            if (conversations.status === 200) {
                history.replace({ pathname: "/review-tutor", alumno: { alumno: props.location.id.appointment }, tutor: { tutor: props.location.tutor.idTutor }, idConversacion: { id: conversations.data } })
            }
        } catch (error) {
            console.log(error);
        }
    }
    const regresar = (data) => {
        console.log(data)
    }
    const [hora, setHora] = useState(moment('2021-04-15T18:47:00').format('YYYY-MM-DDTHH:mm:ss')) 
    return (
        <div
            style={{ background: 'black' }} 
            className="vh-100">
                <Col>
                    
                    <p className="text-center mensaje" 
                        style={{color: "#566573", paddingBottom: 2,
                        paddingTop: 2,
                        paddingLeft: 5,
                        paddingRight: 5,
                        backgroundColor: "black"}}> 
                           {/*<Countdown 
                                date={Date.parse(hora) + 3600000} 
                                renderer={renderer} 
                            /> */} 
                        </p>
                    {
                        remoteParticipants.length > 0
                            ? <div className="remote-participants">{remoteParticipants}
                                <div className="local-participant tutor">
                                    {room ? (
                                        <Participant
                                            key={room.localParticipant.sid}
                                            participant={room.localParticipant}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            : <div className="remote-participants">
                                {room ? (
                                    <Participant
                                        key={room.localParticipant.sid}
                                        participant={room.localParticipant}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>

                    }

                </Col>
                <Col>
                    <div class="content">
                        <Row>
                            <Col>
                                <img src={logo}
                                    className="logo__call" alt="logo" />
                            </Col>
                            <Col>
                              {/*  <p className="
                        d-flex justify-content-center
                        d-flex align-items-center">
                                   
                                    <Button
                                        onClick={onSubmit}
                                        className="
                        call__back heavy">
                                        <i
                                            style={{ fontSize: '20px' }}
                                            className="icon-home"></i>  End Call
                        </Button>
                </p> */}
                            </Col>
                        </Row>
                    </div>
                </Col>

        </div>
    )
}