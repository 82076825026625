import { Rate } from 'antd';
import React, { useContext, useState, useEffect  } from 'react'
import { Panel, Row, Col,  Avatar, Alert } from 'rsuite';
import { Badge, Button } from 'react-bootstrap';
import { BsFillCalendarFill, BsFillCaretRightFill } from "react-icons/bs";

import Location from '../../assets/images/location.gif'
import { AuthContext } from '../../auth/AuthContext';
import Axios from 'axios';
import { BASE_PATH } from '../constants';
import Modal from 'antd/lib/modal/Modal';
import { Session } from './Session';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Accepted from '../../assets/images/accepted.mp4'

const StartSessionGo = (props) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);  
  const [data, setData] = useState([]);
  const [tutor, setTutor] = useState([]);

  useEffect(() => {
      dataUser()
        const onSubmit = setTimeout(async() => {
          console.log('preuba')
          try { 
              const input =   props.location.setAppointment.setAppointment.firstName;
              const [firtsname] = input.split(' ');
              const inputA =   props.location.setAppointment.setAppointment.lastName;
              const [lastname] = inputA.split(' ');
  
              const inputfirstName =  props.location.data.data.firstName;
              console.log(inputfirstName)
              const [firtsnameA] = inputfirstName.split(' ');
              const inputlastName = props.location.data.data.lastName;
              const [lastnameA] = inputlastName.split(' ');
              console.log(firtsnameA, lastnameA)
              let apiRtc = await Axios.post(`https://apiargo.azurewebsites.net/api/Twilio/token`,
                  {
                      //"userId": data.id,
                      "username": `${props.location.data.data.firstName} ${props.location.data.data.lastName}`,
                      // "userEmail": data.email,
                       "room": `${firtsname}${lastname}_${firtsnameA}${lastnameA}`,
                  })
              if (apiRtc.status === 200) {
                  let tiempo  = await Axios.get(`${BASE_PATH}/Conversations/stop/${apiRtc.data.logId}`)
                      if(tiempo.status===200){
                          history.replace({ pathname: "/call", tiempo: {tiempo: tiempo.data}, state: { data: apiRtc.data }, id: { appointment: props.location.setAppointment.setAppointment }, studenId: {studenId:  props.location.studenId.studenId }, room: {room: `${firtsname}${lastname}_${firtsnameA}${lastnameA}`} })
                   }
                  //setApiRtc(apiRtc.data);
                 }
          } catch (error) {
              console.log(error);
          }
        }, 4000);
      //hacer()
  }, [])

  const dataUser = async () => {
      try {
              let tutor = await Axios.get(`${BASE_PATH}/Tutor/home/${props.location.setAppointment.setAppointment.tutorId}`)
                  setTutor(tutor.data[tutor.data.length - 1])
          } catch (e) {
          console.log(e)
      }
  }
    return (
        <div
        className="d-flex justify-content-center
        d-flex align-items-center">
          <div className="ubicacion__map shadow">
            {tutor.city}
          </div>
          <div className="videoTag">
            <video  autoPlay muted className="videoTag__video">
                <source src={Accepted} type='video/mp4' />
            </video>
          </div>
          
        
        </div> 
    )
}

export default StartSessionGo;