import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from '../componentes/Home/Home'
import Tuto1Tutor from '../componentes/Login/Tuto1'
import Tuto2Tutor from '../componentes/Login/Tuto2'
import Tuto3Tutor from '../componentes/Login/Tuto3'
import RegisterTutor from '../componentes/Login/Register'
import Topics from '../componentes/Login/Topics'
import Calendar from '../componentes/Calendar/Calendar'
import Log from '../componentes/Perfil/Log'

import Tuto1Alumno from '../componentes/LoginAlumno/Tuto1'  
import Tuto2Alumno from '../componentes/LoginAlumno/Tuto2'  
import Tuto3Alumno from '../componentes/LoginAlumno/Tuto3' 
import RegisterAlumno from '../componentes/LoginAlumno/Register'
import RegisterPassAlumno from '../componentes/LoginAlumno/RegisterPass'
import HomeAlumno from '../componentes/HomeAlumno/Home'
import CalendarAlumno from '../componentes/CalendarAlumno/Calendar'
import DatePicker from '../componentes/CalendarAlumno/Datepicker'   
import LogAlumno from '../componentes/PerfilAlumno/Log'
import Frame from '../componentes/Frame/Frame';
import ReviewTutor from '../componentes/Frame/ReviewTutor';
import ReviewAlumno from '../componentes/Frame/ReviewAlumno';
import ReviewApp from '../componentes/Frame/ReviewApp';
import CallTutor from '../componentes/Frame/CallTutor';
import StartSessionPerfil from '../componentes/HomeAlumno/StartSessionPerfil';
import StartSessionLoad from '../componentes/HomeAlumno/StartSessionLoad';
import { VerificarDatos } from '../componentes/Login/VerificarDatos';
import TopicsAlumno from '../componentes/LoginAlumno/TopicsAlumno';
import ReviewAppTutor from '../componentes/Frame/ReviewAppTutor';
import { HelpMessage } from '../componentes/HomeAlumno/HelpMessage';
import { HelpMessageTutor } from '../componentes/Home/HelpMessageTutor';
import Help from '../componentes/HomeAlumno/Help';
import HelpTutor from '../componentes/Home/Help';
import { AuthContext } from '../auth/AuthContext';
import { CallTutorTwilio } from '../componentes/Frame/CallTutorTwilio';
import { TwilioFrame } from '../componentes/Frame/TwilioFrame';
import Terms from '../componentes/Home/Terms';
import Licencia from '../componentes/Home/Licencia';
import StartSessionGo from '../componentes/HomeAlumno/StartSessionGo';



export const HomeRoutes = () => {
    const {user} = useContext(AuthContext)
    function UrlPath() {
        console.log(user)
        if(user.role){
            if(user.role[0] === 'Student'){
                return (
                    <Switch>
                        <Route path="/tuto1-alumno" exact component={Tuto1Alumno} />
                        <Route path="/tuto2-alumno" exact component={Tuto2Alumno} />
                        <Route path="/tuto3-alumno" exact component={Tuto3Alumno} />
                        <Route path="/register-alumno" exact component={RegisterAlumno} />
                        <Route path="/registerp-alumno" exact component={RegisterPassAlumno} />
                        <Route path="/home-alumno" exact component={HomeAlumno} />
                        <Route path="/calendar-alumno" exact component={CalendarAlumno} />
                        <Route path="/log-alumno" exact component={LogAlumno} />
                        <Route path="/review-alumno" exact component={ReviewAlumno} />
                        <Route path="/topics-alumno" exact component={TopicsAlumno} />
                        <Route path="/start-perfil" exact component={StartSessionPerfil} />
                        <Route path="/start-load" exact component={StartSessionLoad} />
                        <Route path="/review-app" exact component={ReviewApp} />
                        <Route path="/datepicker" exact component={DatePicker} />
                        <Route path="/help" exact component={Help} />
                        <Route path="/help-message" exact component={HelpMessage} />
                        <Route path="/call" exact component={TwilioFrame}/>
                        <Route path="/startgo" exact component={StartSessionGo}/>
                        <Route path="/terms" exact component={Terms} />
                        <Route path="/licencia" exact component={Licencia} />
                        <Redirect to="/home-alumno" />
                    </Switch>
                );
            }else if(user.role[0] === 'Tutor'){
                return (
                    <Switch>
                        <Route path="/register-tutor" exact component={RegisterTutor} />
                        <Route path="/tuto1-tutor" exact component={Tuto1Tutor} />
                        <Route path="/tuto2-tutor" exact component={Tuto2Tutor} />
                        <Route path="/tuto3-tutor" exact component={Tuto3Tutor} />
                        <Route path="/review-tutor" exact component={ReviewTutor} />
                        <Route path="/topics" exact component={Topics} />
                        <Route path="/help-tutor" exact component={HelpTutor} />
                        <Route path="/help-message-tutor" exact component={HelpMessageTutor} />
                        <Route path="/review-app-tutor" exact component={ReviewAppTutor} />
                        <Route path="/calendar" exact component={Calendar} />
                        <Route path="/log" exact component={Log} />
                        <Route path="/verify_data" exact component={VerificarDatos}/>
                        <Route path="/call-tutor" exact component={CallTutorTwilio}/>
                        <Route path="/terms" exact component={Terms} />
                        <Route path="/licencia" exact component={Licencia} />
                        <Route path="/" exact component={Home} />
                        <Redirect to="/" />
                    </Switch>
                );
            } 
        }
    }

    return (
        <>
           <UrlPath />
        </>
    )
}
