import axios from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormControl, Form, Col, Row, Alert, Schema } from 'rsuite'
import Credit from '../../assets/images/credit.gif'
import { AuthContext } from '../../auth/AuthContext'
import { BASE_PATH } from '../constants'
import {StringType} from "schema-typed";

export const Recharge = (props) => {
    
    const { user } = useContext(AuthContext);
    const formRef = useRef() 
    const initForm = {
        token: "",
    }
    const [form, setForm] = useState(initForm)
    const model = Schema.Model({
        token:StringType().isRequired("required."),
    })
    const onsubmit = async() => {
        if(!formRef.current.check()){
            Alert.warning("Empty required fields.",1000)
            return
        }else{
            try{
                let res = await axios.post(`${BASE_PATH}/User/recharge`,
                {
                    "appUserId": user.userId,
                    "walletId": props.setWallet.id,
                    "token": form.token
                })
                if (res.status === 200) {
                    props.recargar()
                    Alert.success('recharge done');
                   
                }
            }catch (e) {
                console.log(e)
                if(e.response){
                    if (e.response.status === 404 || e.response.status === 400) {
                      //  console.log(e.response.data.message);
                        Alert.warning(e.response.data.message,2000)
                    }
    
                }
            }
        }
    }
    return (
        <>
            <Row>
                <Form 
                ref={formRef}           
                onChange={(formValue)=>setForm(formValue)}
               fluid
               model={model}
               formValue={form}>
                    <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center">
                    <img src={Credit} height='200px'/>
                    </Col>
                    <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center">
                        <h2
                        className="heavy text-secundario">
                            Add Conversation
                        </h2>
                    </Col>
                    <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center">
                        <h4  className="bold">
                        Number of conversations
                        </h4>
                    </Col>
                    <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center mt-4">
                        <h5>

                            <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center">
                            <FormControl 
                            autocomplete="off"
                            name={"token"}  
                            placeholder={""}
                            />
                            </Col>
                            <label >We will send you a payment link to add more conversations to you wallet</label>
                        </h5>
                    </Col>
                </Form> 
            </Row>
            <Row>
                 <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center">
                    <Button 
                    className="heavy" type="primary" onClick={onsubmit}>
                        Send Link
                    </Button>
                </Col>
            </Row>
        </>
    )
}
