import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { Navbar, Nav, Button, Badge, Col, Row } from 'react-bootstrap';
import { Modal } from 'antd';

//Importar Recursos
import '../../assets/css/icons/css/fontello.css';
import logo from '../../assets/images/logo.png'
import { AuthContext } from '../../auth/AuthContext';
import Axios from 'axios';
import { BASE_PATH } from '../constants';
import { NewConversationTutor } from './NewConversationTutor';


const NavTutor = () => {

        const divStyle = {
            color: 'blue',
            height: '6rem',
            textAlign: 'left',
            padding: '12px',
            background: 'rgb(247, 247, 247)',
            marginTop: '15px',
            borderRadius: '100px'
        };
        const divStyleP = {
            color: 'blue',
            height: '6rem',
            textAlign: 'left',
            padding: '12px',
            background: 'rgb(247, 247, 247)',
            marginTop: '15px',
            borderRadius: '100px',
            cursor: 'pointer'
        };
        
        
        const containerStyle = {
            maxWidth: '100%',
            margin: '0 auto',
        }

    const [data, setData] = useState([]);
    const [tutorId, setTutorId] = useState([]);
    const { user } = useContext(AuthContext);
    const [isModalVisibled, setIsModalVisibled] = useState(false);

    const onSucces = () => {
      setIsModalVisibled(false);
      setIsModalVisibleH(false);
      obtenerLista()
    }
    const handleSessionID = () => {
      setIsModalVisibled(true);
    };  
    const handleOk = () => {
      setIsModalVisibled(false);
    };
    const handleCancel = () => {
      setIsModalVisibled(false);
    };
    const [isModalVisibleH, setIsModalVisibleH] = useState(false);

    const showModalH = () => {
        setIsModalVisibleH(true);
    };
  
    const handleOkH = () => {
        setIsModalVisibleH(false);
    };
  
    const handleCancelH = () => {
        setIsModalVisibleH(false);
    };

    const [postList, setPostList] = useState({
        list: [1,2,3,4]
    }); 
    // tracking on which page we currently are
    const [page, setPage] = useState(1);
    // add loader refrence 
    const loader = useRef(null);

    useEffect(() => {
         var options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0
         };
        // initialize IntersectionObserver
        // and attaching to Load More div
         const observer = new IntersectionObserver(handleObserver, options);
         if (loader.current) {
            observer.observe(loader.current)
         }

    }, []);


    useEffect(() => {
        // here we simulate adding new posts to List
        const newList = postList.list.concat([1,1,1,1]);
        setPostList({
            list: newList
        })
    }, [page])

    // here we handle what happens when user scrolls to Load More div
   // in this case we just update page variable
    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {   
            setPage((page) => page + 1)
        }
    }
    
    const { user:{ name }, dispatch } = useContext(AuthContext);
    const history = useHistory();
    const [home, setHomes] = useState([])

    useEffect(() => {
        fetchData();
        obtenerLista()
    }, [])

    const fetchData = async () => {
        try {
            let res = await Axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
            if (res.status === 200) {
                let userid = await Axios.get(`${BASE_PATH}/Tutor/${user.userId}`)
                if (userid.status === 200) {
                    let tutorid = await Axios.get(`${BASE_PATH}/Tutor/home/${userid.data.tutorId}`)
                    if (tutorid.status === 200) {
                        let commissions = await Axios.get(`${BASE_PATH}/Tutor/home/${userid.data.tutorId}`)
                        if (commissions.status === 200) {
                            setHomes(tutorid.data)
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const navigate=(path)=>{
        window.location.href="tutor#/"+path
    }
    const obtenerLista = async () => {
        try{
            let res = await Axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
                if (res.status === 200) {
                    let resid = await Axios.get(`${BASE_PATH}/Tutor/${user.userId}`)
                    setTutorId(resid.data)
                    if (resid.status === 200) {
                        let data = await Axios.get(`${BASE_PATH}/Tutor/tickets/${resid.data.tutorId}`)
                        if (data.status === 200) {
                         setData(data.data)
                         }
                    }
                }
        }catch (e){
            console.log(e)
        }
    }
    const chat = (post) => {
        history.replace({ pathname: "/help-message-tutor", chatId: {chatId: post}, id: {id: tutorId}})
    }
    return (
        <div>
            <div className="d-none d-md-none d-lg-block">
                <Navbar className="navbar shadow-lg fixed-top">
                    <div className="container ">
                    <Navbar.Brand to="/">
                    <img src={logo} 
                        className="logo" alt="logo"/>
                        <text style={{fontSize:'18px', marginLeft:'1rem', color:'grey'}}>Practice is everything</text>
                    </Navbar.Brand>
                        <Nav className="d-flex justify-content-end heavy">
                            <Link 
                                to="/" 
                                className="btn-navbar-first
                                d-flex align-items-center">
                                <i className="demo-icon-first icon-home2"></i> Home
                            </Link>
                            <Link 
                                to="/calendar" 
                                className="btn-navbar-first
                                d-flex align-items-center">
                                    <i className="demo-icon-first icon-calendar"></i> Calendar
                            </Link>
                            <Link 
                                to="/log" 
                                className="btn-navbar-first
                                d-flex align-items-center">
                                    <i className="demo-icon-first icon-log"></i>Log
                            </Link>
                        </Nav>
                    </div>
                </Navbar>
                
                <div className="fixed-bottom copyright">
                    <h6>Copyright 2021, Argo.</h6>
                </div>
                <Link
                to="/help-tutor">
                <Button 
                className="btn btn__help heavy" type="submit">
                        ?
                </Button>
                </Link>
                <Modal title="We are here to help!" 
                    footer={false}
                    visible={isModalVisibleH} 
                    onOk={handleOkH} 
                    onCancel={handleCancelH}>
                        Here you'll find your support history. <br/>
                        You can also create a new case by clicking the button below.     
                        <Button
                        className="
                        btn-show-alumno  
                        shadow-lg">
                        <h4
                            className="text-white heavy">
                            Start a new conversation
                        </h4>
                        </Button>     
                        <div 
                        style={{
                            width: '100%'
                        }} 
                        className="dropdown__divider">
                        </div>  
                        <Col
                        className="log__data_history">
                            <h4>History</h4>
                            <div style={containerStyle}>
                            <div className="post-list">
                                {
                                    data.map((post, index) => {
                                        if(post.status === 'In Progress'){
                                            return (
                                            <div key={index} className="post shadow-sm" style={divStyleP} onClick={() => chat(post)} >
                                                <Row>
                                                    <Col lg="8"  xs="6">
                                                        <Badge pill variant="warning">
                                                            {post.status}
                                                        </Badge>
                                                        <p>
                                                            <text
                                                            style={{
                                                                fontSize: '18px',
                                                                color: 'black'}}>
                                                                ... / ...
                                                            </text>
                                                            <br/>
                                                            <text
                                                            style={{
                                                                fontSize: '12px',
                                                                color: 'grey'}}>
                                                                Case No. {post.ticketId}
                                                            </text>
                                                            </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            )
                                        }else{
                                            return (<div key={index} className="post shadow-sm" style={divStyleP} onClick={() => chat(post)}>
                                            <Row>
                                                <Col lg="8"  xs="6">
                                                    <Badge pill variant="warning">
                                                        {post.status}
                                                    </Badge>
                                                    <p>
                                                        <text
                                                        style={{
                                                            fontSize: '18px',
                                                            color: 'black'}}>
                                                            ... / ...
                                                        </text>
                                                        <br/>
                                                        <text
                                                        style={{
                                                            fontSize: '12px',
                                                            color: 'grey'}}>
                                                             Case No. {post.ticketId}
                                                        </text>
                                                        </p>
                                                </Col>
                                            </Row>
                                            
                                        </div>)
                                        }
                                        
                                    })
                                }
                                <div className="loading" ref={loader}>
                                </div>
                            </div>
                        </div>
                        </Col>  
                </Modal>
            </div>

            <div className="d-block d-sm-none d-none 
            d-sm-block d-md-none text-center">
                <Navbar className="navbar shadow-lg fixed-bottom d-flex justify-content-center">
                    <Nav>
                        <Link 
                            to="/home" 
                            className="btn-navbar-first
                            d-flex align-items-center">
                            <i className="demo-icon-first icon-home2"></i> 
                      
                        </Link>
                        <Link 
                            to="/calendar" 
                            className="btn-navbar-first
                            d-flex align-items-center">
                                <i className="demo-icon-first icon-calendar"></i> 
                        </Link>
                        <Link 
                            to="/log" 
                            className="btn-navbar-first
                            d-flex align-items-center">
                                <i className="demo-icon-first icon-log"></i>
                        </Link>
                    </Nav>
                </Navbar>
            </div>
            <Modal 
                title='New conversation'
                visible={isModalVisibled} 
                onOk={handleOk} 
                onCancel={handleCancel} 
                footer={null}>
                 <NewConversationTutor setTutorId={tutorId} onSucces={onSucces} />
            </Modal>
        </div>

        
        )
    }
    
    export default NavTutor;