import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { Navbar, Nav, Button, Badge, Col, Row } from 'react-bootstrap';
import { Modal } from 'antd';
import { Icon, InputGroup } from 'rsuite';

//Importar Recursos
import '../../assets/css/icons/css/fontello.css';
import logo from '../../assets/images/logo.png'
import { AuthContext } from '../../auth/AuthContext';
import Axios from 'axios';
import { BASE_PATH } from '../constants';
import { NewConversationTutor } from './NewConversationTutor';


const NavTerms = () => {

        const divStyle = {
            color: 'blue',
            height: '6rem',
            textAlign: 'left',
            padding: '12px',
            background: 'rgb(247, 247, 247)',
            marginTop: '15px',
            borderRadius: '100px'
        };
        const divStyleP = {
            color: 'blue',
            height: '6rem',
            textAlign: 'left',
            padding: '12px',
            background: 'rgb(247, 247, 247)',
            marginTop: '15px',
            borderRadius: '100px',
            cursor: 'pointer'
        };
        
        
        const containerStyle = {
            maxWidth: '100%',
            margin: '0 auto',
        }

    const [data, setData] = useState([]);
    const [tutorId, setTutorId] = useState([]);
    const { user } = useContext(AuthContext);
    const [isModalVisibled, setIsModalVisibled] = useState(false);

    const onSucces = () => {
      setIsModalVisibled(false);
      setIsModalVisibleH(false);
      obtenerLista()
    }
    const handleSessionID = () => {
      setIsModalVisibled(true);
    };  
    const handleOk = () => {
      setIsModalVisibled(false);
    };
    const handleCancel = () => {
      setIsModalVisibled(false);
    };
    const [isModalVisibleH, setIsModalVisibleH] = useState(false);

    const showModalH = () => {
        setIsModalVisibleH(true);
    };
  
    const handleOkH = () => {
        setIsModalVisibleH(false);
    };
  
    const handleCancelH = () => {
        setIsModalVisibleH(false);
    };

    const [postList, setPostList] = useState({
        list: [1,2,3,4]
    }); 
    // tracking on which page we currently are
    const [page, setPage] = useState(1);
    // add loader refrence 
    const loader = useRef(null);

    useEffect(() => {
         var options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0
         };
        // initialize IntersectionObserver
        // and attaching to Load More div
         const observer = new IntersectionObserver(handleObserver, options);
         if (loader.current) {
            observer.observe(loader.current)
         }

    }, []);


    useEffect(() => {
        // here we simulate adding new posts to List
        const newList = postList.list.concat([1,1,1,1]);
        setPostList({
            list: newList
        })
    }, [page])

    // here we handle what happens when user scrolls to Load More div
   // in this case we just update page variable
    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {   
            setPage((page) => page + 1)
        }
    }
    
    const { user:{ name }, dispatch } = useContext(AuthContext);
    const history = useHistory();
    const [home, setHomes] = useState([])

    useEffect(() => {
        fetchData();
        obtenerLista()
    }, [])

    const fetchData = async () => {
        try {
            let res = await Axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
            if (res.status === 200) {
                let userid = await Axios.get(`${BASE_PATH}/Tutor/${user.userId}`)
                if (userid.status === 200) {
                    let tutorid = await Axios.get(`${BASE_PATH}/Tutor/home/${userid.data.tutorId}`)
                    if (tutorid.status === 200) {
                        let commissions = await Axios.get(`${BASE_PATH}/Tutor/home/${userid.data.tutorId}`)
                        if (commissions.status === 200) {
                            setHomes(tutorid.data)
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const navigate=(path)=>{
        window.location.href="tutor#/"+path
    }
    const obtenerLista = async () => {
        try{
            let res = await Axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
                if (res.status === 200) {
                    let resid = await Axios.get(`${BASE_PATH}/Tutor/${user.userId}`)
                    setTutorId(resid.data)
                    if (resid.status === 200) {
                        let data = await Axios.get(`${BASE_PATH}/Tutor/tickets/${resid.data.tutorId}`)
                        if (data.status === 200) {
                         setData(data.data)
                         }
                    }
                }
        }catch (e){
            console.log(e)
        }
    }
    const chat = (post) => {
        history.replace({ pathname: "/help-message-tutor", chatId: {chatId: post}, id: {id: tutorId}})
    }
    const homeb = () => {
        history.replace({ pathname: "/"})
    }

    return (
        <div>
            <div className="d-none d-md-none d-lg-block">
                <Navbar className="navbar shadow-lg fixed-top">
                    <div className="container ">
                    <InputGroup.Addon  onClick={homeb}
                    className="help__back">
                                    <Icon 
                                    style={{fontSize:'1.5rem'}}
                                    icon="arrow-left-line"/>
                            </InputGroup.Addon>   
                            
                    <Navbar.Brand to="/">
                    <img src={logo} 
                                        style={{
                                            marginLeft:'1rem'
                                        }}
                        className="logo" alt="logo"/>
                    </Navbar.Brand>
                        <Nav className="d-flex justify-content-end heavy">
                            <Link 
                                to="/terms" 
                                className="btn-navbar-first
                                d-flex align-items-center">
                                Términos y Condiciones
                            </Link>
                            <Link 
                                to="/licencia" 
                                className="btn-navbar-first
                                d-flex align-items-center">
                                     Licencia Mundial para Usuarios Finales
                            </Link>
                        </Nav>
                    </div>
                </Navbar>
                
                <div className="fixed-bottom copyright">
                    Copyright 2021, Argo.
                </div>
               
            </div>

            <div className="d-block d-sm-none d-none 
            d-sm-block d-md-none text-center">
                <Navbar className="navbar shadow-lg fixed-bottom d-flex justify-content-center">
                    <Nav>
                    <InputGroup.Addon  onClick={homeb}
                    className="help__back">
                                    <Icon 
                                    style={{fontSize:'1.5rem'}}
                                    icon="arrow-left-line"/>
                            </InputGroup.Addon>   
                        <Link 
                            to="/terms" 
                            className="btn-navbar-first
                            d-flex align-items-center">
                            Términos
                      
                        </Link>
                        <Link 
                            to="/licencia" 
                            className="btn-navbar-first
                            d-flex align-items-center">
                                Licencia
                        </Link>
                    </Nav>
                </Navbar>
            </div>
            <Modal 
                title='New conversation'
                visible={isModalVisibled} 
                onOk={handleOk} 
                onCancel={handleCancel} 
                footer={null}>
                 <NewConversationTutor setTutorId={tutorId} onSucces={onSucces} />
            </Modal>
        </div>

        
        )
    }
    
    export default NavTerms;