export const login = async (credentials) => {
	// return fetch('https://localhost:44357/api/Account/login', {
	return fetch('https://apiargo.azurewebsites.net/api/Account/login', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(credentials),
	})
		.then((data) => data.json())
		.catch((err) => err)
}

export const registerStudent = async (credentials) => {
	// return fetch('https://localhost:44357/api/Account/register/student', {
	return fetch(
		'https://apiargo.azurewebsites.net/api/Account/register/student',
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(credentials),
		}
	).then((data) => data.json())
}

export const registerTutor = async (credentials) => {
	return fetch('https://apiargo.azurewebsites.net/api/Account/register/tutor', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(credentials),
	}).then((data) => data.json())
}
