import axios from 'axios'
import React, { useContext, useRef, useState, useEffect } from 'react'
import { Button, FormGroup } from 'react-bootstrap'
import { FormControl, Form, Col, Row, Alert, Schema, InputGroup, ControlLabel } from 'rsuite'
import Credit from '../../assets/images/credit.gif'
import { AuthContext } from '../../auth/AuthContext'
import { BASE_PATH } from '../constants'
import { StringType } from "schema-typed";

export const Recharge = (props) => {

    const { user } = useContext(AuthContext);
    const formRef = useRef()
    const initForm = {
        token: "",
    }

    const [ convert, setConvert ] = useState('0.00')

    const [form, setForm] = useState(initForm)
    const model = Schema.Model({
        token: StringType().isRequired("required."),
    })
    const onsubmit = async () => {
        if (!formRef.current.check()) {
            Alert.warning("Empty required fields.", 1000)
            return
        } else {
            try {
                let resid = await axios.get(`${BASE_PATH}/user/${user.userId}`)
                console.log(resid)
                if (resid.status === 200) {
                    let res = await axios.post(`${BASE_PATH}/Visa/getLink`,
                        {
                            "appUserId": user.userId,
                            "studentId": resid.data.studentId,
                            "credits": parseInt(form.token, 10)
                        })
                    console.log(res)
                    if (res.status === 200) {
                        props.recargar()
                        Alert.success('Done', 2000);

                    }else{
                        Alert.error('Fallo en el servidor, vuelva a intentar mas tarde',2000)
                    }
                }else{
                    Alert.warning('Fallo en el sistema, vuelva a intentar mas tarde', 2000)
                }

            } catch (e) {
                console.log(e)
                if (e.response) {
                    if (e.response.status === 404 || e.response.status === 400) {
                        //  console.log(e.response.data.message);
                        Alert.warning(e.response.data.message, 2000)
                    }

                }
            }
        }
    }

    const convertcredit = () => {
        
        if(form.token.trim() != ''){
            let calculate = form.token * 15
            
            setConvert(calculate + '.00')
        }else{
            setConvert('0.00')
        }
    }

    useEffect(()=>{
        convertcredit()
    },[form])

    return (
        <>
            <Row>
                <Form
                    ref={formRef}
                    onChange={(formValue) => setForm(formValue)}
                    fluid
                    model={model}
                    formValue={form}>
                    <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center">
                        <img src={Credit} height='200px' />
                    </Col>
                    <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center"
                    style={{marginBottom:'-1rem'}}>
                        <h2
                            className="heavy text-secundario">
                            Number of credits
                        </h2>
                    </Col>
                    <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center mb-4 bold"
                    style={{fontSize:'18px'}}>
                    <label >Each credit is worth USD $ 15.00, with which you can get a session of one hour.</label>
                    </Col>
                    <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center">
                    <InputGroup Fluid>
                        <FormControl
                        autocomplete="off"
                        name={"token"}
                        placeholder={"Enter the number of sessions you want"}/>
                            <InputGroup.Addon>
                                USD$ {convert}
                            </InputGroup.Addon>
                        </InputGroup>
                    </Col>
                    <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center"
                    style={{color:'grey'}}>
                                <label >We will send you a paylink to your email, with this you can buy more credits to your wallet</label>
                            </Col>
                </Form>
            </Row>
            <Row>
                <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center">
                    <Button
                        className="heavy" type="primary" onClick={onsubmit}>
                        Send Link
                    </Button>
                </Col>
            </Row>
        </>
    )
}
