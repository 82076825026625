import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Panel, Row, Col, Avatar, TagGroup, Tag } from 'rsuite';
import { BASE_PATH } from '../constants';
import Frame from '../Frame/Frame';
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext';
import { Button } from 'react-bootstrap';
import { Rate } from 'antd';

export const SessionCalendar = (props) => {

    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [appointmentt, setCita] = useState([])
    const [apirtc, setApiRtc] = useState([]);
    const [data, setData] = useState([]);
 
    useEffect(() => {
        dataUser()
      //  fetchData()
    }, [])

    const dataUser = async () => {
        try {
            let res = await Axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
            if (res.status === 200) {
                setData(res.data)
            }
            } catch (e) {
            console.log(e)
        }
    }
    const onSubmit = async (post) => {
        history.replace({ pathname: "/startgo", setAppointment: {setAppointment: props.setCita},  studenId: {studenId: props.setStudenId.studentId }, data: { data: data }})
    }
   /* const onSubmit = async () => {
        try { 
            const input =   props.setCita.firstName;
            const [firtsname] = input.split(' ');
            const inputA =   props.setCita.lastName;
            const [lastname] = inputA.split(' ');

            const inputfirstName = data.firstName;
            const [firtsnameA] = inputfirstName.split(' ');
            const inputlastName = data.lastName;
            const [lastnameA] = inputlastName.split(' ');
            
            console.log(firtsnameA, lastnameA)
            let apiRtc = await Axios.post(`https://apiargo.azurewebsites.net/api/Twilio/token`,
                {
                  //  "userId": data.id,
                    "username": `${data.firstName} ${data.lastName}`,
                   // "userEmail": data.email,
                    "room": `${firtsname}${lastname}_${firtsnameA}${lastnameA}`,
                })
            if (apiRtc.status === 200) {
                let tiempo  = await Axios.get(`${BASE_PATH}/Conversations/stop/${apiRtc.data.logId}`)
                if(tiempo.status===200){
                history.replace({ pathname: "/call", tiempo: {tiempo: tiempo.data}, state: { data: apiRtc.data }, id: { appointment: props.setCita }, studenId: {studenId: props.setStudenId.studentId }, room: {room: `${firtsname}${lastname}_${firtsnameA}${lastnameA}`} })
            }
        }
        } catch (error) {
            console.log(error);
        }
    }*/
    const {themes} = props.setCita;
    const array = themes === null ? null: themes.split(",");
    return (
        <div
        style={{
            marginTop:'3rem'
        }}
        className="d-flex justify-content-center text-center d-flex align-items-center"> 
                <Row className=" mt-4">
                    <Col md={24} sm={12}>
                        <h2>
                        It's time for your session with {props.setCita.name}!
                        </h2>
                        <Row>
                            <Col>
                                <Avatar src={props.setCita.imageProfile&&props.setCita.imageProfile} size="lg"
                                circle
                                className="avatar__startsession"
                             />
                            </Col>
                        </Row>
                        <h5>
                        {props.setCita.name}
                        </h5>
                        <Rate disabled defaultValue={props.setCita.rating}/>
                        <br/>
                        <br/>
                             <TagGroup>
                                {array && array.map((item, index) => (
                                    <Tag color="yellow"
                                        key={index}
                                    >
                                        {item}
                                    </Tag>
                                    ))}
                              </TagGroup>
                        <br/>
                        <br/>
                            <Row>
                                <Col >
                                    <Button 
                                    className="heavy" onClick={onSubmit} >Let´s Go!</Button>
                                </Col>
                            </Row>
                    </Col>
                </Row>
        </div>
    )
}
