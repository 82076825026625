import React, { } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Switch } from 'antd';
import 'antd/dist/antd.css';

//Importar Recursos
import tuto1 from '../../assets/images/tuto1.png'

const Login = () => {
    return (
        <div className="d-flex justify-content-center
        d-flex align-items-center vh-100 login">
            <Card className="tutos border-0">
                <Card.Body>
                    <div
                    className="text-center">
                    <i className="demo-icon icon-stars text-warning"
                    style={{fontSize: '5rem'}}></i>
                    <br/>
                        <h1>
                            Bright sky
                        </h1>
                        <h4>
                            The brighter, the better! Level up by keeping a 
                            perfect record streak. Once your constellation is 
                            lit you'll get a reward.
                        </h4>
                    <br/>
                        <Link
                        to="/tuto3-tutor">
                            <Button className="btn__tuto_next" >
                                Next
                            </Button>
                        </Link>
                    </div>
                </Card.Body>
            </Card> 
        </div>
        )
    }
    
    export default Login;