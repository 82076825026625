import { Rate } from 'antd'
import React, {useState} from 'react'
import { Row, Col, Avatar, Form, DatePicker, Alert  } from 'rsuite'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import './StartSession.css';
import { Button } from 'react-bootstrap';
import * as moment from "moment";
import axios from 'axios';
import { BASE_PATH } from '../constants';
 

export const BookSessions = (props) => {
    const [date, setDate] = useState('')    
    const [fecha, setFecha] = useState('')   
    
    
    const onChange = (date) => {
        setFecha(date._d)
       // console.log(date._d);
       var strDateTime = date._d;
       var myDate = new Date(strDateTime); 
    //   console.log(myDate.toLocaleString())

    var fechaInicio = moment(myDate).format('YYYY-MM-DDTHH:mm:ss\\Z')
   // console.log(fechaInicio)

     //  console.log( new Date(date._d).toUTCString() )
      // console.log(new Date(date.subtract(6,'h')).toISOString())
     //   console.log(date.add(1,'d').toISOString())
       setDate(fechaInicio)
    }

    const onSubmit = async (post) => {
        if(date === ''){
            Alert.warning("Empty required fields.",1000)
            return
          }else{
            try {
                let tutor = await axios.post(`${BASE_PATH}/User/appointment`,
                {
                    "appUserId": props.setData.id,
                    "studentId": props.setStudenId.studentId,
                    "appTypeId": 3,
                    "date": date,
                    "status": 1,
                    "tutorId": props.setLlamada.id,
                    
                },{
                })
                    if(tutor.status===200){
                        setDate('')
                        setFecha('')
                        props.onSuccess();
                        Alert.success('scheduled session');
                    }
            }catch (e) { 
                console.log(e)
                if(e.response){
                    if (e.response.status === 404 || e.response.status === 400) {
                      //  console.log(e.response.data.message);
                        Alert.warning(e.response.data.message,2000)
                    }
    
                }
                
            }
        }
    }
    
    return (
        <div>

            <Row>
                <Col md={24} className="text-center bold">
                    <h4>Book a Session</h4>
                    <h6>Choose Date and Time</h6>
                    <Datetime open={true} input={false} onChange={onChange} dateFormat="YYYY-MM-DD" timeFormat="hh:mm" />
                </Col>
            </Row>

             <Row className="mt-4">
                <Col md={24} className="d-flex justify-content-center text-center d-flex align-items-center">
                        <Button   onClick={onSubmit}>
                            Request this session
                        </Button>
                </Col>
             </Row>
        </div>
    )
}
