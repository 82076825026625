import React, { } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Switch } from 'antd';
import 'antd/dist/antd.css';

//Importar Recursos
import tuto1 from '../../assets/images/tuto1.png'

const Login = () => {
    return (
        <div className="d-flex justify-content-center
        d-flex align-items-center vh-100 login">
            <Card className="tutos border-0">
                <Card.Body>
                    <div
                    className="text-center">
                    <img src={tuto1} 
                    className="logo__login" alt="logo" 
                    style={{marginBottom: '1rem'}}/>
                    <br/>
                        <h1>
                            Always On
                        </h1>
                        <h4>
                            Stay available as long as you can to get matches 
                            more often and level up faster!
                        </h4>
                    <br/>
                    <Link
                    to="/tuto2-tutor">
                        <Button className="btn__tuto_next">
                            Next
                        </Button>
                    </Link>
                    </div>
                </Card.Body>
            </Card> 
        </div>
        )
    }
    
    export default Login;