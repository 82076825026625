import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Video from 'twilio-video';
import './VideoCall.css'
import Axios from 'axios';
import * as moment from "moment";
import { Button, Row, Col } from 'react-bootstrap';
import { Icon } from 'rsuite';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import logo from '../../assets/images/logow.png';
import Participant from './Participant';
import { BASE_PATH } from '../constants';
import Countdown from 'react-countdown-now';

export const TwilioFrame = (props) => {
    const history = useHistory()
    const [room, setRoom] = useState(null)
    const [mute, setMute] = useState(false)
    const [video, setVideo] = useState(false)
    const [participants, setParticipants] = useState([]);
    const roomName = props.location.room.room;
    const token = props.location.state.data.token;
    const [hang, setHang] = useState(false)
    const [p, setP] = useState(false)
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (minutes <= 10 && minutes >=1) {
            setP("1")
             return <span>Restan {minutes}:{seconds} en llamada.</span>;
         
         
        }else if(minutes === 0 && seconds === 1){     
            return <span>Restan {minutes}:{seconds} en llamada.</span>;
        }else if(minutes === 0 && seconds === 0){     
            setP("2")
            return <span>Restan {minutes}:{seconds} en llamada.</span>;
        }else {
            // Render a countdown
            return <></>;
        }
    };
    useEffect(() => {
        cortar()
    }, [p])
    const cortar = async() => {
        if(p === "2") {
            onSubmit()
        }
    }
    const muteVideo = () => {
        if (room != null) {
            room.localParticipant.videoTracks.forEach(videoTracks => {
                setVideo(true)
                videoTracks.track.disable();
            });
        }
    }

    const unMuteVideo = () => {
        if (room != null) {
            room.localParticipant.videoTracks.forEach(videoTracks => {
                setVideo(false)
                videoTracks.track.enable();
            });
        }
    }

    const unMuteAudio = () => {
        if (room != null) {
            room.localParticipant.audioTracks.forEach(audioTrack => {
                setMute(false)
                audioTrack.track.enable();
            });
        }
    }

    const muteAudio = () => {
        if (room != null) {
            console.log(room)
            room.localParticipant.audioTracks.forEach(audioTrack => {
                setMute(true)
                audioTrack.track.disable();
            });
        }
    }

    useEffect(() => {
        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };
        const participantDisconnected = participant => {
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };
        Video.connect(token, {
            name: roomName
        }).then(room => {
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        });
        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(trackPublication => {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    // window.location.href="dashboard-doctor#/"
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
    }, [roomName, hang]);

   
    
    const remoteParticipants = participants.map(participant => (
        <Participant key={participant.sid} participant={participant}  tiempo={props.location.tiempo.tiempo.startTime}/>
    ));
    const navigate = (path) => {
        window.location.href = "dashboard-doctor#/" + path
    }
    useEffect(() => {
      /*  return new Promise(() => {
            setTimeout(() => {
                endCall()
            }, 3600000);
        }); */
   }, [])
   const endCall = async() => {
    try {
        let conversations = await Axios.post(`${BASE_PATH}/Conversations/sessions`,
            {
                "urlStreamming": props.location.state.data.token,
                "duration": 60,
                "studentId": props.location.studenId.studenId,
                "tutorId": props.location.id.appointment.tutorId,
                "AppointmentId": props.location.id.appointment.appointmentId
            })
        if (conversations.status === 200) {
            history.replace({ pathname: "/review-alumno", alumno: { alumno: props.location.id.appointment }, tutor: { tutor: props.location.id.appointment.tutorId }, idConversacion: { id: conversations.data }, idestudiante: { id: props.location.studenId.studenId } })
        }
    } catch (error) {
        console.log(error);
    }
   }
    const onSubmit = async () => {
        try {
            let tutor = await Axios.put(`${BASE_PATH}/Tutor/appointment/${props.location.id.appointment.appointmentId}`,
                    {
                        "appointmentId": props.location.id.appointment.appointmentId,
                        "status": 2
                    },{
                    })
                    if(tutor.status===200){
            let conversations = await Axios.post(`${BASE_PATH}/Conversations/sessions`,
                {
                    "urlStreamming": props.location.state.data.token,
                    "duration": 60,
                    "studentId": props.location.studenId.studenId,
                    "tutorId": props.location.id.appointment.tutorId,
                    "AppointmentId": props.location.id.appointment.appointmentId
                })
            if (conversations.status === 200) {
                history.replace({ pathname: "/review-alumno", alumno: { alumno: props.location.id.appointment }, tutor: { tutor: props.location.id.appointment.tutorId }, idConversacion: { id: conversations.data }, idestudiante: { id: props.location.studenId.studenId } })
            }
        }
        } catch (error) {
            console.log(error);
        }
    }
    const [hora, setHora] = useState(moment(props.location.tiempo.tiempo.startTime).format('YYYY-MM-DDTHH:mm:ss'))
    return (
        <div
            style={{ background: 'black' }}
            className="vh-100">
            <Col>
                <p className="text-center mensaje" 
                    style={{color: "#566573", paddingBottom: 10,
                    paddingTop: 10,
                    paddingLeft: 30,
                    paddingRight: 30,
                    backgroundColor: "black"}}> 
                        <Countdown 
                            date={Date.parse(hora) + 3600000} 
                            renderer={renderer} 
                        />
                    </p>
                {
                    remoteParticipants.length > 0
                        ? <div className="remote-participants">{remoteParticipants}
                            <div className="local-participant student">
                                {room ? (
                                    <Participant
                                        key={room.localParticipant.sid}
                                        participant={room.localParticipant}
                                       
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        : <div className="remote-participants">
                            {room ? (
                                <Participant
                                    key={room.localParticipant.sid}
                                    participant={room.localParticipant}
                                />
                            ) : (
                                ''
                            )}
                        </div>

                }

            </Col>
                <Col>
                    <div class="content">
                        <Row>
                            <Col>
                                <img src={logo}
                                    className="logo__call" alt="logo" />
                            </Col>
                            <Col>
                                <p className="
                        d-flex justify-content-center
                        d-flex align-items-center">

                                    <Button
                                        onClick={onSubmit}
                                        className="
                        call__back heavy">
                                        <i
                                            style={{ fontSize: '20px' }}
                                            className="icon-home"></i>  End Call
                        </Button>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
        </div>

    )
}


