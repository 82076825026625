import React, { } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Switch } from 'antd';
import 'antd/dist/antd.css';

//Importar Recursos
import tuto1 from '../../assets/images/tuto1.png'

const Login = () => {
    return (
        <div className="d-flex justify-content-center
        d-flex align-items-center vh-100 login">
            <Card className="tutos border-0">
                <Card.Body>
                    <div
                    className="text-center">
                    <br/>
                        <h1>
                            Welcome
                        </h1>
                        <h4>
                            Thank you for joining us on this journey. 
                            Let´s set you up for success!
                        </h4>
                    <br/>
                    <Link
                    to="/tuto2-alumno">
                        <Button className="btn__tuto_next">
                            Next
                        </Button>
                    </Link>
                    </div>
                </Card.Body>
            </Card> 
        </div>
        )
    }
    
    export default Login;