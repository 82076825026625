// export const BASE_PATH = "https://localhost:44357/api"
export const BASE_PATH = "https://apiargo.azurewebsites.net/api"
export const IDAppFacebook = "162540325983660"
export const IDAppGoogle = "183694740314-97bpdhdh5dlenipstis7n4ae6gmjapim.apps.googleusercontent.com"

export const EMPTY_FORM = {
    type: "warning",
    title: "Campos Vacios.",
    description: "Verifica que todos los campos esten llenos y escritos correctamente."
}

export const ACCOUNT_CREATED = {
    type: "success",
    title: "El usuario se creo correctamente.",
    description: "Ya puedes utilizar tu cuenta"
}
export const INVALID_CREDENTIALS = {
    type: "warning",
    title: "Las credenciales son incorrectas",
    description: "Por favor verfica tus credenciales."
}
export const EMAIL_TAKEN = {
    type: "warning",
    title: "Correo electronico no disponible.",
    description: "El correo ya se encuentra asociado a una cuenta, intenta con otro correo electronico."
}

export const ERROR_SERVER = {
    type: "error",
    title: "Error interno.",
    description: "Algo salio mal, por favor intentar mas tarde."
}