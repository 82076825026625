import React, { useEffect, useState, useRef, useContext } from 'react';
import { Row, Col, Image, Card, Button, Badge, Nav, Navbar } from 'react-bootstrap';
import { Rate, Modal } from 'antd';
import { NewConversation } from '../Navbars/NewConversation';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

//Importar Recursos
import Estudiante from '../Navbars/NavAlumno';
import Avatar from '../../assets/images/avatar2.jpg';
import { AuthContext } from '../../auth/AuthContext';
import { BASE_PATH } from '../constants';
import * as moment from "moment";
import axios from 'axios';
import { TagGroup } from 'rsuite';
import { Recharge } from './Recharge';
import Great from '../../assets/images/great.gif'

const divStyle = {
    color: 'blue',
    height: '6rem',
    textAlign: 'left',
    padding: '12px',
    background: 'rgb(247, 247, 247)',
    marginTop: '15px',
    borderRadius: '100px'
};


const containerStyle = {
    maxWidth: '100%',
    margin: '0 auto',
}

const Help = () => {
    const divStyle = {
        color: 'blue',
        height: '6rem',
        textAlign: 'left',
        padding: '12px',
        background: '#179BBF',
        marginTop: '15px',
        borderRadius: '100px'
    };
    const divStyleP = {
        color: 'white',
        height: '6rem',
        textAlign: 'left',
        padding: '12px',
        background: '#179BBF',
        marginTop: '15px',
        borderRadius: '100px',
        cursor: "pointer"
    };
    
    
    const containerStyle = {
        maxWidth: '100%',
        margin: '0 auto',
    }

    const history = useHistory();

    const [isModalVisibled, setIsModalVisibled] = useState(false);

    const onSucces = () => {
      setIsModalVisibled(false);
      setIsModalVisibleH(false);
      obtenerLista()
    }
    const handleSessionID = () => {
      setIsModalVisibled(true);
    };  
    const handleOk = () => {
      setIsModalVisibled(false);
    };
    const handleCancel = () => {
      setIsModalVisibled(false);
    };

    const [data, setData] = useState([]);
    const [studentId, setStudentId] = useState([]);
    const { user } = useContext(AuthContext);
    const [isModalVisibleH, setIsModalVisibleH] = useState(false);

    const showModalH = () => {
        setIsModalVisibleH(true);
    };
  
    const handleOkH = () => {
        setIsModalVisibleH(false);
    };
  
    const handleCancelH = () => {
        setIsModalVisibleH(false);
    };


    const navigate=(path)=>{
        window.location.href="student#/"+path
    }

    const [postList, setPostList] = useState({
        list: [1,2,3,4]
    }); 
    // tracking on which page we currently are
    const [page, setPage] = useState(1);
    // add loader refrence 
    const loader = useRef(null);

    useEffect(() => {
         var options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0
         };
        // initialize IntersectionObserver
        // and attaching to Load More div
         const observer = new IntersectionObserver(handleObserver, options);
         if (loader.current) {
            observer.observe(loader.current)
         }

    }, []);


    useEffect(() => {
        obtenerLista()
        // here we simulate adding new posts to List
        const newList = postList.list.concat([1,1,1,1]);
        setPostList({
            list: newList
        })
    }, [page])

    // here we handle what happens when user scrolls to Load More div
   // in this case we just update page variable
    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {   
            setPage((page) => page + 1)
        }
    }
    const obtenerLista = async () => {
            try{
                let res = await axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
                    if (res.status === 200) {
                        let resid = await axios.get(`${BASE_PATH}/user/${user.userId}`)
                        setStudentId(resid.data)
                        if (resid.status === 200) {
                            let data = await axios.get(`${BASE_PATH}/User/tickets/${resid.data.studentId}`)
                            if (data.status === 200) {
                             setData(data.data)
                             }
                        }
                    }
            }catch (e){
                console.log(e)
            }
    }
    const chat = (post) => {
        history.replace({ pathname: "/help-message", chatId: {chatId: post}, id: {id: studentId}})
    }

    return (
        <div
        className="top__space">
            <Estudiante />
            <div className="d-block d-sm-none d-none 
            d-sm-block d-md-none text-center">
                <Navbar className="navbar shadow-sm fixed-top d-flex justify-content-center">
                    <Nav 
                    className="d-flex align-items-center">
                        <h4 className="heavy">
                            We are here to help!
                        </h4>
                    </Nav>
                </Navbar>
            </div>
            <section
                className="container-sm">
                <Row>
                    <Col lg="4"
                    className="log__data__first">
                    <div
                    className="help">
                        <h3 className="bold d-none d-md-none d-lg-block">We are here to help!</h3>
                        <h6>Here you'll find your support history. <br/>
                        You can also create a new case by clicking the button below.</h6>
                        <br/>     
                        <Button
                        className="btn__review__alumno"
                        onClick={handleSessionID}
                        style={{
                            marginTop:'2rem',
                            marginBottom:'2rem',
                            paddingTop:'0.9rem',
                            paddingLeft:'1rem',
                            paddingRight:'1rem'
                            
                        }}>
                        <h4
                            className="text-white heavy">
                            Start a new conversation 
                        </h4>
                        </Button>     
                    </div>
                    </Col>
                    <Col
                    className="log__data_history__alumno">
                        <div style={containerStyle}>
                            <div className="post-list">
                                { 
                                    data.map((post, index) => {
                                        if(post.status === 'In Progress'){
                                            const fecha = moment(post.date).format('MMMM Do YYYY, h:mm a');
                                            return (
                                            <div key={index} className="post shadow-sm " style={divStyleP} onClick={() => chat(post)} >
                                                <Row
                                                style={{marginRight:'0.4rem', marginLeft:'0.2rem'}}>
                                                    <Col lg="12"  xs="12">
                                                        <div
                                                        className="d-flex justify-content-end align-items-center">
                                                        <Badge pill 
                                                        style={{
                                                            position:'absolute', 
                                                            marginTop:'2rem', 
                                                            padding:'0.3rem', 
                                                            paddingLeft:'0.5rem',
                                                            paddingRight:'0.5rem',
                                                            }}>
                                                            <h6>{post.status}</h6>
                                                        </Badge>
                                                        </div>
                                                        <p>
                                                            <h6
                                                            className="bold"
                                                            style={{
                                                                marginTop:'0.5rem',
                                                                fontSize: '18px',
                                                                color: 'white'}}>
                                                                Subject
                                                            </h6>
                                                            <br/>
                                                            <h6
                                                            className="bold"
                                                            style={{
                                                                marginTop:'-2rem',
                                                                fontSize: '12px',
                                                                color: '#172d89'}}>
                                                                 {fecha}
                                                            </h6>
                                                            <br/>
                                                            <h6
                                                            className="bold"
                                                            style={{
                                                                marginTop:'-2rem',
                                                                fontSize: '12px',
                                                                color: 'white'}}>
                                                                 Case No. {post.ticketId}
                                                            </h6>
                                                            </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            )
                                        }else{
                                            const fecha = moment(post.date).format('MMMM Do YYYY, h:mm a');
                                            return (<div key={index} className="post shadow-sm" style={divStyleP} onClick={() => chat(post)}>
                                            <Row>
                                                <Col lg="12"  xs="12">
                                                    <Badge pill 
                                                    style={{position:'absolute', marginTop:'2rem'}}>
                                                        {post.status}
                                                    </Badge>
                                                    <p>
                                                    <h6
                                                    className="bold"
                                                            style={{
                                                                marginTop:'0.5rem',
                                                                fontSize: '18px',
                                                                color: 'white'}}>
                                                                Subject
                                                            </h6>
                                                            <br/>
                                                            <h6
                                                            className="bold"
                                                            style={{
                                                                marginTop:'-2rem',
                                                                fontSize: '12px',
                                                                color: '172d89'}}>
                                                                 {fecha}
                                                            </h6>
                                                            <br/>
                                                            <h6
                                                            className="bold"
                                                            style={{
                                                                marginTop:'-2rem',
                                                                fontSize: '12px',
                                                                color: 'white'}}>
                                                                 Case No. {post.ticketId}
                                                            </h6>
                                                        </p>
                                                </Col>
                                            </Row>
                                            
                                        </div>)
                                        }
                                        
                                    })
                                }
                                <div className="loading" ref={loader}>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
            <Modal 
                title='New conversation'
                visible={isModalVisibled} 
                onOk={handleOk} 
                onCancel={handleCancel} 
                footer={null}>
                 <NewConversation setStudentId={studentId} onSucces={onSucces} />
            </Modal>
            
        </div>
    )
}

export default Help;