import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Rate, Radio } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Schema, Alert, FormGroup, Form, FormControl } from 'rsuite';
import {StringType} from "schema-typed";
import { BASE_PATH } from '../constants';
import axios from 'axios';


//Importar Recursos


const ReviewAppTutor = (props) => {
    const history = useHistory();
    const formRef = useRef()
    const [rate, setRate] = useState({rate: 1});   
    const [value, setValue] =  useState(1); 
    const initForm = {
        comentario: "",
    }
    const [form, setForm] = useState(initForm)

    const model = Schema.Model({
        comentario:StringType().isRequired("Campo obligatorio."),
    })

    const onChange = e => {
      console.log('radio checked', e.target.value);
      setValue(e.target.value);
    };
    const handleChange = (rate) => {
        setRate({ rate });
      }; 
      
      const onSubmit = async () => {
        if(!formRef.current.check()){
            Alert.warning("Empty required fields.",1000)
            return
        }else{
            try { 
                let ratings = await axios.post(`${BASE_PATH}/Ratings`,
                {   
                    "studentId": props.location.estudiante.estudiante,
                    "tutorId": props.location.tutor.tutor,
                    "conversationId": props.location.id.id,
                    "typeRating": 0,
                    "score": rate.rate,
                    "comment": form.comentario 
                })
                    if (ratings.status === 200){ 
                        Alert.success('qualification made');
                        history.replace({ pathname: "/"})
                    }
                }catch (error) { 
                    console.log(error);
                }
            }
    }
    return (
        <div className="">
            <Form
                ref={formRef}           
                onChange={(formValue)=>setForm(formValue)}
                fluid
                model={model}
                formValue={form}>
            <div className="container-sm review">
                <h3>
                ¿How would you rate your experience in the app during the call?
                </h3>
                <Rate 
                style={{ 
                    fontSize: 36,
                    color:'rgba(12,61,90,1)' }}
                value={rate.rate}
                onChange={handleChange}  />
                <FormGroup controlId="exampleForm.ControlTextarea1">
                    <FormControl
                    rows={5} name="textarea" componentClass="textarea" 
                    className="form__textarea__review"
                    placeholder="Share some tips ;)" name="comentario" />
                </FormGroup>
                <div className="divider__space"></div>
                <Button 
                className="
                btn__review
                btn__review__alumno
                shadow
                heavy" 
                onClick={onSubmit}
                type="submit">
                    Review
                </Button>
            </div>
        </Form> 
        </div>
    )
}
export default ReviewAppTutor;