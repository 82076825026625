import React, { useEffect, useRef, useState } from 'react'
import { Col, Grid, Row, Panel, Form, FormControl, FlexboxGrid, FormGroup, Schema, Alert, Icon, InputGroup } from 'rsuite'
import './StartSession.css'
import * as moment from "moment";
import 'moment/locale/es';
import { BASE_PATH } from '../constants';
import axios from 'axios';
import {StringType} from "schema-typed";
import { Navbar, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export const HelpMessageTutor = (props) => {
    
    const [messages, setMessages] = useState([])
    const endChat = useRef(null)  
    const formRef = useRef() 
    const [data, setdata] = useState({comment: ""})
    
    const [form, setForm] = useState(data)
     const scrollBottom = () => {
        endChat.current.scrollIntoView({block: 'nearest', inline: 'start'})
    }
    useEffect(() => {
        scrollBottom()
        fetchData()
    }, [])
    const fetchData = async () => {
        try {
            const url = `${BASE_PATH}/Tutor/tickets/detail/${props.location.chatId.chatId.ticketId}`
            let res = await axios.get(url)
            setMessages(res.data)
            console.log(res.data);
        } catch (e) {
            console.log(e)
        }
    } 
    const model = Schema.Model({
        comment:StringType().isRequired("required."),
    })
    const onSend = async () => {
        if(!formRef.current.check()){
            Alert.warning("Empty required fields.",1000)
            return
        }else{
            try{
                let send = await axios.post(`${BASE_PATH}/Tutor/tickets/detail`,{
                    "ticketId": props.location.chatId.chatId.ticketId,
                    "tutorId": props.location.id.id.tutorId,
                    "comment": form.comment
                })
                if (send.status === 200) {
                    fetchData()
                    setdata({comment: ""})
                    setForm(data)
                    Alert.success('Message sent')
                }
            }catch (e){
                console.log(e)
            }
        }
    }
    const classes = ["chat"]
    const gridProps = {
        xs: 24,
        lg: 14,
        lgOffset: 0,
    }
    const history = useHistory()
    const help = () => {
        history.replace({ pathname: "/help-tutor"})
    }
    return (  
<div
        className="">
        <div className=" text-center">
                <Navbar className="navbar shadow-sm fixed-top d-flex justify-content-center">
                    <Nav 
                    className="d-flex align-items-center">
                            <InputGroup.Addon  onClick={help}
                            className="help__back">
                                    <Icon 
                                    style={{fontSize:'1.5rem'}}
                                    icon="arrow-left-line"/>
                            </InputGroup.Addon>      
                        <h4 className="heavy">
                            Soporte Técnico
                        </h4>
                    </Nav>
                </Navbar>
            </div>
            <Row className="d-flex justify-content-center help__message">
                <Col md={15} xs={23}>
                    <Panel
                        id="chat-container"
                        className={""}
                        header={''}>
                        {   
                            messages.map((item) => {
                                if( item.tutorId  != null){
                                    return ( 
                                        <Row key={item.id}>
                                            <Col className="paddingleft"
                                               md={14} mdOffset={10} 
                                            >
                                                <Panel
                                                 className={'messageUser'}
                                                 style={{
                                                     borderRadius:'15px'
                                                 }} 
                                                    
                                                >
                                                    <h6
                                                    style={{    
                                                        fontSize: "10px",
                                                        wordSpacing: "-3px",
                                                        position: "absolute",
                                                        color: "rgba(255, 255, 255, 0.76)"}}>
                                                        { moment(item?.registerDate).fromNow() }
                                                    </h6>
                                                    <br/>
                                                    <h6>
                                                        { item?.comment }
                                                    </h6>
                                                </Panel>
                                            </Col>
                                        </Row>
                                     );
                                }else{
                                    return ( 
                                        <Row key={item.id} >
                                            <Col  className="paddingright"
                                               md={14} mdOffset={0} 
                                            >
                                                <Panel
                                                className={'message'}
                                                style={{
                                                    borderRadius:'15px'
                                                }} 
                                                >
                                                    <text
                                                    className="" style={{    
                                                        fontSize: "10px",
                                                        wordSpacing: "-3px",
                                                        position: "absolute",
                                                        color: "rgba(255, 255, 255, 0.76)"}}>
                                                        { moment(item?.registerDate).fromNow() }
                                                    </text>
                                                    <br/>
                                                    <text>
                                                        { item?.comment }
                                                    </text>
                                                </Panel>
                                            </Col>
                                        </Row>
                                     );
                                }
                            }
                        )}   
                        <div ref={endChat}>
                        </div>
                    </Panel>
                </Col>
            </Row>
            <Row>
            {props.location.chatId.chatId.status === 'In Progress'
                ? <Form fluid
                ref={formRef}           
                onChange={(formValue)=>setForm(formValue)}
                fluid
                model={model}
                formValue={form}
                className="fixed-bottom shadow-lg help__form">
                    <FormGroup>
                        <Col
                        className={"rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center d-flex align-items-center"}>
                            <InputGroup inside xs={8}>
                                <FormControl rows={2} name="comment" placeholder="Please enter a message..." componentClass="textarea"/>  
                            </InputGroup>
                            <InputGroup.Addon
                            className="help__send">
                                    <Icon 
                                    style={{fontSize:'1.5rem'}}
                                    icon="send"onClick={onSend}/>
                            </InputGroup.Addon>   
                        </Col>
                    </FormGroup>
                </Form>
               : <div></div>
                }
            </Row> 
    </div>
    )
}
