import React, {useContext, useRef, useState} from "react";
import ReactDOM from "react-dom";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { Card, Button } from 'react-bootstrap';
import { Row, Col, Alert, Schema, Form } from 'rsuite';
import {StringType, NumberType, BooleanType } from "schema-typed";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_PATH } from "../constants";
import Axios from "axios";
import { AuthContext } from "../../auth/AuthContext";



function TopicsAlumno() {
  const history = useHistory();
  const [tags, setTags] = React.useState([])
  const formRef = useRef() 
  const { user } = useContext(AuthContext);
 
    const stringData = tags.join(', ')

  const onSubmit = async () => {
    if(stringData === ''){
      Alert.warning("enter at least one topic.",1000)
      return
    }else{
       try { 
            let topic = await Axios.post(`${BASE_PATH}/Account/update/user`,
            {
              "id":  user.userId,
              "themes": stringData
            })
                if (topic.status === 200){ 
                  sessionStorage.setItem('status', 'new')
                 history.replace({ pathname: "/home-alumno"})
                 Alert.success('success')
            }
        } catch (error) { 
          console.log(error);
        }
      }
    }

  
  console.log(stringData)
  return (
        <div>
        <section 
        className="container-sm mt-4">            
              <Row>
                <Col md={24}>
                    <h1>Which topics do you enjoy?</h1>  
                </Col>
                <Col md={24}>
                  <h6>Setting your interests will allow for a better experience <br/>throughout the app.</h6> 
                  <h5 className="heavy text-secundario">Press enter for each topic</h5>
                </Col>
                <Col md={24}> 
                  <ReactTagInput 
                    placeholder="Type and press enter"
                    tags={tags} 
                    onChange={(newTags) => setTags(newTags)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center mt-4">
                        <Button   onClick={onSubmit}>
                            Done
                        </Button>
                </Col>
              </Row>
        </section>
    </div>
    
  )
}
    
    export default TopicsAlumno;