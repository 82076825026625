import React, { useEffect, useState, useRef, useContext } from 'react';
import { Row, Col, Image, Badge, Navbar, Nav } from 'react-bootstrap';
import { Rate, Modal } from 'antd';

//Importar Recursos
import Avatar from '../../assets/images/avatar2.jpg';
import Estudiante from '../Navbars/NavAlumno';
import { AuthContext } from '../../auth/AuthContext';
import { BASE_PATH } from '../constants';
import * as moment from "moment";
import axios from 'axios';
import { Panel, TagGroup, Tag, Icon } from 'rsuite';
import './style.css'
import { SessionCalendar } from './SessionCalendar';
import { RiDeleteBin6Fill } from "react-icons/ri";
import { CancelarLlamada } from './CancelarLlamada';

const divStyle = {
    color: 'blue',
    height: '7rem',
    textAlign: 'left',
    padding: '12px',
    background: 'rgb(247, 247, 247)',
    marginTop: '15px',
    borderRadius: '100px',
};

const divStylePointer = {
    color: 'blue',
    height: '7rem',
    textAlign: 'left',
    padding: '12px',
    background: 'rgb(247, 247, 247)',
    marginTop: '15px',
    borderRadius: '100px',
    cursor:"pointer"
};

const containerStyle = {
    maxWidth: '100%',
    margin: '0 auto',
}

const Calendar = () => {
    const { user } = useContext(AuthContext);
    const [appointment, setAppointment] = useState([])
    const [studenId, setStudenId] = useState([])
    const [date, setDate] = useState(moment().format('YYYY-MM-DDTHH\\Z'))
    const [postList, setPostList] = useState({
        list: [1,2,3,4]
    }); 
    const [isModalVisibleS, setIsModalVisibleS] = useState(false);
    const [isModalVisibleE, setIsModalVisibleE] = useState(false);
    const [cita, setCita] = useState([])
    const [eliminar, setEliminar] = useState([])

    const showModalS = (post) => {
        setCita(post)
        setIsModalVisibleS(true);
    };

    const handleOkS = () => {
        setIsModalVisibleS(false);
    };

    const handleCancelS = () => {
        setIsModalVisibleS(false);
    };
    const showModalE = (post) => {
        setEliminar(post)
        setIsModalVisibleE(true);
    };

    const handleOkE = () => {
        setIsModalVisibleE(false);
    };

    const handleCancelE = () => {
        setIsModalVisibleE(false);
    };
    // tracking on which page we currently are
    const [page, setPage] = useState(1);
    // add loader refrence 
    const loader = useRef(null);
    
    const onSuccess = () => {
        setIsModalVisibleE(false);
        fetchData()
    }
    useEffect(() => {
         var options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0
         };
        // initialize IntersectionObserver
        // and attaching to Load More div
         const observer = new IntersectionObserver(handleObserver, options);
         if (loader.current) {
            observer.observe(loader.current)
         }
         fetchData()
    }, []);


    useEffect(() => {
        // here we simulate adding new posts to List
        const newList = postList.list.concat([1,1,1,1]);
        setPostList({
            list: newList
        })
        setInterval(()=>{ 
             validar();    
        }, 15000)
    }, [page])

    // here we handle what happens when user scrolls to Load More div
   // in this case we just update page variable
    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {   
            setPage((page) => page + 1)
        }
    }

    const fetchData = async () => {
        try {
    
          let res = await axios.get(`${BASE_PATH}/User/appointment/${user.userId}`)
          if (res.status === 200) {
            setAppointment(res.data.sort((a, b) => new Date(a.date) - new Date(b.date)))
            let useID = await axios.get(`${BASE_PATH}/User/${user.userId}`)
            if (useID.status === 200) {
                setStudenId(useID.data)
            }
          }
        } catch (e) {
          console.log(e)
        }
      }

  
      const ver = () => {
          console.log('si se puedo')
      }
      
      const validar = () => {
        setDate(moment().format('YYYY-MM-DDTHH\\Z')) 
      } 
      
     
    return (
        <div
        className="top__space">
            <Estudiante />
            <div className="d-block d-sm-none d-none 
            d-sm-block d-md-none text-center">
                <Navbar className="navbar shadow-sm fixed-top d-flex justify-content-center">
                    <Nav 
                    className="d-flex align-items-center">
                        <h4 className="heavy">
                            Your Agenda
                        </h4>
                    </Nav>
                </Navbar>
            </div>
            <section 
            className="container-sm agenda__space_top">
                    <Row>
                    <Col lg="4"
                        className="d-none d-md-none d-lg-block">
                        <h1 
                        className="heavy">
                            Your Agenda
                        </h1>
                        </Col>
                        <Col> 
                        
                        <div style={containerStyle}>
                            <div className="post-list">
                            {
                                      appointment.map((post, index) => {
                                        const fecha = moment(post.date).format('lll');
                                        const fechav = moment(post.date).format('YYYY-MM-DDTHH\\Z');
                                        const {themes} = post;
                                        const array = themes === null ? null: themes.split(",");
                                        if( fechav  === date){
                                            return (
                                                <div style={divStylePointer}> 
                                                <Panel 
                                                 key={index} key={index}  
                                                className="" > 
                                                    <Row >
                                                        <Col tyle={{
                                                    cursor: 'pointer'}} onClick={()=> showModalS(post)} md="auto"  className="d-flex align-items-center"
                                                        lg="2" md="auto" xs="3">
                                                            <Image src={post.imageProfile&&post.imageProfile}
                                                            roundedCircle 
                                                            className="avatar"/>
                                                        </Col>
                                                        <Col tyle={{
                                                    cursor: 'pointer'}} lg="8"  xs="7" onClick={()=> showModalS(post)}>
                                                            <p>
                                                            <h6
                                                                style={{
                                                                    fontSize: '15px',
                                                                    color: 'black'}}>
                                                                    {fecha}
                                                                </h6>
                                                                <br/>
                                                                <h6
                                                                style={{
                                                                    marginTop:'-1rem',
                                                                    fontSize: '12px',
                                                                    color: 'grey'}}>
                                                                    {post.name}  <Rate disabled defaultValue={post.rating} />
                                                                </h6>
                                                             {/*   <h2
                                                                    style={{
                                                                    paddingTop:'0.4rem',
                                                                    marginBottom:'-1rem',
                                                                    width: '20rem'}}>
                                                                        <TagGroup>
                                                                        {array && array.map((item, index) => (
                                                                            <Tag color="yellow"
                                                                                key={index}
                                                                            >
                                                                                {item}
                                                                            </Tag>
                                                                            ))}
                                                                        </TagGroup>
                                                                        </h2> */}
                                                                </p>
                                                        </Col>
                                                        <Col className="d-flex align-items-center" 
                                                        lg="2" md="auto" xs="2">
                                                            <RiDeleteBin6Fill  onClick={()=> showModalE(post)} style={{
                                                    cursor: 'pointer'}} size={20} color={'red'}/>
                                                        </Col>
                                                    </Row> 
                                                </Panel>  
                                                </div>
                                               
                                                )
                                        }else{
                                            return(
                                                <div style={divStyle}>
                                                <Panel   
                                                className="" > 
                                                    <Row>
                                                        <Col className="d-flex align-items-center"
                                                        lg="2" md="auto" xs="3">
                                                            <Image src={post.imageProfile&&post.imageProfile}
                                                            roundedCircle 
                                                            className="avatar"/>
                                                        </Col>
                                                        <Col lg="8"  xs="7">
                                                         {/*}   <Badge pill variant="warning">
                                                                Next up
                                                              </Badge> */}
                                                            <p>
                                                                <h6
                                                                style={{
                                                                    fontSize: '15px',
                                                                    color: 'black'}}>
                                                                    {fecha}
                                                                </h6>
                                                                <br/>
                                                                <h6
                                                                style={{
                                                                    marginTop:'-1rem',
                                                                    fontSize: '12px',
                                                                    color: 'grey'}}>
                                                                    {post.name}  <Rate disabled defaultValue={post.rating} />
                                                                </h6>
                                                            </p>
                                                        </Col>
                                                        <Col className="d-flex align-items-center" 
                                                        lg="2" md="auto" xs="2">
                                                            <RiDeleteBin6Fill tyle={{
                                                    cursor: 'pointer'}}  onClick={()=> showModalE(post)} size={20} color={'red'}/>
                                                        </Col>
                                                    </Row> 
                                                </Panel>
                                                </div>
                                                );
                                        }
                                    })
                                 }
                                <div className="loading" ref={loader}>
                                </div>
                            </div>
                            
                        </div>
                        </Col>
                    </Row>
                    <Modal title="sessions"  
                    footer={false}
                    visible={isModalVisibleS} 
                    onOk={handleOkS} 
                    onCancel={handleCancelS}>
                          <SessionCalendar setCita={cita} setStudenId={studenId}/>
                    </Modal>
                    <Modal   
                    header={false}
                    footer={false}
                    visible={isModalVisibleE} 
                    onOk={handleOkE} 
                    onCancel={handleCancelE}>
                          <CancelarLlamada setEliminar={eliminar} setStudenId={studenId} cerrar={handleCancelE} onSuccess={onSuccess}/>
                    </Modal>
            </section>
        </div>
        )
    }
    
    export default Calendar;