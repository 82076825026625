import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap';
import 'antd/dist/antd.css';
import { StringType, BooleanType, NumberType, DateType } from "schema-typed";
import { Row, Col, Checkbox, Icon, Form, FormControl, Schema, SelectPicker, DatePicker, Alert, InputGroup, InputPicker, Input } from 'rsuite';
import { Modal } from 'antd';

//Importar Recursos
import logo from '../../assets/images/logo.png'

import { useForm } from '../../hooks/useForm';
import { registerStudent } from '../../service/service';

import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Card } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input'
import Terms from '../Home/Terms';


export const RegisterAlumno = () => {

    const [isModalVisibleT, setIsModalVisibleT] = useState(false);

    const showModalT = () => {
        setIsModalVisibleT(true);
    };

    const handleOkT = () => {
        setIsModalVisibleT(false);
    };

    const handleCancelT = () => {
        setIsModalVisibleT(false);
    };

    const months = [
        {
            "label": "Enero",
            "value": "1"
        },
        {
            "label": "Febrero",
            "value": "2"
        },
        {
            "label": "Marzo",
            "value": "3"
        },
        {
            "label": "Abril",
            "value": "4"
        },
        {
            "label": "Mayo",
            "value": "5"
        },
        {
            "label": "Junio",
            "value": "6"
        },
        {
            "label": "Julio",
            "value": "7"
        },
        {
            "label": "Agosto",
            "value": "8"
        },
        {
            "label": "Septiembre",
            "value": "9"
        },
        {
            "label": "Octubre",
            "value": "10"
        },
        {
            "label": "Noviembre",
            "value": "11"
        },
        {
            "label": "Diciembre",
            "value": "12"
        },
    ]

    const history = useHistory();
    const [fecha, setFecha] = useState("");
    const [phone, setPhone] = useState()
    const [value, setValue] = useState()
    const { dispatch } = useContext(AuthContext);
    const [birthdate, setBirthdate] = useState({ year: '', mouth: '', day: '' })
    const formRef = useRef()
    const initForm = {
        firstName: "",
        lastName: "",
        email: "",
        gender: '',
        countryId: 1,
        city: "",
        appTypeId: 3,
        birthdate: "",
    }
    const [form, setForm] = useState(initForm)
    const getRequestBody = () => {
        return {
            "firstName": form.firstName,
            "lastName": form.lastName,
            "email": form.email,
            "cellNumber": form.cellNumber,
            "gender": form.gender,
            "birthdate": form.birthdate,
            "countryId": 1,
            "city": form.city,
            "appTypeId": 3,
        }
    }
    const model = Schema.Model({
        firstName: StringType().isRequired("Obligatory field."),
        lastName: StringType().isRequired("Obligatory field."),
        email: StringType().isEmail('Please enter a valid email address.').isRequired('This field is required.'),
        gender: NumberType().isRequired("Obligatory field."),
        city: StringType().isRequired("Obligatory field."),
        day: NumberType().max(31, 'Enter a valid day')
    })
    const next = () => {
        if (birthdate.year != "" && birthdate.mouth != '' && birthdate.day != '' && birthdate.day <= 31 ) {
            if (calcularEdad(new Date(birthdate.year, birthdate.mouth, birthdate.day)) >= 11) {
                
                if (phone === undefined) {
                    Alert.warning("Empty required fields.", 1000)
                    return
                } else {
                    if (!formRef.current.check()) {
                        Alert.warning("Empty required fields.", 1000)
                    }
                    else {
                        history.replace({ pathname: "/register/alumno/password", alumno: {alumno: form}, phone: {phone: phone}})
                    }
                }
            }else{
                Alert.warning('You are not old enough to access', 2000)
            }

        }else{
            Alert.warning('Please enter a valid date',2000)
        }
    }

    const recurrent = [{
        "label": "Male",
        "value": 1
    }, {
        "label": "Female",
        "value": 0
    }]

    const pais = [
        {
            "label": "Afganistán",
            "value": "Afganistán"
        }, {
            "label": "Albania",
            "value": "Albania"
        },
        {
            "label": "Alemania",
            "value": "Alemania"
        }, {
            "label": "Andorra",
            "value": "Andorra"
        },
        {
            "label": "Angola",
            "value": "Angola"
        }, {
            "label": "Antigua y Barbuda",
            "value": "Antigua y Barbuda"
        },
        {
            "label": "Arabia Saudita",
            "value": "Arabia Saudita"
        }, {
            "label": "Argelia",
            "value": "Argelia"
        },
        {
            "label": "Argentina",
            "value": "Argentina"
        }, {
            "label": "Armenia",
            "value": "Armenia"
        },
        {
            "label": "Australia",
            "value": "Australia"
        }, {
            "label": "Austria",
            "value": "Austria"
        },
        {
            "label": "Azerbaiyán",
            "value": "Azerbaiyán"
        }, {
            "label": "Bahamas",
            "value": "Bahamas"
        },
        {
            "label": "Bangladés",
            "value": "Bangladés"
        }, {
            "label": "Barbados",
            "value": "Barbados"
        },
        {
            "label": "Baréin",
            "value": "Baréin"
        }, {
            "label": "Bélgica",
            "value": "Bélgica"
        },
        {
            "label": "Belice",
            "value": "Belice"
        }, {
            "label": "Benín",
            "value": "Benín"
        },
        {
            "label": "Bielorrusia",
            "value": "Bielorrusia"
        }, {
            "label": "Birmania",
            "value": "Birmania"
        },
        {
            "label": "Bolivia",
            "value": "Bolivia"
        }, {
            "label": "Bosnia y Herzegovina",
            "value": "Bosnia y Herzegovina"
        },
        {
            "label": "Botsuana",
            "value": "Botsuana"
        }, {
            "label": "Brasil",
            "value": "Brasil"
        },
        {
            "label": "Brunéi",
            "value": "Brunéi"
        }, {
            "label": "Bulgaria",
            "value": "Bulgaria"
        },
        {
            "label": "Burkina Faso",
            "value": "Burkina Faso"
        }, {
            "label": "Guatemala",
            "value": "Guatemala"
        }, {
            "label": "El Salvador",
            "value": "El Salvador"
        }
    ]

    const login = () => {
        history.replace({ pathname: "/login" })
    }

    const styleInputNumber = {
        width: '25%',
        border: 'none',
        borderBottom: '1px solid #e6e6ea',
        borderTop: '1px solid #e6e6ea'
    }

    const styleBorder = {
        border: 'none',
        borderBottom: '1px solid #e6e6ea',
        borderTop: '1px solid #e6e6ea'
    }

    const calcularEdad = (fecha) => {
        var hoy = new Date();
        var cumpleanos = new Date(fecha);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }

        return edad;
    }

    useEffect(()=>{
        if(birthdate.day != "" && birthdate.mouth != '' && birthdate.day != ''){
            setForm({...form, birthdate: new Date(birthdate.year, birthdate.mouth, birthdate.day)})
        }
    }, [birthdate])

    return (
        <div className="d-flex justify-content-center
        d-flex align-items-center vh-100 login">
            <Card className="card border-0">
                <Card.Body>
                    <InputGroup.Addon onClick={login}
                        className="register__back">
                        <Icon
                            icon="close" />
                    </InputGroup.Addon>
                    <img src={logo}
                        className="logo__login centrado" alt="logo"
                        style={{ marginBottom: '1rem' }} />
                    <Form
                        ref={formRef}
                        fluid
                        onChange={(formValue) => setForm(formValue)}
                        model={model}
                        formValue={form}
                    >
                        <Row>
                            <Col md={24}>
                                <h6><label >First name</label></h6>
                                <FormControl name={"firstName"} placeholder={"First name"} />
                            </Col>
                            <Col md={24}>
                                <h6><label >Last name</label></h6>
                                <FormControl name={"lastName"} placeholder={"Last name"} />
                            </Col>
                            <Col md={24}>
                                <h6><label >Email</label></h6>
                                <FormControl name={"email"} placeholder={"Enter email"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}  >
                                <h6><label style={{ marginBottom: '-1rem' }}>Phone</label></h6>
                                <text style={{ wordSpacing: 0, color: 'grey' }}>Selecciona tu País</text>
                                <div
                                    style={{
                                        padding: '0.5rem',
                                        border: '1px solid rgba(0, 0, 0, 0.110)',
                                        borderRadius: '5px',
                                        height: '2.3rem'
                                    }}>
                                    <PhoneInput
                                        placeholder=" | Número de Celular"
                                        value={phone}
                                        onChange={setPhone} />
                                </div>
                            </Col>
                            <Col md={12} >
                                <h6><label >Gender</label></h6>
                                <FormControl searchable={false} accepter={SelectPicker} name={"gender"} placeholder={"Gender"} data={recurrent} block />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} >
                                <h6><label >Birthdate</label></h6>
                                <div style={{ display: 'flex' }}>
                                    <InputPicker data={months} style={{ width: 150 }, styleBorder} placeholder={'Month of birth'}
                                        onSelect={(value) => setBirthdate({ ...birthdate, mouth: value })}
                                        onClean={()=> setBirthdate({...birthdate, mouth: ''})} />
                                    <Input placeholder="Day" style={styleInputNumber} type='number'
                                        onChange={(value) => setBirthdate({ ...birthdate, day: value })} />
                                    <Input placeholder="year" style={styleInputNumber} type='number' 
                                        onChange={(value) => setBirthdate({ ...birthdate, year: value })} />
                                </div>
                            </Col>
                            <Col md={12} >
                                <h6><label >I was born in</label></h6>
                                <FormControl searchable={false} placement="topStart" accepter={SelectPicker} name={"city"} placeholder={"Select"} data={pais} block />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={24}>
                                <Checkbox value={value}><h6>Agree to terms and conditions, and to have a remote
                                    interview previous to my registration.</h6></Checkbox>
                                <div className=" d-flex justify-content-center">
                                    <div
                                        onClick={showModalT}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue'
                                        }}>
                                        <p>Read the Terms, Conditions and License</p>
                                    </div>
                                    <Modal
                                        title=""
                                        footer={false}
                                        visible={isModalVisibleT}
                                        onOk={handleOkT}
                                        onCancel={handleCancelT}
                                        width={1000}
                                        style={{ top: 20 }}>
                                        <Terms />
                                    </Modal>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center mt-4">
                                <Button appearance="primary" onClick={next} className="btn-navbar-first shadow heavy">Next</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default RegisterAlumno;