import React, { useState, useContext } from 'react';
import { Card, Form, } from 'react-bootstrap';
import 'antd/dist/antd.css';

//Importar Recursos
import logo from '../../assets/images/logo.png'
import { useForm } from '../../hooks/useForm';
import { login } from '../../service/service';

import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { BASE_PATH } from '../constants';
import { Alert, Button } from 'rsuite';

export const Login = ({ history }) => {

    const { dispatch } = useContext(AuthContext);

    const [validated, setValidated] = useState(false);

    const [formValues, handleInputChange] = useForm({
        email: '',
        password: ''
    });

    const { email, password } = formValues;

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        } else {
            event.preventDefault();

            const dataInfo = await login({
                "email": email,
                "password": password,
                "appId": 3,
                "version": "1.0"
            });

            if(dataInfo.statusCode != null && dataInfo.statusCode === 401){
                setValidated(false)
                Alert.warning('Incorrect username or password')
            }else {
                try {
                    let res = await Axios.get(`${BASE_PATH}/Tutor/${dataInfo.userId}`)
                    if (res.status === 200) {
                        let data = await Axios.get(`${BASE_PATH}/Account/user/${dataInfo.userId}`)
                            if (data.status === 200) {
                            let home = await Axios.get(`${BASE_PATH}/Tutor/home/${res.data.tutorId}`)
                            console.log(home.data[0].themes)
                                if(home.data[0].themes === null){            
                                    setValidated(true)
                                    dispatch({
                                        type: types.login,
                                        payload: dataInfo
                                    });
                                    history.replace({ pathname: "/verify_data", datos: {datos: data.data}})
                                //  history.replace("/verify_data")
                                }else {
                                setValidated(true)

                                dispatch({
                                    type: types.login,
                                    payload: dataInfo
                                });
                    
                                history.replace("/")
                                }
                        }
                    }
                    } catch (e) {
                        console.log(e)
                }
            }
        }
    };
    return (
        <div className="d-flex justify-content-center
        d-flex align-items-center vh-100 login">
            <Card className="card border-0">
                <Card.Body>
                    <img src={logo}
                        className="logo__login centrado" alt="logo"
                        style={{ marginBottom: '1rem' }} />
                    <Form validated={validated} onSubmit={handleSubmit}>
                        <Form.Group md="4" controlId="validationCustom02">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="Enter email"
                                size="lg"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group md="4" controlId="validationCustom03">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                required
                                type="password"
                                placeholder="Enter password"
                                size="lg"
                                name="password"
                                value={password}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Button 
                        className="btn-login  shadow heavy" type="submit">
                            Login
                        </Button>
                    </Form>
                     <br />
                </Card.Body>
            </Card>
        </div>
    )
}
export default Login;