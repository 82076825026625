import React, { state, handleChange, useState, useContext, useEffect, setVisible, visible } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom'
import { Switch, Upload, message, Rate, Modal, Drawer } from 'antd';
import 'rsuite/lib/styles/index.less';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Notification } from 'rsuite';
import 'antd/dist/antd.css';
import Axios from 'axios';
import { BASE_PATH } from '../constants';
import { AuthContext } from '../../auth/AuthContext';
import { render } from '@testing-library/react';
import { Solicitudes } from './Solicitudes';
import NavTutor from '../Navbars/NavTutor';
import { UploadOutlined } from '@ant-design/icons';
import { MdModeEdit } from "react-icons/md";
import { types } from '../../types/types';

//Importar Recursos
//import Avatar from '../../assets/images/avatar.jpg';
import ProfileSettings from '../../componentes/Login/ProfileSettings'
import RegisterEdit from '../Login/RegisterEdit';
import TopicsEdit from '../Login/TopicsEdit';
import ReviewApp from '../Frame/ReviewApp';
import { UpdateTutor } from './UpdateTutor';
import Terms from '../Home/Terms';



const Home = (props) => {
    const [lgShow, setLgShow] = useState(false);
    const [checkedd, setChecked] = useState(false)

    const [isModalVisibleS, setIsModalVisibleS] = useState(false);

    const showModalS = () => {
        setIsModalVisibleS(true);
    };

    const handleOkS = () => {
        setIsModalVisibleS(false);
    };

    const handleCancelS = () => {
        setIsModalVisibleS(false);
    };

    const [isModalVisibleT, setIsModalVisibleT] = useState(false);

    const showModalT = () => {
        setIsModalVisibleT(true);
    };

    const handleOkT = () => {
        setIsModalVisibleT(false);
    };

    const handleCancelT = () => {
        setIsModalVisibleT(false);
    };

    const [isModalVisibleC, setIsModalVisibleC] = useState(false);

    const showModalC = () => {
        setIsModalVisibleC(true);
    };

    const handleOkC = () => {
        setIsModalVisibleC(false);
    };

    const handleCancelC = () => {
        setIsModalVisibleC(false);
    };

    const [isModalVisibleR, setIsModalVisibleR] = useState(false);

    const showModalR = () => {
        setIsModalVisibleR(true);
    };

    const handleOkR = () => {
        setIsModalVisibleR(false);
    };

    const handleCancelR = () => {
        setIsModalVisibleR(false);
    };

    const [isModalVisibleP, setIsModalVisibleP] = useState(false);

    const showModalP = () => {
        setIsModalVisibleP(true);
    };

    const handleOkP = () => {
        setIsModalVisibleP(false);
    };

    const handleCancelP = () => {
        setIsModalVisibleP(false);
    };


    const onSuccess = () => {
        fetchData()
        setIsModalVisibleS(false);
        setVisible(false);
    }

    
    useEffect(() => {
        fetchData()
        setInterval(() => {
            abrirmodal();
        }, 15000)
    }, [])
    const abrirmodal = async () => {
        try {
            let userid = await Axios.get(`${BASE_PATH}/Tutor/${user.userId}`)
            if (userid.status === 200) {
                setTutor(userid.data)
                let resso = await Axios.get(`${BASE_PATH}/Tutor/appointment/request/${userid.data.tutorId}`)
                if (resso.status === 200) {
                    setSolicitudes(resso.data.filter(el => el.statusAppointmentId === 0))
                    if (resso.data.filter(el => el.statusAppointmentId === 0).length > 0) {
                        showModal()
                        
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    var timer;
    const noAvalible = () =>{
        
        timer = setInterval(()=>{
            console.log('Interval Encendido')
            if(solicitudes.length === 0){
                setChecked(false)
                handleAvailable(false)
                clearInterval(timer)
            }
        }, 60000)
        
    }
    const offTimer = () =>{
        console.log('Se apago el Timer')
        clearInterval(timer)
    }
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [avatar, setAvatar] = useState("");
    const [defaultFileList, setDefaultFileList] = useState([]);
    const [scheduled, setScheduled] = useState([]);
    const [sessions, setSessions] = useState([])
    const actualizarModal = () => {

    }
    const showModal = () => {
        setIsModalVisible(true);
        actualizarModal();
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const { user } = useContext(AuthContext);
    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const sendImage = async (image) => {

        const { onSuccess, onError, file, onProgress } = image;

        const fm = new FormData();
        fm.append("AppUserId", user.userId);
        fm.append("ProfileImage", file)


        const config = {
            headers: { "content-type": "multipart/form-data" }
        };

        try {
            const res = await Axios.post(
                `${BASE_PATH}/Account/storeImage`,
                fm,
                config
            );
            if (res.status === 200) {
                const resUser = await Axios.post(
                    `${BASE_PATH}/Account/update/user`,
                    {
                        "id": user.userId,
                        "imageProfile": res.data.urlImageProfile,
                    }
                );
                setAvatar(res.data.urlImageProfile)
                fetchData()
            }
        } catch (err) {
            console.log("Eroor: ", err);
        }
    }
    const handleChange = ({ file, fileList, event }) => {

        setDefaultFileList(fileList);

    };
    const editTopics = () => {
        fetchData()
        setIsModalVisibleT(false)
        setVisible(false);
    }
    const [datos, setData] = useState([]);
    const fetchData = async () => {
        try {
            let res = await Axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
            if (res.status === 200) {
                setData(res.data)
                let userid = await Axios.get(`${BASE_PATH}/Tutor/${user.userId}`)
                if (userid.status === 200) {
                    setTutor(userid.data)
                    let tutorid = await Axios.get(`${BASE_PATH}/Tutor/home/${userid.data.tutorId}`)
                    if (tutorid.status === 200) {
                        let commissions = await Axios.get(`${BASE_PATH}/Tutor/home/${userid.data.tutorId}`)
                        if (commissions.status === 200) {
                            setHomes(tutorid.data)
                            let balance = await Axios.get(`${BASE_PATH}/Tutor/commissions/${userid.data.tutorId}`)
                            if (balance.status === 200) {
                                setCommissions(balance.data)
                                let resso = await Axios.get(`${BASE_PATH}/Tutor/appointment/request/${userid.data.tutorId}`)
                                if (resso.status === 200) {
                                    setSolicitudes(resso.data)
                                    let scheduled = await Axios.get(`${BASE_PATH}/Tutor/appointment/scheduled/${user.userId}`)
                                    if (scheduled.status === 200) {
                                        setScheduled(scheduled.data)
                                        let conversations = await Axios.get(`${BASE_PATH}/Tutor/conversations/${userid.data.tutorId}`)
                                        if (conversations.status === 200) {
                                            setSessions(conversations.data)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }
    }
    const [solicitudes, setSolicitudes] = useState([])
    const [commissions, setCommissions] = useState([]);
    const [tutor, setTutor] = useState([]);
    const [homes, setHomes] = useState({
        available: "",
        rating: 0,
        pointsStreak: 0,
        level: 0
    });
    const getRequestTrue = () => {
        return {
            "tutorId": tutor.tutorId,
            "available": true,
        }

    }
    const getRequestFalse = () => {
        return {
            "tutorId": tutor.tutorId,
            "available": false,
        }

    }
    const handleAvailable = async (checked) => {
        if (checked) {

            try {
                let res = await Axios.put(`${BASE_PATH}/Tutor/available`, getRequestTrue())
                if (res.status === 200) {
                    //  props.onSuccess()
                    // setConfirm(false)
                    //   Alert.success("Categoria eliminada correctamente.",1000)
                    console.log('Se guardo True')
                    
                }
            } catch (e) {
                console.log(e)
            }
        }
        if (checked === false) {
            try {
                let res = await Axios.put(`${BASE_PATH}/Tutor/available`, getRequestFalse())
                console.log(res)
                if (res.status === 200) {
                    //  props.onSuccess()
                    // setConfirm(false)
                    //   Alert.success("Categoria eliminada correctamente.",1000)
                    console.log('Se Guardo False')
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    const terms = () => {
        history.replace({ pathname: "/terms" })
    }

    

    useEffect(() => {
        console.log(homes)
        console.log(homes[homes.length - 1] && homes[homes.length - 1].available)
        if(homes[homes.length - 1] && homes[homes.length - 1].available != undefined){
            setChecked(homes[homes.length - 1] && homes[homes.length - 1].available)
        }
        
    }, [homes])

    useEffect(()=>{
        if(checkedd){
            noAvalible()
        }else{
            offTimer()
        }
    }, [checkedd])

    const rate = homes[homes.length - 1] && homes[homes.length - 1].rating;
    function UrlPath() {
        return (
            <div><Rate disabled defaultValue={rate} /></div>
        );
    }

    const { loading, imageUrl } = useState();
    const uploadButton = (
        <div>
            <Button
                className="pic-uploader bold "
                icon={<UploadOutlined />}><i className="icon-camera"></i>
            </Button>
        </div>
    );

    let imgAvatar;
    if (homes.length > 0) {
        imgAvatar = homes[0].imageProfile;
    }

    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const { user: { name }, dispatch } = useContext(AuthContext);
    const history = useHistory();
    const [home,] = useState([])
    const handleLogout = async () => {

        history.replace('/login');

        dispatch({
            type: types.logout
        });
        try {
            let res = await Axios.put(`${BASE_PATH}/Tutor/available`, getRequestFalse())
            if (res.status === 200) {
                //  props.onSuccess()
                // setConfirm(false)
                //   Alert.success("Categoria eliminada correctamente.",1000)
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div>
            <NavTutor />
            <section
                className="container-sm top__space">
                <Row
                    className="d-flex justify-content-center">
                    <Col>
                        <Card className="card__avatar image04 border-0">
                            <Card.ImgOverlay>
                                <Card.Img src={imgAvatar} className="card-border" />
                                <div className="card-bottom">
                                    <Button
                                        className="avatar-uploader"
                                        onClick={showDrawer}
                                        style={{
                                            fontSize: 18,
                                            marginLeft: 12
                                        }}>
                                        <i className="icon-settings d-flex align-items-center"></i>
                                    </Button>
                                    <Drawer
                                        placement="right"
                                        closable={false}
                                        onClose={onClose}
                                        visible={visible}
                                        width={300}>
                                        <div
                                            className="d-flex justify-content-center">
                                            <Card className="pic__perfil image04__previa border-0">
                                                <Card.ImgOverlay>
                                                    <Card.Img src={imgAvatar} className="" />
                                                    <div className="card-bottom">
                                                        <Upload
                                                            name="avatar"
                                                            showUploadList={false}
                                                            customRequest={sendImage}
                                                            onChange={handleChange}
                                                            beforeUpload={beforeUpload}>

                                                            {imageUrl ? <img src={imageUrl} alt="avatar"
                                                                onContextMenu="return false;"
                                                                style={{ width: '100%' }} /> : uploadButton}
                                                        </Upload>
                                                    </div>
                                                </Card.ImgOverlay>
                                            </Card>
                                        </div>
                                        <div className="divider__space"></div>
                                        <Button
                                            onClick={showModalS}
                                            style={{
                                                borderRadius: 50,
                                                width: '100%',
                                                padding: '1rem',
                                                marginBottom: '1rem'
                                            }}>
                                            Profile Settings
                                        </Button>
                                        <Modal title="Profile Settings"
                                            footer={false}
                                            visible={isModalVisibleS}
                                            onOk={handleOkS}
                                            onCancel={handleCancelS}
                                            style={{ top: 1 }}>
                                            <UpdateTutor setData={datos} onSuccess={onSuccess} />
                                        </Modal>
                                        <Button
                                            onClick={showModalT}
                                            style={{
                                                borderRadius: 50,
                                                width: '100%',
                                                padding: '1rem',
                                                marginBottom: '1rem'
                                            }}>
                                            Topics
                                        </Button>
                                        <Modal
                                            title=""
                                            footer={false}
                                            visible={isModalVisibleT}
                                            onOk={handleOkT}
                                            onCancel={handleCancelT}>
                                            <TopicsEdit setHomes={homes} editSucces={editTopics} />
                                        </Modal>
                                        <Button
                                            onClick={showModalP}
                                            style={{
                                                borderRadius: 50,
                                                width: '100%',
                                                padding: '1rem',
                                                marginBottom: '1rem'
                                            }}>
                                            Terms & Conditions
                                        </Button>
                                        <Modal
                                            title=""
                                            footer={false}
                                            visible={isModalVisibleP}
                                            onOk={handleOkP}
                                            onCancel={handleCancelP}
                                            width={1000}
                                            style={{ top: 1 }}>
                                            <Terms />
                                        </Modal>

                                        <Button
                                            onClick={handleLogout}
                                            style={{
                                                width: '100%',
                                                padding: '1rem',
                                                background: '#177AB3',
                                                borderRadius: 50,
                                                color: 'white',
                                                marginTop: '45%'
                                            }}>
                                            Logout
                                        </Button>
                                    </Drawer>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                        <div
                            className="d-flex justify-content-center switch">
                            <Switch
                                checked={checkedd}
                                onChange={(checke)=>{
                                    setChecked(checke)
                                    handleAvailable(checke)
                                }}
                                checkedChildren="Available"
                                unCheckedChildren="Not Available"
                                defaultChecked />
                        </div>
                    </Col>
                    <Col lg="6"
                        className="home__info">
                        <h1
                            className="heavy">
                            <text
                                className="home__hello">
                                Hello,
                            </text>
                            <br /> {datos.firstName}
                        </h1>
                        <Row>
                            <Modal
                                visible={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                footer={null}>
                                <Solicitudes
                                    setData={datos}
                                    setTutor={tutor}
                                    setHomes={homes[homes.length - 1] && homes[homes.length - 1]}
                                    setSolicitudes={solicitudes[solicitudes.length - 1] && solicitudes[solicitudes.length - 1]} />
                            </Modal>
                        </Row>
                        <div
                            className="text-center">
                            <UrlPath />
                        </div>
                        <Row
                            className="btn-show d-flex 
                        align-items-center 
                        text-center">
                            <Col xs="4" lg="4">
                                <h3
                                    className="text-white heavy">
                                    ${commissions.monthBalance}
                                </h3>
                            </Col>
                            <h4
                                className="text-white bold">
                                Monthly balance
                            </h4>
                            <Col>
                            </Col>
                        </Row>
                        <Row
                            className="d-flex justify-content-center">
                            <Col xs="5" lg="3"
                                className="text-center card__data">
                                <h1>
                                    {scheduled.scheduledAppointments && scheduled.scheduledAppointments}
                                </h1>
                                <h6>Scheduled sessions</h6>
                            </Col>
                            <Col xs="5" lg="3"
                                className="text-center card__data">
                                <h1>
                                    {sessions.monthSessions && sessions.monthSessions}
                                </h1>
                                <h6>Session this month</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </div>
    );
}

export default Home;