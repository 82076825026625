import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Rate, Radio } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, FormControl, Schema, FormGroup, Alert } from 'rsuite';
import {StringType} from "schema-typed";
import { BASE_PATH } from '../constants';
import Axios from 'axios';



//Importar Recursos


const ReviewAlumno = (props) => {
    const history = useHistory();
    const formRef = useRef()
    const [rate, setRate] = useState({rate: 1});   
    const [value, setValue] =  useState(1); const initForm = {
        comentario: "",
    }
    const [form, setForm] = useState(initForm)

    const model = Schema.Model({
        comentario:StringType().isRequired("Campo obligatorio."),
    })
    const onChange = e => {
      console.log('radio checked', e.target.value);
      setValue(e.target.value);
    };

    const handleChange = (rate) => {
        setRate({ rate });
      }; 
      
    const onSubmit = async () => {
        if(!formRef.current.check()){
            Alert.warning("Empty required fields.",1000)
            return
        }else{
            try { 
                let finalizar = await Axios.put(`${BASE_PATH}/Tutor/appointment/${props.location.alumno.alumno.appointmentId}`,
                {
                    "appointmentId": props.location.alumno.alumno.appointmentId,
                    "status": 3,
                })
                if (finalizar.status === 200){ 
                let ratings = await Axios.post(`${BASE_PATH}/Ratings`,
                {   
                    "studentId": props.location.idestudiante.id,
                    "tutorId": props.location.tutor.tutor,
                    "conversationId": props.location.idConversacion.id.conversationId,
                    "typeRating": 2,
                    "score": rate.rate,
                    "comment": form.comentario 
                })
                    if (ratings.status === 200){ 
                        Alert.success('qualification made');
          
                        history.replace({ pathname: "/review-app", estudiante: {estudiante: props.location.idestudiante.id}, tutor: {tutor:  props.location.tutor.tutor}, id: {id: props.location.idConversacion.id.conversationId}})
                    }
                } 
              }catch (error) { 
                    console.log(error);
                }
            }
    }
    return (
        <Form
        ref={formRef}           
        onChange={(formValue)=>setForm(formValue)}
       fluid
       model={model}
       formValue={form}>
            <div className="container-sm review">
                    <h3>
                        ¿How would you grade {props.location.alumno.alumno.tutorName} as a Tutor?
                    </h3>
                    <Rate 
                    style={{ 
                        fontSize: 36,
                        color:'rgba(12,61,90,1)' }}
                    value={rate.rate}
                    onChange={handleChange}  />
                    <FormGroup controlId="exampleForm.ControlTextarea1">
                        <FormControl
                       rows={5} name="textarea" componentClass="textarea" 
                       className="form__textarea__review"
                       placeholder="Share some tips ;)" name="comentario" />
                    </FormGroup>
                    <div className="divider__space"></div>
                    <h3>
                        ¿Would you book this Tutor again?
                    </h3>
                    <Radio.Group 
                        onChange={onChange} value={value}>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={2}>No</Radio>
                    </Radio.Group>
                    <FormGroup controlId="exampleForm.ControlTextarea1">
                       
                    </FormGroup>
                <Button 
                className="
                btn__review
                btn__review__alumno
                shadow
                heavy" 
                onClick={onSubmit}
                type="submit">
                    Review
                </Button>
            </div>
        </Form>
    )
}
export default ReviewAlumno;