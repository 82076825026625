
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Row, Col, Uploader, Loader, Icon, Badge, IconButton, Avatar, HelpBlock, Form, FormControl, Schema, SelectPicker, DatePicker, Button, Alert  } from 'rsuite';
import { AuthContext } from '../../auth/AuthContext';
import {StringType, BooleanType} from "schema-typed";
import { BASE_PATH } from '../constants';

export const VerificarDatos = (props) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([])
  const [tutor, setIDtutor] = useState([])
  const [imagen, setImg] = useState([])
  const [preview, setPreview] = useState("")
  const formRef = useRef()
  console.log(data)
  const initForm = {
    firstName: "",
    lastName: "",
    email: "",
    cellNumber: "",
    }
  const [form, setForm] = useState(initForm)
  useEffect(() => {
    fetchData()
    setForm({
        "firstName": props.location.datos.datos.firstName,
        "lastName":  props.location.datos.datos.lastName, 
        "email":  props.location.datos.datos.email, 
        "cellNumber":  props.location.datos.datos.cellNumber, 
        "gender":  props.location.datos.datos.gender, 
        "birthdate": props.location.datos.datos.birthdate,
        "city": props.location.datos.datos.city
    })
  }, [])
    const recurrent = [{
        "label": "Male",
        "value": 1
    }, {
        "label": "Female",
        "value": 0
    }]
    
    const pais = [
        {
        "label": "Afganistán",
        "value": "Afganistán"
    }, {
        "label": "Albania",
        "value": "Albania"
    },
    {
        "label": "Alemania",
        "value": "Alemania"
    }, {
        "label": "Andorra",
        "value": "Andorra"
    },
    {
        "label": "Angola",
        "value": "Angola"
    }, {
        "label": "Antigua y Barbuda",
        "value": "Antigua y Barbuda"
    },
    {
        "label": "Arabia Saudita",
        "value": "Arabia Saudita"
    }, {
        "label": "Argelia",
        "value": "Argelia"
    },
    {
        "label": "Argentina",
        "value": "Argentina"
    }, {
        "label": "Armenia",
        "value": "Armenia"
    },
    {
        "label": "Australia",
        "value": "Australia"
    }, {
        "label": "Austria",
        "value": "Austria"
    },
    {
        "label": "Azerbaiyán",
        "value": "Azerbaiyán"
    }, {
        "label": "Bahamas",
        "value": "Bahamas"
    },
    {
        "label": "Bangladés",
        "value": "Bangladés"
    }, {
        "label": "Barbados",
        "value": "Barbados"
    },
    {
        "label": "Baréin",
        "value": "Baréin"
    }, {
        "label": "Bélgica",
        "value": "Bélgica"
    },
    {
        "label": "Belice",
        "value": "Belice"
    }, {
        "label": "Benín",
        "value": "Benín"
    },
    {
        "label": "Bielorrusia",
        "value": "Bielorrusia"
    }, {
        "label": "Birmania",
        "value": "Birmania"
    },
    {
        "label": "Bolivia",
        "value": "Bolivia"
    }, {
        "label": "Bosnia y Herzegovina",
        "value": "Bosnia y Herzegovina"
    },
    {
        "label": "Botsuana",
        "value": "Botsuana"
    }, {
        "label": "Brasil",
        "value": "Brasil"
    },
    {
        "label": "Brunéi",
        "value": "Brunéi"
    }, {
        "label": "Bulgaria",
        "value": "Bulgaria"
    },
    {
        "label": "Burkina Faso",
        "value": "Burkina Faso"
    }, {
        "label": "Guatemala",
        "value": "Guatemala"
    }, {
        "label": "El Salvador",
        "value": "El Salvador"
    }
    ]
  const model = Schema.Model({
    firstName:StringType().isRequired("Obligatory field."),
    lastName:StringType().isRequired("Obligatory field."),
    email:StringType().isRequired("Obligatory field."), 
    cellNumber:StringType().isRequired("Obligatory field."),
    })
  const fetchData = async()  => {
    try {
        let data = await axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
        if (data.status === 200) {
          setData(data.data)
          let tutor = await axios.get(`${BASE_PATH}/Tutor/${user.userId}`)
          if (tutor.status === 200) {
            setIDtutor(tutor.data)
            let img = await axios.get(`${BASE_PATH}/Tutor/home/${tutor.data.tutorId}`)
            if (img.status === 200) {
              setImg(img.data)
            }
          }
        }
      } catch (e) {
          console.log(e)
      }
  }
  const nextTopic = async() => {
    if(imagen[imagen.length-1].imageProfile === null) {
        Alert.warning("Required Photography.",1000)
        return
    }
    if(!formRef.current.check()){
        Alert.warning("Empty required fields.",1000)
        return
      }else{
        try {
            let update = await axios.post(`${BASE_PATH}/Account/update/user`,
                {
                    "id": user.userId,
                    "firstName": form.firstName,
                    "lastName": form.lastName,
                    "email": form.email,
                    "cellNumber": form.cellNumber,
                    "gender": form.gender,
                    "birthdate": form.birthdate,
                    "city": form.city
                },{
                })
                    if(update.status===200){
                    history.replace({ pathname: "/topics"})
                    Alert.success("Updated data.", 1000);
                        
                    }
        }catch (e) { 
            console.log(e)
            Alert.warning("error",1000)
        }
    }
  }
  const handleChange = async (files) => {
      const fm = new FormData();
    fm.append("AppUserId", user.userId);
    fm.append("ProfileImage", files[0].blobFile)


    const config = {
      headers: { "content-type": "multipart/form-data" }
    };

    try {
      const res = await axios.post(
        `${BASE_PATH}/Account/storeImage`,
        fm,
        config
      );
      if (res.status === 200) {
        const resUser = await axios.post(
          `${BASE_PATH}/Account/update/user`,
          {
            "id": user.userId,
            "imageProfile": res.data.urlImageProfile,
          }
        );
        fetchData()
      }
    } catch (err) {
      console.log("Eroor: ", err);
    }
  }
    return (
        <div className="container">
                <Row >
                    <Col xs={12} xsPush={6}   className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center mt-4">
                        <Badge content={
                                <Uploader
                                    accept={"image/*"}
                                    autoUpload={false}
                                    fileListVisible={false}
                                    multiple={false}
                                    onChange={handleChange}
                                >
                                    <IconButton color={"blue"} circle size={"xs"}
                                                icon={<Icon icon={"edit"}/>}/>
                                </Uploader>
                            }>
                                {<Avatar
                                    src={imagen[imagen.length-1]&&imagen[imagen.length-1].imageProfile}
                                    size={"lg"}
                                    style={{
                                        width: 100,
                                        height: 120,
                                        fontSize: "3.5em"
                                    }}>{preview}</Avatar>}
                        </Badge>
                    </Col>
                </Row>
                <Form
                        ref={formRef}
                        fluid
                        onChange={(formValue)=>setForm(formValue)}
                        model={model}
                        formValue={form}
                    > 
                    <Row>
                        <Col xs={12} xsPush={6}   className="mt-4">
                            <label >Name</label>
                            <FormControl name={"firstName"} disabled placeholder={"Name"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} xsPush={6}   className="mt-4">
                            <label >Last name</label>
                            <FormControl name={"lastName"} disabled placeholder={"Last name"}/>
                        </Col>                 
                    </Row>
                    <Row>
                        <Col xs={12} xsPush={6}   className="mt-4">
                            <label >Email</label>
                            <FormControl name={"email"} disabled placeholder={"Last name"}/>
                        </Col>                 
                    </Row>
                    <Row>
                        <Col xs={12} xsPush={6}   className="mt-4">
                            <label >Phone</label>
                            <FormControl name={"cellNumber"} disabled placeholder={"Last name"}/>
                        </Col>                 
                    </Row>
                    <Row>
                        <Col xs={12} xsPush={6}   className="mt-4">
                            <label >Gender</label>
                            <FormControl searchable={false} disabled accepter={SelectPicker} name={"gender"} placeholder={"Gender"}  data={recurrent} block/>
                        </Col>                 
                    </Row>
                    <Row>
                        <Col xs={12} xsPush={6}   className="mt-4">
                            <label >Birthdate</label>
                            <FormControl   accepter={DatePicker} disabled name={"birthdate"} placeholder={"birthdate"}  block/>
                        </Col>                 
                    </Row>
                    <Row>
                        <Col xs={12} xsPush={6}   className="mt-4">
                            <label >I was born in</label>
                            <FormControl searchable={false}  accepter={SelectPicker} name={"city"} placeholder={"I was born in"} data={pais}  block/>
                        </Col>                 
                    </Row>
                </Form>
                <Row>
                    <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center mt-4">
                    <Button  color="blue" onClick={nextTopic} >
                      Next
                    </Button>
                    </Col>
                </Row>
        </div>
    )
}
