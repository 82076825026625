import React, { useState, useContext } from 'react';
import { Card, Form, Button, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';

//Importar Recursos
import logo from '../../assets/images/logo.png'

import { useForm } from '../../hooks/useForm';
import { registerTutor } from '../../service/service';

import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';


export const Register = ({ history }) => {
    const [validated, setValidated] = useState(false);
    const [fecha, setFecha] = useState("");

    const { dispatch } = useContext(AuthContext);


    const [formValues, handleInputChange] = useForm({
        firstName: "",
        lastName: "",
        email: "",
        cellNumber: "",
        gender: 0,
        countryId: 1,
        city: "Guatemala",
        appTypeId: 4,
        password: ""
    });

    let { firstName, lastName, email, cellNumber, gender, countryId, city, appTypeId, password } = formValues;

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {

            const dataInfo = await registerTutor({
                "firstName": firstName,
                "lastName": lastName,
                "email": email,
                "cellNumber": cellNumber,
                "countryId": countryId,
                "birthdate": fecha,
                "gender": parseInt(gender),
                "city": city,
                "appTypeId": appTypeId,
                "password": password,
                "role": "Tutor"
            });

            if (dataInfo.token != null) {
                setValidated(true)

                dispatch({
                    type: types.register,
                    payload: dataInfo
                });

                history.replace("/login");
            } else {
                setValidated(false)
            }
        }
    };
    return (
        <div className="d-flex justify-content-center
        d-flex align-items-center vh-100 login">
            <Card className="card border-0">
                <Card.Body>
                    <img src={logo}
                        className="logo__login centrado" alt="logo"
                        style={{ marginBottom: '1rem' }} />
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="First name"
                                    name="firstName"
                                    value={firstName}
                                    onChange={handleInputChange}
                                    size="lg"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="validationCustom02">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Last name"
                                    name="lastName"
                                    value={lastName}
                                    onChange={handleInputChange}
                                    size="lg"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="validationCustom02">
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={email}
                                    onChange={handleInputChange}
                                    size="lg"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="validationCustom02">
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder="Enter password"
                                    name="password"
                                    value={password}
                                    onChange={handleInputChange}
                                    size="lg"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="validationCustom02">
                                <Form.Control
                                    required
                                    type="number"
                                    placeholder="Enter your phone"
                                    name="cellNumber"
                                    value={cellNumber}
                                    onChange={handleInputChange}
                                    size="lg"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                            <div>
                                <DatePicker className="datepicker" onChange={(date, string) => setFecha(string)} />
                            </div>
                        </Form.Row>
                        <Form.Row>
                            <Form>
                            <Form.Group controlId="exampleForm.SelectCustom">
                                <Form.Control as="select" size="lg" custom>
                                <option>I was born in</option>
                                <option>Afganistán</option>
                                <option>Albania</option>
                                <option>Alemania</option>
                                <option>Andorra</option>
                                <option>Angola</option>
                                <option>Antigua y Barbuda</option>
                                <option>Arabia Saudita</option>
                                <option>Argelia</option>
                                <option>Argentina</option>
                                <option>Armenia</option>
                                <option>Australia</option>
                                <option>Austria</option>
                                <option>Azerbaiyán</option>
                                <option>Bahamas</option>
                                <option>Bangladés</option>
                                <option>Barbados</option>
                                <option>Baréin</option>
                                <option>Bélgica</option>
                                <option>Belice</option>
                                <option>Benín</option>
                                <option>Bielorrusia</option>
                                <option>Birmania</option>
                                <option>Bolivia</option>
                                <option>Bosnia y Herzegovina</option>
                                <option>Botsuana</option>
                                <option>Brasil</option>
                                <option>Brunéi</option>
                                <option>Bulgaria</option>
                                <option>Burkina Faso</option>
                                <option>Burundi</option>
                                <option>Bután</option>
                                <option>Cabo Verde</option>
                                <option>Camboya</option>
                                <option>Camerún</option>
                                <option>Canadá</option>
                                <option>Catar</option>
                                <option>Chad</option>
                                <option>Chile</option>
                                <option>China</option>
                                <option>Chipre</option>
                                <option>Ciudad del Vaticano</option>
                                <option>Colombia</option>
                                <option>Comoras</option>
                                <option>Corea del Norte</option>
                                <option>Corea del Sur</option>
                                <option>Costa de Marfil</option>
                                <option>Costa Rica</option>
                                <option>Croacia</option>
                                <option>Cuba</option>
                                <option>Dinamarca</option>
                                <option>Dominica</option>
                                <option>Ecuador</option>
                                <option>Egipto</option>
                                <option>El Salvador</option>
                                <option>Emiratos Árabes Unidos</option>
                                <option>Eritrea</option>
                                <option>Eslovaquia</option>
                                <option>Eslovenia</option>
                                <option>España</option>
                                <option>Estados Unidos</option>
                                <option>Estonia</option>
                                <option>Etiopía</option>
                                <option>Filipinas</option>
                                <option>Finlandia</option>
                                <option>Fiyi</option>
                                <option>Francia</option>
                                <option>Gabón</option>
                                <option>Gambia</option>
                                <option>Georgia</option>
                                <option>Ghana</option>
                                <option>Granada</option>
                                <option>Grecia</option>
                                <option>Guatemala</option>
                                <option>Guyana</option>
                                <option>Guinea</option>
                                <option>Guinea ecuatorial</option>
                                <option>Guinea-Bisáu</option>
                                <option>Haití</option>
                                <option>Honduras</option>
                                <option>Hungría</option>
                                <option>India</option>
                                <option>Indonesia</option>
                                <option>Irak</option>
                                <option>Irán</option>
                                <option>Irlanda</option>
                                <option>Islandia</option>
                                <option>Islas Marshall</option>
                                <option>Islas Salomón</option>
                                <option>Israel</option>
                                <option>Italia</option>
                                <option>Jamaica</option>
                                <option>Japón</option>
                                <option>Jordania</option>
                                <option>Kazajistán</option>
                                <option>Kenia</option>
                                <option>Kirguistán</option>
                                <option>Kiribati</option>
                                <option>Kuwait</option>
                                <option>Laos</option>
                                <option>Lesoto</option>
                                <option>Letonia</option>
                                <option>Líbano</option>
                                <option>Liberia</option>
                                <option>Libia</option>
                                <option>Liechtenstein</option>
                                <option>Lituania</option>
                                <option>Luxemburgo</option>
                                <option>Macedonia del Norte</option>
                                <option>Madagascar</option>
                                <option>Malasia</option>
                                <option>Malaui</option>
                                <option>Maldivas</option>
                                <option>Malí</option>
                                <option>Malta</option>
                                <option>Marruecos</option>
                                <option>Mauricio</option>
                                <option>Mauritania</option>
                                <option>México</option>
                                <option>Micronesia</option>
                                <option>Moldavia</option>
                                <option>Mónaco</option>
                                <option>Mongolia</option>
                                <option>Montenegro</option>
                                <option>Mozambique</option>
                                <option>Namibia</option>
                                <option>Nauru</option>
                                <option>Nepal</option>
                                <option>Nicaragua</option>
                                <option>Níger</option>
                                <option>Nigeria</option>
                                <option>Noruega</option>
                                <option>Nueva Zelanda</option>
                                <option>Omán</option>
                                <option>Países Bajos</option>
                                <option>Pakistán</option>
                                <option>Palaos</option>
                                <option>Panamá</option>
                                <option>Papúa Nueva Guinea</option>
                                <option>Paraguay</option>
                                <option>Perú</option>
                                <option>Polonia</option>
                                <option>Portugal</option>
                                <option>Reino Unido</option>
                                <option>República Centroafricana</option>
                                <option>República Checa</option>
                                <option>República del Congo</option>
                                <option>República Democrática del Congo</option>
                                <option>República Dominicana</option>
                                <option>Ruanda</option>
                                <option>Rumanía</option>
                                <option>Rusia</option>
                                <option>Samoa</option>
                                <option>San Cristóbal y Nieves</option>
                                <option>San Marino</option>
                                <option>San Vicente y las Granadinas</option>
                                <option>Santa Lucía</option>
                                <option>Santo Tomé y Príncipe</option>
                                <option>Senegal</option>
                                <option>Serbia</option>
                                <option>Seychelles</option>
                                <option>Sierra Leona</option>
                                <option>Singapur</option>
                                <option>Siria</option>
                                <option>Somalia</option>
                                <option>Sri Lanka</option>
                                <option>Suazilandia</option>
                                <option>Sudáfrica</option>
                                <option>Sudán</option>
                                <option>Sudán del Sur</option>
                                <option>Suecia</option>
                                <option>Suiza</option>
                                <option>Surinam</option>
                                <option>Tailandia</option>
                                <option>Tanzania</option>
                                <option>Tayikistán</option>
                                <option>Timor Oriental</option>
                                <option>Togo</option>
                                <option>Tonga</option>
                                <option>Trinidad y Tobago</option>
                                <option>Túnez</option>
                                <option>Turkmenistán</option>
                                <option>Turquía</option>
                                <option>Tuvalu</option>
                                <option>Ucrania</option>
                                <option>Uganda</option>
                                <option>Uruguay</option>
                                <option>Uzbekistán</option>
                                <option>Vanuatu</option>
                                <option>Venezuela</option>
                                <option>Vietnam</option>
                                <option>Yemen</option>
                                <option>Yibuti</option>
                                <option>Zambia</option>
                                <option>Zimbabue</option>
                                </Form.Control>
                            </Form.Group>
                            </Form>
                            {['radio'].map((type) => ( 
                                <div key={`custom-inline-${type}`} className="mb-4 checks">
                                    <Form.Check
                                        custom
                                        inline
                                        label="Male"
                                        type={type}
                                        id={`custom-inline-${type}-1`}
                                        name="gender"
                                        value="1"
                                        onChange={handleInputChange}

                                    />
                                    <Form.Check
                                        custom
                                        inline
                                        label="Female"
                                        name="gender"
                                        value="2"
                                        onChange={handleInputChange}
                                        type={type}
                                        id={`custom-inline-${type}-2`}
                                    />
                                </div>
                            ))}

                        </Form.Row>
                        <Form.Group>
                            <Form.Check
                                style={{width: '400px'}}
                                required
                                label="Agree to terms and conditions, and to have a remote 
                                interview previous to my registration."
                                feedback="You must agree before submitting."
                            />
                        </Form.Group>
                        <Button type="submit">Submit</Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Register;