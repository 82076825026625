import React, {  } from 'react';
import { Row, Col, Image, Badge, Navbar, Nav } from 'react-bootstrap';
import { DatePicker, Space, TimePicker, Select } from 'antd';
import moment from 'moment';


function onChange(date, dateString) {
    console.log(date, dateString);
  }

const Datepicker = () => {

    return (
        <div>
            <div className="d-block d-sm-none d-none 
            d-sm-block d-md-none text-center">
                <Navbar className="navbar shadow-sm fixed-top d-flex justify-content-center">
                    <Nav 
                    className="d-flex align-items-center">
                        <h4 className="heavy">
                            Datepicker
                        </h4>
                    </Nav>
                </Navbar>
            </div>
            <section 
            className="container-sm agenda__space_top">
                    <DatePicker onChange={onChange} />

            </section>
        </div>
        )
    }
    
    export default Datepicker;