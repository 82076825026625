import React, { useState, useContext } from 'react';
import { Card, Form, } from 'react-bootstrap';
import 'antd/dist/antd.css';
import { Icon, Button, Loader, Modal, InputGroup, Input, Alert } from 'rsuite';

//Importar Recursos
import logo from '../../assets/images/logo.png'
import { useForm } from '../../hooks/useForm';
import { login } from '../../service/service';

import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { Link } from 'react-router-dom';
import { BASE_PATH, IDAppFacebook, IDAppGoogle } from '../constants';
import Axios from 'axios';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import axios from 'axios';


export const LoginAlumno = ({ history }) => {
    const { dispatch } = useContext(AuthContext);
    const [loading, setLoading] = useState({ state: false, title: 'Wait a moment...' })
    const [stateModal, setStateModal] = useState(false);
    const [ emailRecover, setEmailRecover ] = useState('')

    const [validated, setValidated] = useState(false);
    const [formValues, handleInputChange] = useForm({
        email: '',
        password: ''
    });
    const { email, password } = formValues;

    const loginFacebook = async (response) => {

        try {
            setLoading({ state: true, title: 'Loading profile data ...' })
            let facebook = await axios.post(`${BASE_PATH}/Account/login/facebook`, {
                "accessToken": response.accessToken,
                "role": "Student"
            })
            console.log(facebook)
            if (facebook.status === 200) {
                setValidated(true)

                dispatch({
                    type: types.login,
                    payload: facebook.data
                });
                history.replace("/home-alumno")
            } else {

                Alert.success('Login failed')
            }
        } catch (error) {
            setLoading({ state: false, title: 'Wait a moment...' })
        }
    }

    const loginGoogle = async (resp) => {
        //console.log(resp)
        try {
            setLoading({ state: true, title: 'Loading profile data ...' })
            let google = await axios.post(`${BASE_PATH}/Account/login/google`, {
                "accessToken": resp.tokenId,
                "role": "Student"
            })
            console.log(google)
            if (google.status === 200) {
                setValidated(true)

                dispatch({
                    type: types.login,
                    payload: google.data
                });
                setLoading({ state: false, title: 'Wait a moment...' })
                history.replace("/home-alumno")
            } else {
                Alert.success('Login failed')
            }
        } catch (error) {
            setLoading({ state: false, title: 'Wait a moment...' })
        }
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        try {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();

            } else {
                event.preventDefault();
                setLoading({ ...loading, state: true })
                const dataInfo = await login({
                    "email": email,
                    "password": password,
                    "appId": 6,
                    "version": "1.0"
                });

                if (dataInfo.statusCode != null && dataInfo.statusCode === 401) {
                    setValidated(false)
                    Alert.warning('Incorrect username or password')
                    setLoading({ ...loading, state: false })
                } else {
                    setLoading({ ...loading, title: 'Loading profile data ...' })
                    if (dataInfo.role[0] === 'Student') {
                        setValidated(true)

                        dispatch({
                            type: types.login,
                            payload: dataInfo
                        });
                        setLoading({ state: false, title: 'Wait a moment...' })
                        history.replace("/home-alumno")
                    } else if (dataInfo.role[0] === 'Tutor') {
                        try {
                            setLoading({ state: true, title: 'Loading profile data ...' })
                            let res = await Axios.get(`${BASE_PATH}/Tutor/${dataInfo.userId}`)
                            if (res.status === 200) {
                                let data = await Axios.get(`${BASE_PATH}/Account/user/${dataInfo.userId}`)
                                if (data.status === 200) {
                                    let home = await Axios.get(`${BASE_PATH}/Tutor/home/${res.data.tutorId}`)
                                    console.log(home.data[0].themes)
                                    if (home.data[0].themes === null) {
                                        setValidated(true)
                                        dispatch({
                                            type: types.login,
                                            payload: dataInfo
                                        });
                                        history.replace({ pathname: "/verify_data", datos: { datos: data.data } })
                                        //  history.replace("/verify_data")
                                    } else {
                                        setValidated(true)

                                        dispatch({
                                            type: types.login,
                                            payload: dataInfo
                                        });
                                        setLoading({ state: false, title: 'Wait a moment...' })
                                        history.replace("/")
                                    }
                                }
                            }
                        } catch (e) {
                            console.log(e)
                        }
                    } else if (dataInfo.role[0] === 'AdminCompany') {
                        setValidated(true)

                        dispatch({
                            type: types.login,
                            payload: dataInfo
                        });
                        setLoading({ state: false, title: 'Wait a moment...' })
                        history.replace("/")
                    } else if (dataInfo.role[0] === 'Teacher') {
                        setValidated(true)

                        dispatch({
                            type: types.login,
                            payload: dataInfo
                        });
                        setLoading({ state: false, title: 'Wait a moment...' })
                        history.replace("/")
                    }


                }
            }
        } catch (error) {
            Alert.error('Server error, try again')
        }

    };

    const recoverPass = async() => {
        if(validarEmail(emailRecover)){

            try {
                let result = await Axios.post(`${BASE_PATH}/Account/user/forgotPassword`,{
                    email: emailRecover
                })
                console.log(result)
                if(result.status === 200){
                    Alert.success('An email has been sent to reset your password, please verify', 3000)
                    setEmailRecover('')
                    setStateModal(false)
                }

            } catch (error) {
                
            }

        }else{
            Alert.error('Enter a valid email', 2000)
        }
    }

    function validarEmail(valor) {
        if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)){
            return true
        } else {
            return false
        }
      }

    return (
        <div className="d-flex justify-content-center
        d-flex align-items-center vh-100 login">

            <Card className="card border-0" style={{ width: '22rem' }}>
                <Card.Body>
                    <img src={logo}
                        className="logo__login centrado" alt="logo"
                        style={{ marginBottom: '1rem' }} />
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group md="4" controlId="validationCustom02">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="Enter email"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                                size="lg"
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group md="4" controlId="validationCustom03">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                required
                                type="password"
                                placeholder="Enter password"
                                name="password"
                                value={password}
                                onChange={handleInputChange}
                                size="lg"
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Button style={{ marginBottom: '0.6rem' }}
                            className="btn-login shadow heavy" type="submit">
                            Login
                        </Button>
                    </Form>
                    <p className="text-forgot" onClick={() => setStateModal(true)} >Forgot my password</p>
                    {loading.state &&

                        <div style={{ textAlign: 'center' }}>
                            <Loader content={loading.title} vertical size="lg" />
                        </div>
                    }
                    <GoogleLogin
                        clientId={IDAppGoogle}
                        onSuccess={loginGoogle}
                        onFailure={loginGoogle}
                        cookiePolicy={'single_host_origin'}
                        autoLoad={false}
                        render={renderProps => (
                            <Button style={{ marginBottom: '0.6rem', marginTop: '2rem' }}
                                onClick={renderProps.onClick}
                                className="btn-login-redes heavy d-flex align-items-center " type="submit2">
                                <Icon icon='google-plus-square' size="2x" style={{ color: 'red', marginRight: '1rem', marginLeft: '1rem' }} /> Login with Google
                            </Button>
                        )}
                    >

                    </GoogleLogin>

                    <FacebookLogin
                        appId={IDAppFacebook}
                        callback={loginFacebook}
                        autoLoad={false}
                        render={renderProps => (
                            <Button style={{ marginBottom: '0.6rem' }}
                                onClick={renderProps.onClick}
                                className="btn-login-redes heavy d-flex align-items-center " type="submit">
                                <Icon icon='facebook-square' size="2x" style={{ color: 'blue', marginRight: '1rem', marginLeft: '1rem' }} /> Login with Facebook
                            </Button>
                        )}
                    />

                    <Link
                        to="/register/alumno"
                        className="">
                        <Button className="btn-login-redes heavy d-flex align-items-center">
                            <Icon icon='chevron-circle-right' size="2x" style={{ color: 'grey', marginRight: '1rem', marginLeft: '1rem' }} />Register as a Student
                        </Button>
                    </Link>
                    <br />
                </Card.Body>
            </Card>


            <Modal show={stateModal} onHide={() => setStateModal(false)} size="xs" className="modal-recovery">
                <Modal.Header>
                    <Modal.Title>Recover password</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <InputGroup >
                        <InputGroup.Addon> @</InputGroup.Addon>
                        <Input 
                            placeholder={'Enter the email you want to recover'} 
                            value={emailRecover}
                            onChange={(value)=>setEmailRecover(value)}
                            type="email"
                        />
                    </InputGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={recoverPass} appearance="primary">
                        Ok
                    </Button>
                    <Button onClick={() => setStateModal(false)} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default LoginAlumno;
