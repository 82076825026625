import React, { useContext } from 'react'
import {
    BrowserRouter as Router,
    Switch
  } from 'react-router-dom';

import { AuthContext } from '../auth/AuthContext';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import  LoginTutor  from '../componentes/Login/Login';
import { LoginAlumno } from '../componentes/LoginAlumno/Login';
import { Register } from '../componentes/Login/Register';
import { RegisterAlumno } from '../componentes/LoginAlumno/Register';
import { HomeRoutes } from './HomeRoute';
import RegisterPassAlumno from '../componentes/LoginAlumno/RegisterPass'
import Terms from '../componentes/Home/Terms';
import Licencia from '../componentes/Home/Licencia';
import RecoveryPass from '../componentes/LoginAlumno/RecoveryrPass';


export const AppRouter = (props) => {

    const { user } = useContext(AuthContext);

    return (
        <Router>
            <div>
                <Switch> 
                    <PublicRoute 
                        exact 
                        path="/reset/password" 
                        component={ RecoveryPass } 
                        isAuthenticated={ user.logged }
                    />
                    
                    <PublicRoute 
                        exact 
                        path="/login" 
                        component={ LoginAlumno } 
                        isAuthenticated={ user.logged }
                    />
                    <PublicRoute 
                        exact 
                        path="/tutor" 
                        component={ LoginTutor } 
                        isAuthenticated={ user.logged }
                    />
                    <PublicRoute 
                        exact 
                        path="/register/alumno/password" 
                        component={ RegisterPassAlumno } 
                        isAuthenticated={ user.logged }
                    />
                    <PublicRoute 
                        exact 
                        path="/register/alumno" 
                        component={ RegisterAlumno } 
                        isAuthenticated={ user.logged }
                    />
                    <PublicRoute 
                        exact 
                        path="/register/tutor" 
                        component={ Register } 
                        isAuthenticated={ user.logged }
                    />
                    <PublicRoute 
                        exact 
                        path="/terms" 
                        component={ Terms } 
                        isAuthenticated={ user.logged }
                    />
                    <PublicRoute 
                        exact 
                        path="/licencia" 
                        component={ Licencia } 
                        isAuthenticated={ user.logged }
                    />


                    <PrivateRoute 
                        props={props}
                        path="/" 
                        component={ HomeRoutes } 
                        isAuthenticated={ user.logged }
                    />
                </Switch>
            </div>
        </Router>
    )
}