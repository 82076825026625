import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Panel, Row, Col, Avatar, TagGroup, Tag } from 'rsuite';
import { BASE_PATH } from '../constants';
import Frame from '../Frame/Frame';
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext';
import { Button } from 'react-bootstrap';
import { Rate } from 'antd';

export const Session = (props) => {

    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [appointmentt, setAppointment] = useState([])
    const [apirtc, setApiRtc] = useState([]);
    const [data, setData] = useState([]);
    const [tutor, setTutor] = useState([]);
 
    useEffect(() => {
        dataUser()
        hacer()
    }, [])

    const dataUser = async () => {
        try {
            let res = await Axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
            if (res.status === 200) {
                setData(res.data)
                let tutor = await Axios.get(`${BASE_PATH}/Tutor/home/${props.setAppointment.tutorId}`)
                if (res.status === 200) {
                    setTutor(tutor.data[tutor.data.length - 1])
                    
                }
            }
            } catch (e) {
            console.log(e)
        }
    }
    const onSubmit = async (post) => {
        history.replace({ pathname: "/startgo", setAppointment: {setAppointment: props.setAppointment},  studenId: {studenId: props.setStudenId }, data: { data: data }})
    }

   /* const onSubmit = async (post) => {
        try { 
            const input =   props.setAppointment.firstName;
            const [firtsname] = input.split(' ');
            const inputA =   props.setAppointment.lastName;
            const [lastname] = inputA.split(' ');

            const inputfirstName = data.firstName;
            const [firtsnameA] = inputfirstName.split(' ');
            const inputlastName = data.lastName;
            const [lastnameA] = inputlastName.split(' ');
            console.log(firtsnameA, lastnameA)
            let apiRtc = await Axios.post(`https://apiargo.azurewebsites.net/api/Twilio/token`,
                {
                    //"userId": data.id,
                    "username": `${data.firstName} ${data.lastName}`,
                    // "userEmail": data.email,
                     "room": `${firtsname}${lastname}_${firtsnameA}${lastnameA}`,
                })
            if (apiRtc.status === 200) {
                let tiempo  = await Axios.get(`${BASE_PATH}/Conversations/stop/${apiRtc.data.logId}`)
                    if(tiempo.status===200){
                        history.replace({ pathname: "/call", tiempo: {tiempo: tiempo.data}, state: { data: apiRtc.data }, id: { appointment: props.setAppointment }, studenId: {studenId: props.setStudenId }, room: {room: `${firtsname}${lastname}_${firtsnameA}${lastnameA}`} })
                 }
                //setApiRtc(apiRtc.data);
               }
        } catch (error) {
            console.log(error);
        }
    }*/
    const [arrayy, setArray] = useState();
    const hacer = () => {
        if(props.setAppointment != undefined){
   /*         const {themes} = props.setAppointment
            const array = themes === null ? null: themes.split(",");
            setArray(array) */
        }else{
        } 
    }
    function UrlPath() {
        if (props.setAppointment != undefined ) {
            return (
                <Row className=" mt-4">
                <Col md={24} sm={12}>
                    <h2>
                    It's time for your session with {props.setAppointment.name&&props.setAppointment.name}!
                    </h2>
                    <Row>
                        <Col>
                            <Avatar size="lg"
                            circle
                            className="avatar__startsession"
                            src={tutor.imageProfile&&tutor.imageProfile}/>
                        </Col>
                    </Row>
                    <h5>
                    {props.setAppointment.tutorName&&props.setAppointment.tutorName}
                    </h5>
                    <Rate disabled defaultValue={tutor.rating&&tutor.rating}/>
                    <br/>
                    <br/>
                    <Col md={22} xs={22}
                        style={{
                            marginTop:'1rem',
                            marginBottom:'1rem'
                        }}>
                    </Col>
                    <br/>
                    <br/>
                    <text>
                        {tutor.city}
                    </text>
                    <br/>
                    <br/>
                        <Row>
                            <Col >
                                <Button 
                                className="heavy" onClick={() => onSubmit()} >Let´s Go!</Button>
                            </Col>
                        </Row>
                </Col>
            </Row>
            );
        }else{
            return (<div></div>);
        }
    }

    return (
        <div
        style={{
            marginTop:'3rem'
        }}
        className="d-flex justify-content-center text-center d-flex align-items-center"> 
              <UrlPath />
        </div>
    )
}
