import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Card } from 'react-bootstrap';
import { Form, FormControl, Grid, FormGroup, ControlLabel, Schema, Alert, Button } from 'rsuite';
import { StringType, DateType } from "schema-typed";
import { Switch, Upload, message, Rate, Modal, Drawer, Steps, Avatar } from 'antd';
import axios from 'axios';
import { BASE_PATH } from '../constants';

export const ExistingAcademy = (props) => {
  const formRef = useRef()
  const [data, setData] = useState([])
  const initForm = {
    email: "",
    code: ""
  }
  const [form, setForm] = useState(initForm)
  const model = Schema.Model({
    email: StringType().isRequired("Obligatory field."),
    code: StringType().isRequired("Obligatory field."),
  })

  const fetchData = async () => {
    try {

      let res = await axios.get(`${BASE_PATH}/Academy/student/data/${props.user}`)
      console.log(res)
      if (res.status === 200) {
        setData(res.data[res.data.length - 1])
      }
    } catch (e) {
      console.log(e)
    }
  }


  const disconectAcademy = async () => {
    if (!formRef.current.check()) {
      Alert.warning('Fill in the required fields', 2000)
    } else {
      console.log('Prueba')
      try {
        let result = await axios.put(`${BASE_PATH}/Academy/student/unlink`, {
          "appUserId": props.user,
          "email": form.email,
          "code": form.code

        })
        console.log(result)

        if(result.status === 200){
          Alert.success(result.data.message ,2000)
          props.onSuccess()
        }
      } catch (error) {
        console.log(error)
      }

    }
  }

  const sendCode = async () => {
    console.log('Prueba de codigo')
    try {
      let result = await axios.get(`${BASE_PATH}/Academy/student/getToken/${props.user}`)
      console.log(result)
      if (result.status === 200) {
        Alert.success('The code has been sent to your email, please check', 2000)
      } else {
        Alert.warning('Server failure, try again')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
    
  }, [])
  return (
    <div
      className="
        d-flex align-items-center
        justify-content-center ">
      <div>
        <h4>
          Argo Academy
        </h4>
        <div className="divider__space"></div>
        <Row>
          <Col md={4} xs={4}>
            <Avatar
              src={data.urlImageProfile && data.urlImageProfile}
              className="shadow"
              size={84} />
          </Col>
          <Col className="text-left heavy" md={8} xs={8}>
            <h3>
              {data.companyName}
            </h3>
          </Col>
        </Row>
        <div className="divider__space"></div>
        <h4
          className="heavy">
          Full Name
        </h4>
        {data.fullName}
        <div className="divider__space"></div>
        <h4
          className="heavy">
          Grade
        </h4>
        {data.gradeDescription}
        <div className="divider__space"></div>


        <Form>
          <FormGroup>
            <ControlLabel>Topic </ControlLabel>
            <FormControl size="lg" placeholder={data.theme} disabled />
          </FormGroup>
        </Form>

        <Form
          ref={formRef}
          model={model}
          formValue={form}
          onChange={(formValue) => setForm(formValue)}
        >
          <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
            <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center " >
              <Button
                className="heavy"
                onClick={sendCode}
              >
                Get a Code
              </Button>
            </Col>
          </Row>

          <FormGroup>
            <Row className="mt-4" style={{
              padding: '2rem',
              background: 'rgba(0, 0, 0, 0.049)',
              borderRadius: '20px'
            }}>
              <Col md={24} >
                <ControlLabel>Email</ControlLabel>
                <FormControl name={"email"} size="lg" placeholder={"Email"} />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row className="mt-4" style={{
              padding: '2rem',
              background: 'rgba(0, 0, 0, 0.049)',
              borderRadius: '20px'
            }}>
              <Col md={24} >
                <ControlLabel>Code</ControlLabel>
                <FormControl name={"code"} size="lg" placeholder={""} />
              </Col>
            </Row>
          </FormGroup>
        </Form>

        <Row
          style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
          <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center " style={{ textAlign: 'center' }} >
            <Button
              className="heavy" type="primary" onClick={disconectAcademy} >
              Disconnect from the academy
            </Button>
          </Col>
        </Row>

      </div>
    </div>
  )
}
