import React, { useContext, useRef, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Switch } from 'antd';
import 'antd/dist/antd.css';
import { StringType, BooleanType, NumberType, DateType } from "schema-typed";
import { Schema, Alert, Row, Col, Form, FormControl } from 'rsuite';

//Importar Recursos
import logo from '../../assets/images/logo.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { login } from '../../service/service';
import { BASE_PATH } from '../constants';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';

const RegisterPassAlumno = (props) => {

    const { dispatch } = useContext(AuthContext);
    const history = useHistory()
    const [validated, setValidated] = useState(false);

    const formRef = useRef()
    const initForm = {
        password: "",
        verifyPassword: "",

    }
    const [form, setForm] = useState(initForm)
    const model = Schema.Model({
        password: StringType().isRequired('This field is required.'),
        verifyPassword: StringType()
            .addRule((value, data) => {

                if (value !== data.password) {
                    return false;
                }

                return true;
            }, 'The two passwords do not match')
            .isRequired('This field is required.')
    });

    const crearUsuario = async () => {
        if (!formRef.current.check()) {
            Alert.warning("Empty required fields.", 1000)
            return
        } else {
            try {
                let crear = await axios.post(`${BASE_PATH}/Account/register/student`, {
                    "firstName": props.history.location.alumno.alumno.firstName,
                    "lastName": props.history.location.alumno.alumno.lastName,
                    "birthdate": props.history.location.alumno.alumno.birthdate,
                    "email": props.history.location.alumno.alumno.email,
                    "cellNumber": props.history.location.phone.phone,
                    "gender": props.history.location.alumno.alumno.gender,
                    "countryId": props.history.location.alumno.alumno.countryId,
                    "city": props.history.location.alumno.alumno.city,
                    "appTypeId": props.history.location.alumno.alumno.appTypeId,
                    "password": form.password,
                    "role": "Student"

                })
                if (crear.status === 200) {
                    Alert.success('User created')
                    const dataInfo = await login({
                        "email": crear.data.email,
                        "password": form.password,
                        "appId": 3,
                        "version": "1.0"
                    });

                    if (dataInfo.statusCode != null && dataInfo.statusCode === 401) {
                        setValidated(false)
                        Alert.warning('Incorrect username or password')
                    } else {
                        setValidated(true)
                        sessionStorage.setItem('status', 'new')
                        dispatch({
                            type: types.login,
                            payload: dataInfo
                        });
                        
                        history.replace({ pathname: "/topics-alumno" })
                    }
                }
            } catch (e) {
                if (e.response) {
                    if (e.response.status === 404 || e.response.status === 400) {
                        //  console.log(e.response.data.message);
                        Alert.warning(e.response.data.errors[0], 3000)
                    }
                }
            }
        }
    }
    return (
        <>
            <div className="d-flex justify-content-center
        d-flex align-items-center vh-100 login">
                <Card className="card border-0">
                    <Card.Body>
                        <Form ref={formRef}
                            fluid
                            onChange={(formValue) => setForm(formValue)}
                            model={model}
                            formValue={form}>
                            <Row>
                                <Col md={24}>
                                    <h6><label >Create Password</label></h6>
                                    <h6 className="text-secundario"
                                        style={{ fontSize: '12px' }}>
                                        Use at least seven characters,
                                        you can combine numbers, symbols <br />
                                        and at least one uppercase
                                    </h6>
                                    <FormControl name={"password"} type="password" placeholder={"Enter password"} />
                                </Col>
                                <Col md={24}>
                                    <h6><label >Verify password</label></h6>
                                    <FormControl name={"verifyPassword"} type="password" placeholder={"Verify password"} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center mt-4">
                                    <Button onClick={crearUsuario}>
                                        Register
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default RegisterPassAlumno;