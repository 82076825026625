import { faGenderless } from '@fortawesome/free-solid-svg-icons';
import { Select } from 'antd';
import axios from 'axios';
import React, { useRef, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { Form, Row, Col, FormControl, SelectPicker, Schema, Alert} from 'rsuite'
import {StringType, BooleanType, NumberType, DateType} from "schema-typed";
import PhoneInput from 'react-phone-number-input'
import { BASE_PATH } from '../constants';

export const UpdateTutor = (props) => {
    const { Option } = Select;
    const formRef = useRef()
     const [value, setValue] = useState(props.setData.cellNumber)
    const initForm = {
        firstName: "",
        lastName: "",
        cellNumber: "",
        gender: "",
        city: "",
        cellNumber: "",
        }
      const [form, setForm] = useState(initForm)
   
    
    const pais = [
        {
        "label": "Afganistán",
        "value": "Afganistán"
    }, {
        "label": "Albania",
        "value": "Albania"
    },
    {
        "label": "Alemania",
        "value": "Alemania"
    }, {
        "label": "Andorra",
        "value": "Andorra"
    },
    {
        "label": "Angola",
        "value": "Angola"
    }, {
        "label": "Antigua y Barbuda",
        "value": "Antigua y Barbuda"
    },
    {
        "label": "Arabia Saudita",
        "value": "Arabia Saudita"
    }, {
        "label": "Argelia",
        "value": "Argelia"
    },
    {
        "label": "Argentina",
        "value": "Argentina"
    }, {
        "label": "Armenia",
        "value": "Armenia"
    },
    {
        "label": "Australia",
        "value": "Australia"
    }, {
        "label": "Austria",
        "value": "Austria"
    },
    {
        "label": "Azerbaiyán",
        "value": "Azerbaiyán"
    }, {
        "label": "Bahamas",
        "value": "Bahamas"
    },
    {
        "label": "Bangladés",
        "value": "Bangladés"
    }, {
        "label": "Barbados",
        "value": "Barbados"
    },
    {
        "label": "Baréin",
        "value": "Baréin"
    }, {
        "label": "Bélgica",
        "value": "Bélgica"
    },
    {
        "label": "Belice",
        "value": "Belice"
    }, {
        "label": "Benín",
        "value": "Benín"
    },
    {
        "label": "Bielorrusia",
        "value": "Bielorrusia"
    }, {
        "label": "Birmania",
        "value": "Birmania"
    },
    {
        "label": "Bolivia",
        "value": "Bolivia"
    }, {
        "label": "Bosnia y Herzegovina",
        "value": "Bosnia y Herzegovina"
    },
    {
        "label": "Botsuana",
        "value": "Botsuana"
    }, {
        "label": "Brasil",
        "value": "Brasil"
    },
    {
        "label": "Brunéi",
        "value": "Brunéi"
    }, {
        "label": "Bulgaria",
        "value": "Bulgaria"
    },
    {
        "label": "Burkina Faso",
        "value": "Burkina Faso"
    }, {
        "label": "Guatemala",
        "value": "Guatemala"
    }, {
        "label": "El Salvador",
        "value": "El Salvador"
    }
    ]
    useEffect(() => {
        setForm({
            "firstName": props.setData.firstName,
            "lastName":  props.setData.lastName, 
            "cellNumber":  props.setData.cellNumber, 
            "gender":  props.setData.gender, 
            "birthdate": props.setData.birthdate,
            "city": props.setData.city
        })
      }, [])
      const model = Schema.Model({
        firstName:StringType().isRequired("Obligatory field."),
        lastName:StringType().isRequired("Obligatory field."),
        cellNumber:StringType().isRequired("Obligatory field."),
        gender:NumberType().isRequired("Obligatory field."),
        city:StringType().isRequired("Obligatory field."),
        })
        const datos = () => {
            return {
                "id": props.setData.id,
                "firstName": form.firstName,
                "lastName": form.lastName,
                "cellNumber": value,
                "gender": gender,
                "birthdate": form.birthdate,
                "city": city
            }
        }
        const updateTutor = async() => {
            if(!formRef.current.check()){
                Alert.warning("Empty required fields.",1000)
                return
              }else{
                try {
                    let update = await axios.post(`${BASE_PATH}/Account/update/user`,datos(),{
                        })
                            if(update.status===200){
                            props.onSuccess()
                            Alert.success("Updated data.", 1000);
                                
                            }
                }catch (e) { 
                    console.log(e)
                    Alert.warning("error",1000)
                }
            }
          }
          
          const recurrent = [{
            "label": "Male",
            "value": 1
        }, {
            "label": "Female",
            "value": 0
        }]
            const children = [];
          for (let i = 10; i < 36; i++) {
            children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
          }
          
          const [gender, setGender] = useState(props.setData.gender);
          const handleChange =(value) => {
            setGender(value)
          }
          const [city, setCity] = useState(props.setData.city);
          const handleChangecity =(value) => {
            setCity(value)
          }
          const [mode, setMode] = useState('readonly');
            const disabled = mode === 'disabled';
            const readOnly = mode === 'readonly';
            const plaintext = mode === 'plaintext';
    return (
        <>
             <Form
                    ref={formRef}
                    fluid
                    onChange={(formValue)=>setForm(formValue)}
                    model={model}
                    formValue={form}   
                    > 
                    <Row>
                        <Col md={24} >
                            <h6><label >Full Name</label></h6>
                            <FormControl name={"firstName"} size="lg"  placeholder={"Full Name"} disabled/>
                        </Col>
                        <Col md={24} className="mt-4">
                            <h6><label >Email and user</label></h6>
                            <FormControl name={"lastName"} size="lg"  placeholder={"Email and user"} disabled/>
                        </Col>                 
                    </Row>
                    <Row >
                        <Col  md={24} className="mt-4">
                            <h6><label >Phone number</label></h6>
                                <div
                                style={{
                                    padding: '0.6rem',
                                    border:'1px solid rgba(0, 0, 0, 0.222)',
                                    borderRadius:'5px',
                                    height:'2.6rem'
                                }}>
                                <PhoneInput 
                                    placeholder="+502 0000 0000"
                                    value={value}
                                    onChange={setValue} /> 
                                </div>
                        </Col>  
                        {/*<Col md={12} className="mt-4">
                            <div
                                style={{
                                    padding: '0.7rem',
                                    border:'1px solid rgba(0, 0, 0, 0.222)',
                                    borderRadius:'5px'
                                }}>
                            <Select disabled defaultValue={props.setData.gender}  style={{ width: '100%' }}  onChange={handleChange}>
                                {recurrent.map((post) =>
                                    <Option className="form-control" gutter={16} key={post.value} value={post.value} >{post.label}</Option>
                                )}
                            </Select>
                            </div>
                        </Col>*/}  
                        <Col md={12} className="mt-4">
                            <FormControl name={""} size="lg"  placeholder={"Gender"} disabled/>
                        </Col>   
                        <Col md={12} className="mt-4">
                            <FormControl name={""} size="lg"  placeholder={"date of birth"} disabled/>
                        </Col>             
                    </Row>
                    <Row>
                        {/*<Col md={24} className="mt-4">
                            
                            <div
                                style={{
                                    padding: '0.7rem',
                                    border:'1px solid rgba(0, 0, 0, 0.222)',
                                    borderRadius:'5px'
                                }}>
                            <Select  disabled defaultValue={props.setData.city}  style={{ width: '100%' }}  onChange={handleChangecity}>
                                {pais.map((post) =>
                                    <Option gutter={16} key={post.value} value={post.value} >{post.label}</Option>
                                )}
                            </Select>
                            </div>
                       </Col>*/}
                        <Col md={24} className="mt-4">
                            <FormControl name={""} size="lg"  placeholder={"País"} disabled/>
                        </Col>  
                        <Col md={24} className="mt-4">
                        <h5 style={{color:'blue'}}>Change your password</h5>
                        </Col>
                        <Col md={12} xs={12}>
                            <h6><label >Current Password</label></h6>
                            <FormControl  size="lg"  placeholder={""}/>
                        </Col>
                        <Col md={12} xs={12}>
                            <h6><label >New Password</label></h6>
                            <FormControl  size="lg"  placeholder={""}/>
                        </Col>         
                        
                    </Row>
                </Form>
                <Row>
                    <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center mt-4">
                    <Button onClick={updateTutor}  >
                      Save
                    </Button>
                    </Col>
                </Row>

        </>
    )
}
