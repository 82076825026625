import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { Navbar, Nav, Button, Badge, Col, Row } from 'react-bootstrap';
import { Modal } from 'antd';

//Importar Recursos
import '../../assets/css/icons/css/fontello.css';
import logo from '../../assets/images/logo.png'
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { BASE_PATH } from '../constants';
import axios from 'axios';
import { NewConversation } from './NewConversation';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Estudiante = () => {
    const divStyle = {
        color: 'blue',
        height: '6rem',
        textAlign: 'left',
        padding: '12px',
        background: 'rgb(247, 247, 247)',
        marginTop: '15px',
        borderRadius: '100px'
    };
    const divStyleP = {
        color: 'blue',
        height: '6rem',
        textAlign: 'left',
        padding: '12px',
        background: 'rgb(247, 247, 247)',
        marginTop: '15px',
        borderRadius: '100px',
        cursor: "pointer"
    };
    
    
    const containerStyle = {
        maxWidth: '100%',
        margin: '0 auto',
    }

    const history = useHistory();

    const [isModalVisibled, setIsModalVisibled] = useState(false);

    const onSucces = () => {
      setIsModalVisibled(false);
      setIsModalVisibleH(false);
      obtenerLista()
    }
    const handleSessionID = () => {
      setIsModalVisibled(true);
    };  
    const handleOk = () => {
      setIsModalVisibled(false);
    };
    const handleCancel = () => {
      setIsModalVisibled(false);
    };

    const [data, setData] = useState([]);
    const [studentId, setStudentId] = useState([]);
    const { user } = useContext(AuthContext);
    const [isModalVisibleH, setIsModalVisibleH] = useState(false);

    const showModalH = () => {
        setIsModalVisibleH(true);
    };
  
    const handleOkH = () => {
        setIsModalVisibleH(false);
    };
  
    const handleCancelH = () => {
        setIsModalVisibleH(false);
    };


    const navigate=(path)=>{
        window.location.href="student#/"+path
    }

    const [postList, setPostList] = useState({
        list: [1,2,3,4]
    }); 
    // tracking on which page we currently are
    const [page, setPage] = useState(1);
    // add loader refrence 
    const loader = useRef(null);

    useEffect(() => {
         var options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0
         };
        // initialize IntersectionObserver
        // and attaching to Load More div
         const observer = new IntersectionObserver(handleObserver, options);
         if (loader.current) {
            observer.observe(loader.current)
         }

    }, []);


    useEffect(() => {
        obtenerLista()
        // here we simulate adding new posts to List
        const newList = postList.list.concat([1,1,1,1]);
        setPostList({
            list: newList
        })
    }, [page])

    // here we handle what happens when user scrolls to Load More div
   // in this case we just update page variable
    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {   
            setPage((page) => page + 1)
        }
    }
    const obtenerLista = async () => {
            try{
                let res = await axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
                    if (res.status === 200) {
                        let resid = await axios.get(`${BASE_PATH}/user/${user.userId}`)
                        setStudentId(resid.data)
                        if (resid.status === 200) {
                            let data = await axios.get(`${BASE_PATH}/User/tickets/${resid.data.studentId}`)
                            if (data.status === 200) {
                             setData(data.data)
                             }
                        }
                    }
            }catch (e){
                console.log(e)
            }
    }
    const chat = (post) => {
        history.replace({ pathname: "/help-message", chatId: {chatId: post}, id: {id: studentId}})
    }
    return (
        <div>
            <div className="d-none d-md-none d-lg-block">
                <Navbar className="navbar shadow-lg fixed-top">
                    <div className="container navbar__first">
                    <Navbar.Brand to="/">
                    <img src={logo} 
                        className="logo" alt="logo"/> 
                        <text style={{fontSize:'18px', marginLeft:'1rem', color:'grey', wordSpacing:'0.2rem'}}>Practice is everything</text>
                    </Navbar.Brand>
                        <Nav className="d-flex justify-content-end heavy">
                            <Link 
                                to="/home-alumno" 
                                className="btn-navbar-first
                                d-flex align-items-center">
                                <i className="demo-icon-first icon-home2"></i> Home
                            </Link>
                            <Link 
                                to="/calendar-alumno" 
                                className="btn-navbar-first menu-calendario
                                d-flex align-items-center">
                                    <i className="demo-icon-first icon-calendar"></i> Calendar
                            </Link>
                            <Link 
                                to="/log-alumno" 
                                className="btn-navbar-first menu-log
                                d-flex align-items-center">
                                    <i className="demo-icon-first icon-log"></i> Log
                                </Link>
                        </Nav>
                    </div>
                </Navbar>
                
                <div className="fixed-bottom copyright">
                    <h6>Copyright 2021, Argo.</h6>
                </div>
                <Link
                to="/help">
                    <Button
                    className="btn btn__help heavy" type="submit">
                            ?
                    </Button>
                </Link>
                <Modal title="We are here to help!" 
                    footer={false}
                    visible={isModalVisibleH} 
                    onOk={handleOkH} 
                    onCancel={handleCancelH}>
                        Here you'll find your support history. <br/>
                        You can also create a new case by clicking the button below.     
                        <Button
                        onClick={handleSessionID}
                        className="
                        btn-show-alumno  
                        shadow-lg">
                        <h4
                            className="text-white heavy">
                            Start a new conversation
                        </h4>
                        </Button>     
                        <div 
                        style={{
                            width: '100%'
                        }} 
                        className="dropdown__divider">
                        </div>  
                        <Col
                        className="log__data_history">
                            <div style={containerStyle}>
                            <div className="post-list">
                                { 
                                    data.map((post, index) => {
                                        if(post.status === 'In Progress'){
                                            return (
                                            <div key={index} className="post shadow-sm" style={divStyleP} onClick={() => chat(post)} >
                                                <Row>
                                                    <Col lg="8"  xs="6">
                                                        <Badge pill variant="warning">
                                                            {post.status}
                                                        </Badge>
                                                        <p>
                                                            <text
                                                            style={{
                                                                fontSize: '18px',
                                                                color: 'black'}}>
                                                                ... / ...
                                                            </text>
                                                            <br/>
                                                            <text
                                                            style={{
                                                                fontSize: '12px',
                                                                color: 'grey'}}>
                                                                 Case No. {post.ticketId}
                                                            </text>
                                                            </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            )
                                        }else{
                                            return (<div key={index} className="post shadow-sm" style={divStyleP} onClick={() => chat(post)}>
                                            <Row>
                                                <Col lg="8"  xs="6">
                                                    <Badge pill variant="warning">
                                                        {post.status}
                                                    </Badge>
                                                    <p>
                                                        <text
                                                        style={{
                                                            fontSize: '18px',
                                                            color: 'black'}}>
                                                            ... / ...
                                                        </text>
                                                        <br/>
                                                        <text
                                                        style={{
                                                            fontSize: '12px',
                                                            color: 'grey'}}>
                                                             Case No. {post.ticketId}
                                                        </text>
                                                        </p>
                                                </Col>
                                            </Row>
                                            
                                        </div>)
                                        }
                                        
                                    })
                                }
                                <div className="loading" ref={loader}>
                                </div>
                            </div>
                        </div>
                        </Col>  
                </Modal>
            </div>

            <div className="d-block d-sm-none d-none 
            d-sm-block d-md-none text-center">
                <Navbar className="navbar shadow-lg fixed-bottom d-flex justify-content-center">
                    <Nav>
                        <Link 
                            to="/home-alumno" 
                            className="btn-navbar-first
                            d-flex align-items-center">
                            <i className="demo-icon-first icon-home2"></i> 
                      
                        </Link>
                        <Link 
                            to="/calendar-alumno" 
                            className="btn-navbar-first
                            d-flex align-items-center menu-calendario-2">
                                <i className="demo-icon-first icon-calendar"></i> 
                        </Link>
                        <Link 
                            to="/log-alumno" 
                            className="btn-navbar-first
                            d-flex align-items-center menu-log-2">
                                <i className="demo-icon-first icon-log"></i>
                        </Link>
                        <Link 
                            to="/help" 
                            className="btn-navbar-first
                            d-flex align-items-center btn__help-2">
                                <Button
                                className="bold btn__review__alumno">Help</Button>
                        </Link>
                    </Nav>
                </Navbar>
            </div>
        </div>

        )
    }
    
    export default Estudiante;