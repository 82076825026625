import React, { useEffect, useState, useRef, useContext } from 'react';
import { Row, Col, Image, Card, Button, Badge, Nav, Navbar } from 'react-bootstrap';
import { Rate, Modal } from 'antd';

//Importar Recursos
import Estudiante from '../Navbars/NavAlumno';
import Avatar from '../../assets/images/avatar2.jpg';
import { AuthContext } from '../../auth/AuthContext';
import { BASE_PATH } from '../constants';
import * as moment from "moment";
import axios from 'axios';
import { TagGroup } from 'rsuite';
import { Recharge } from './Recharge';
import Great from '../../assets/images/great.gif'

const divStyle = {
    color: 'blue',
    height: '6rem',
    textAlign: 'left',
    padding: '12px',
    background: 'rgb(247, 247, 247)',
    marginTop: '15px',
    borderRadius: '100px'
};


const containerStyle = {
    maxWidth: '100%',
    margin: '0 auto',
}

const LogAlumno = () => {
    
    const { user } = useContext(AuthContext);
    const [historial, setAppointment] = useState([]);
    const [billetera, setWallet] = useState([]);
    const [monthSessions, setMonthSessions] = useState([])
    const [postList, setPostList] = useState({
        list: [1, 2, 3, 4]
    });
    const [isModalVisibleAC, setIsModalVisibleAC] = useState(false);

    const showModalAC = () => {
        setIsModalVisibleAC(true);
    };

    const handleOkAC = () => {
        setIsModalVisibleAC(false);
    };

    const handleCancelAC = () => {
        setIsModalVisibleAC(false);
    };

    const [isModalVisibleSU, setIsModalVisibleSU] = useState(false);
    const showModalSU = () => {
        fetchData()
        setIsModalVisibleAC(false);
        setIsModalVisibleSU(true);
    };

    const handleOkSU = () => {
        setIsModalVisibleSU(false);
    };

    const handleCancelSU = () => {
        setIsModalVisibleSU(false);
    };  
    // tracking on which page we currently are
    const [page, setPage] = useState(1);
    // add loader refrence 
    const loader = useRef(null);

    useEffect(() => {
        
        fetchData()
        var options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0
        };
        // initialize IntersectionObserver
        // and attaching to Load More div
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current)
        }
    }, []);

    const fetchData = async () => {
        try { 
            let usurio = await axios.get(`${BASE_PATH}/User/${user.userId}`)
            if (usurio.status === 200) {
                let res = await axios.get(`${BASE_PATH}/User/history/${usurio.data.studentId}`)
                if (res.status === 200) {
                    setAppointment(res.data)
                    let wallet = await axios.get(`${BASE_PATH}/User/wallet/${user.userId}`)
                    if (wallet.status === 200) {
                      setWallet(wallet.data)
                       let monthSessions = await axios.get(`${BASE_PATH}/User/conversations/${usurio.data.studentId}`) 
                        if (monthSessions.status === 200) {
                            setMonthSessions(monthSessions.data)
                        }
                    }
                }
            }
           
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        // here we simulate adding new posts to List
        const newList = postList.list.concat([1, 1, 1, 1]);
        setPostList({
            list: newList
        })
    }, [page])

    // here we handle what happens when user scrolls to Load More div
    // in this case we just update page variable
    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            setPage((page) => page + 1)
        }
    }


    return (
        <div
        className="top__space">
            <Estudiante />
            <div className="d-block d-sm-none d-none 
            d-sm-block d-md-none text-center">
                <Navbar className="navbar shadow-sm fixed-top d-flex justify-content-center">
                    <Nav 
                    className="d-flex align-items-center">
                        <h4 className="heavy">
                            This month's log
                        </h4>
                    </Nav>
                </Navbar>
            </div>
            <section
                className="container-sm">
                <Row>
                    <Col lg="4"
                    className="log__data__first">
                        <h1 className="d-none d-md-none d-lg-block">This month's log</h1>
                        <Button className="btn__log__buy text-left shadow-lg" onClick={showModalAC}>
                            Buy credit
                        </Button>
                        <Button className="btn btn__balance" type="submit">
                            {billetera.balance}
                        </Button>
                        <br/>
                                    <Row>
                                        <Col className="text-center card__data card__data__log">
                                            <h3>
                                            {monthSessions.monthSessions&&monthSessions.monthSessions} 
                                           </h3>
                                            <h6>Sessions this month</h6>
                                            <br />
                                        </Col>
                                    </Row>
            
                    </Col>
                    <Col
                    className="log__data_history__alumno">
                        <h4>History</h4>
                        <div style={containerStyle}>
                            <div className="post-list">
                                {
                                    historial.map((post, index) => {
                                        const fecha = moment(post.date).format('lll');
                                        return (<div key={index} className="post shadow-sm" style={divStyle}>
                                            <Row>
                                                <Col className="d-flex 
                                                justify-content-center"
                                                lg="2" md="auto" xs="3">
                                                    <Image src={post.urlProfileImage&&post.urlProfileImage}
                                                        roundedCircle
                                                        className="avatar" />
                                                </Col>
                                                <Col lg="8" xs="9">
                                                    <Badge pill variant="warning">
                                                        <h6 style={{fontSize:'12px', marginBottom:'-0.2rem'}}>Next up</h6>
                                                    </Badge>
                                                    <p>
                                                        <h6
                                                        style={{
                                                            fontSize: '18px',
                                                            color: 'black'}}>
                                                            {fecha}
                                                        </h6>
                                                        <br/>
                                                        <h6
                                                        style={{
                                                            marginTop:'-2rem',
                                                            fontSize: '12px',
                                                            color: 'grey'}}>
                                                            {post.tutorName} 
                                                        </h6>
                                                        <br/>
                                                        <h6
                                                        style={{
                                                            marginTop:'-2rem',
                                                            fontSize: '12px',
                                                            color: 'grey'}}>
                                                            {post.status}
                                                        </h6>
                                                    </p>
                                                </Col>
                                            </Row>

                                        </div>)
                                    })
                                }
                                <div className="loading" ref={loader}>
                                    
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
            <Modal
              footer={false}
              visible={isModalVisibleAC} 
              onOk={handleOkAC} 
              onCancel={handleCancelAC}>
                <>
                 <Recharge recargar={showModalSU} setWallet={billetera}/>
                </>
              </Modal>
              <Modal
              footer={false}
              visible={isModalVisibleSU} 
              onOk={handleOkSU} 
              onCancel={handleCancelSU}>
                <>
                <div
                  className="
                  d-flex align-items-center
                  justify-content-center text-center">
                  <div>
                    <img src={Great} height='200px'/>
                      <h2
                      className="heavy text-secundario">
                          Awesome!
                      </h2>
                      <h4
                      className="bold">
                      Your new balance is
                      </h4>
                      <h1
                      className="heavy">
                          ${billetera.balance}
                      </h1>
                      <div className="divider__space"></div>
                  </div>
                </div>
                </>
              </Modal>
        </div>
    )
}

export default LogAlumno;