import React, { state, handleChange, useContext, useState, useEffect } from 'react';
import { Row, Col, Card, FormControl, Form, Button} from 'react-bootstrap';
import { Notification,  } from 'rsuite';
import { Link, useHistory } from 'react-router-dom'
import { Switch, Upload, message, Rate, Alert, Modal, Drawer, Avatar } from 'antd';
import StartSessionPerfil from './StartSessionPerfil';
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { AuthContext } from '../../auth/AuthContext';
import Estudiante from '../Navbars/NavAlumno';
import { UploadOutlined } from '@ant-design/icons';
import { MdModeEdit } from "react-icons/md";
import { types } from '../../types/types';

//Importar Recursos
import { BASE_PATH } from '../constants';
import Axios from 'axios';
import { StartSession } from './StartSession';
import { Session } from './Session';
//import Avatar from '../../assets/images/avatar.jpg';
import TopicsEdit from '../LoginAlumno/TopicsEdit';
import ReviewApp from '../Frame/ReviewApp';
import Great from '../../assets/images/great.gif'
import { Recharge } from './Recharge';
import { EditAlumnoL } from '../LoginAlumno/EditAlumnoL';
import { ConnectAcademy } from './ConnectAcademy';
import { ExistingAcademy } from './ExistingAcademy';

//IntroJS
import '../HomeAlumno/introjs.css';
import { Steps, Hints } from 'intro.js-react';

{ /*
    title: 'Second',
    content:
    <div
    className="
    d-flex align-items-center
    justify-content-center ">
      <div>
        <h4>
          Argo Academy
        </h4>
        <div className="divider__space"></div>
        <Row>
          <Col md={4} xs={4}>
          <Avatar 
          src={ImgAvatar}
          className="shadow" 
          size={84}/>
          </Col>
          <Col className="text-left heavy" md={8} xs={8}>
            <h3>
              Colegio Monte Verde
            </h3>
          </Col>
        </Row>
        <div className="divider__space"></div>
        <h4
        className="heavy">
            Full Name
        </h4>
            Egda Marisol Soyos Monroy
        <div className="divider__space"></div>
        <h4
        className="heavy">
            Grade
        </h4>
            6to. Primaria "A"
        <div className="divider__space"></div>
        <Form.Row>
        <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>Topic</Form.Label>
              <Form.Control
              disabled
              type="text" 
              name="firstName"
              placeholder="Simple Past"
              size="lg"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
        <Form.Row
        style={{
          padding:'1rem',
          background:'rgba(0, 0, 0, 0.049)',
          borderRadius:'20px'
          }}>
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>Delete Account</Form.Label>
            <br/>
            <Button
            className="heavy">
              Get a Code
            </Button>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="validationCustom02">
              <Form.Label 
              className="d-flex justify-content-start">Code</Form.Label>
              <Form.Control
              className="border-0"
                  required
                  type="text"
                  
                  size="lg"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <br/>
            <Button
            className="heavy">
              Delete
            </Button>
        </Form.Row>
      </div>
    </div>,
 */ }

const HomeAlumno = (props) => {



  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const { user } = useContext(AuthContext);
  const { loading, imageUrl } = useState();
  const [datos, setData] = useState([]);
  const [studenId, setStudenId] = useState([]);
  const [home, setHome] = useState([{}]);
  const [billetera, setWallet] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [appointmentid, setAppointmentId] = useState([]);
  const [appointmentt, setAppointment] = useState([]);
  const [llamada, setLlamada] = useState([]);
  const [isModalVisibleddd, setIsModalVisibleddd] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [tutores, setTutores] = useState([]);
  const [monthSessions, setMonthSessions] = useState([])
  const [booked, setBooked] = useState([])
  const [start, setStart] = useState(false)

  const starts = () => {
    if (props.location.estado === undefined) {
      //console.log('vacio')
    } else {
      handleSession()
    }
    //  setStart(props.location.estado.estado&&props.location.estado.estado)
    //  console.log(start)
  }

  const [isModalVisibleAC, setIsModalVisibleAC] = useState(false);

  const showModalAC = () => {
    setIsModalVisibleAC(true);
  };

  const handleOkAC = () => {
    setIsModalVisibleAC(false);
  };

  const handleCancelAC = () => {
    setIsModalVisibleAC(false);
  };

  const [isModalVisibleSU, setIsModalVisibleSU] = useState(false);
  const showModalSU = () => {
    fetchData()
    setIsModalVisibleAC(false);
    setIsModalVisibleSU(true);
  };

  const handleOkSU = () => {
    setIsModalVisibleSU(false);
  };

  const handleCancelSU = () => {
    setIsModalVisibleSU(false);
  };


  const [isModalVisibleS, setIsModalVisibleS] = useState(false);

  const showModalS = () => {
    setIsModalVisibleS(true);
  };

  const handleOkS = () => {
    setIsModalVisibleS(false);
  };

  const handleCancelS = () => {
    setIsModalVisibleS(false);
  };

  const [isModalVisibleT, setIsModalVisibleT] = useState(false);

  const showModalT = () => {
    setIsModalVisibleT(true);
  };

  const handleOkT = () => {
    setIsModalVisibleT(false);
  };

  const handleCancelT = () => {
    setIsModalVisibleT(false);
  };

  const [isModalVisibleC, setIsModalVisibleC] = useState(false);

  const showModalC = () => {
    setIsModalVisibleC(true);
  };

  const handleOkC = () => {
    setIsModalVisibleC(false);
  };

  const handleCancelC = () => {
    setIsModalVisibleC(false);
  };

  const [isModalVisibleR, setIsModalVisibleR] = useState(false);

  const showModalR = () => {
    setIsModalVisibleR(true);
  };

  const handleOkR = () => {
    setIsModalVisibleR(false);
  };

  const handleCancelR = () => {
    setIsModalVisibleR(false);
  };

  const tutoresdisponobles = async () => {
    try {
      let res = await Axios.get(`${BASE_PATH}/Tutor/available?getNumber=3`, {

      })
      if (res.status === 200) {
        setTutores(res.data)
        //  console.log(res)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const close = () => {
    fetchData()
    setIsModalVisibleddd(false);
    setIsModalVisible(false);
  }
  const handleSession = () => {
    console.log(user)
    getStateLink()
    tutoresdisponobles();
    setIsModalVisible(true);
  };


  const getStateLink = async () => {
    try {
      console.log(studenId.studentId)
      let result = await Axios.post(`${BASE_PATH}/Visa/checkLink`, {
        "appUserId": user.userId,
        "studentId": studenId.studentId,
      })
      let wallet = await Axios.get(`${BASE_PATH}/User/wallet/${user.userId}`)
      console.log(result)
      console.log(wallet)
      if (wallet.status === 200) {
        setWallet(wallet.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleisModalVisibleddd = (post) => {
    setLlamada(post)
    console.log(post)
    setIsModalVisibleddd(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsModalVisibleddd(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalVisibleddd(false);
  };
  const handleOktutor = () => {
    setIsModalVisible(false);
    setIsModalVisibleddd(false);
  };

  const handleCanceltutor = () => {
    setIsModalVisibleddd(false);
  };
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  const onSearchTtutores = (data) => {
    setTutores(data)
  }

  const onSuccesst = () => {
    fetchData()
    setIsModalVisibleS(false);
    setVisible(false);
    setIsModalVisibleR(false)

  }


  const sendImage = async (image) => {

    const { onSuccess, onError, file, onProgress } = image;

    const fm = new FormData();
    fm.append("AppUserId", user.userId);
    fm.append("ProfileImage", file)

    const config = {
      headers: { "content-type": "multipart/form-data" }
    };

    try {
      const res = await Axios.post(
        `${BASE_PATH}/Account/storeImage`,
        fm,
        config
      );
      if (res.status === 200) {
        const resUser = await Axios.post(
          `${BASE_PATH}/Account/update/user`,
          {
            "id": user.userId,
            "imageProfile": res.data.urlImageProfile,
          }
        );
        fetchData()
        setAvatar(res.data.urlImageProfile)
      }
    } catch (err) {
      console.log("Eroor: ", err);
    }
  }
  const Avatar = home[home.length - 1] && home[home.length - 1].imageProfile;
  const handleChange = ({ file, fileList, event }) => {

    setDefaultFileList(fileList);

  };

  const editTopics = () => {
    fetchData()
    setIsModalVisibleT(false)
    setVisible(false);
  }
  const fetchData = async () => {
    try {

      let res = await Axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
      if (res.status === 200) {
        setData(res.data)
        let resid = await Axios.get(`${BASE_PATH}/user/${user.userId}`)
        if (resid.status === 200) {
          setStudenId(resid.data)
          let studentId = await Axios.get(`${BASE_PATH}/User/home/${resid.data.studentId}`)
          if (studentId.status === 200) {
            setHome(studentId.data)
            let wallet = await Axios.get(`${BASE_PATH}/User/wallet/${user.userId}`)
            if (wallet.status === 200) {
              setWallet(wallet.data)
              let monthSessions = await Axios.get(`${BASE_PATH}/User/conversations/${resid.data.studentId}`)
              if (monthSessions.status === 200) {
                setMonthSessions(monthSessions.data)
                let booked = await Axios.get(`${BASE_PATH}/User/appointment/booked/${user.userId}`)
                if (booked.status === 200) {
                  setBooked(booked.data)
                }
              }
            }
          }
        }

      }
    } catch (e) {
      console.log(e)
    }
  }
  const uploadButton = (
    <div>
      <Button
        className="pic-uploader bold "
        icon={<UploadOutlined />}><i className="icon-camera"></i>
      </Button>
    </div>
  );
  const customIcons = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <SmileOutlined />,
  };

  let imgAvatar;
  if (home.length > 0) {
    imgAvatar = home[0].imageProfile;
  }

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const { user: { name }, dispatch } = useContext(AuthContext);
  const history = useHistory();
  const handleLogout = () => {

    history.replace('/login/alumno');

    dispatch({
      type: types.logout 
    });
  }

  const [ showTutorial, setShowTutorial ] = useState(false)

  const verifieduserNew = () => {
    console.log('PRUEBA Tutorial')
    if(sessionStorage.getItem('status') === 'new'){
      setShowTutorial(true)
      
    }
  }
  
  //Aqui va la configuracion de los pasos del tutorial
  const stepsTutorial = [
    {
      element: '.menu-calendario-2',
      intro: '<h3>Calendar</h3> <h6>Keep up to date with the sessions that you have scheduled and those that are pending to take.</h6>',
    },
    {
      element: '.menu-log-2',
      intro: '<h3>Log</h3> <h6>Activity log of the platform, to have control of the sessions.</h6>',
    },
    {
      element: '.avatar-uploader',
      intro: '<h3>Settings</h3> <h6>Profile: Very useful with which you can modify some of your basic information, such as phone number, and password.</h6> <h6>Topics: You can add or remove.</h6> <h6>Argo Academy: You can subscribe your profile to be part of an institution or academy.</h6>',
    },
    {
      element: '.btn-show-alumno',
      intro: '<h3>Start Session</h3> <h6>You can find your preferred tutor and start a conversation, or schedule a session.</h6>',
    },
    {
      element: '.sessions-details',
      intro: '<h3>Session</h3> <h6>Record and behavior of the sessions you have and had available.</h6>',
    },
    {
      element: '.btn__balance_alumno',
      intro: '<h3>Buy More Sessions</h3> <h6>Each credit is worth USD $ 15.00, with which you can get a session of one hour. You will also receive a visa link to you email, to proceed with the payment. </h6>',
    },
    {
      element: '.boton-tutorial',
      intro: '<h6>In case you have missed something, or it is not clear to you, you can activate the tutorial.</h6>',
    },
    {
      element: '.btn__help-2',
      intro: '<h6>Here you will find your support history. You can also create a new case by clicking the button below.</h6>',
    },
  ];

  useEffect(() => {
    fetchData()
    starts()
    tutoresdisponobles()
    verifieduserNew()
  }, [])
  return (
    <>
      <Steps
        enabled={showTutorial}
        steps={stepsTutorial}
        initialStep={0}
        onExit={()=> {
          setShowTutorial(false)
          sessionStorage.removeItem('status')
        }}        
      />
          <Button
            className="boton-tutorial d-flex align-items-center heavy"
                  onClick={()=> setShowTutorial(true)}>
                  Tutorial
                </Button>
      <div
        className="top__space">
        <Estudiante />
        <section
          className="container-sm">
          <Row
            className="d-flex justify-content-center">
            <Col>
              <Card className="card__avatar image04 border-0">
                <Card.ImgOverlay>
                  <Card.Img src={imgAvatar} className="card-border" />
                  <div className="card-bottom">
                    <Button
                      onClick={showDrawer}
                      className="avatar-uploader"
                      style={{
                        fontSize: 18,
                        marginLeft: 12,
                      }}>
                      <i className="icon-settings d-flex align-items-center"></i>

                    </Button>
                    <Drawer
                      placement="right"
                      closable={false}
                      onClose={onClose}
                      visible={visible}
                      width={300}>
                      <div
                        className="d-flex justify-content-center">
                        <Card className="pic__perfil image04__previa border-0">
                          <Card.ImgOverlay>
                            <Card.Img src={imgAvatar} className="" />
                            <div className="card-bottom">
                              <Upload
                                name="avatar"
                                showUploadList={false}
                                customRequest={sendImage}
                                onChange={handleChange}
                                beforeUpload={beforeUpload}>

                                {imageUrl ? <img src={imageUrl} alt="avatar"
                                  onContextMenu="return false;"
                                  style={{ width: '100%' }} /> : uploadButton}
                              </Upload>
                            </div>
                          </Card.ImgOverlay>
                        </Card>
                      </div>
                      <div className="divider__space"></div>
                      <Button
                        onClick={showModalS}
                        style={{
                          borderRadius: 50,
                          width: '100%',
                          padding: '1rem',
                          marginBottom: '1rem',
                        }}>
                        Profile Settings
                      </Button>
                      <Modal title="Profile Settings"
                        style={{
                          top: 1
                        }}
                        footer={false}
                        visible={isModalVisibleS}
                        onOk={handleOkS}
                        onCancel={handleCancelS}>
                        <EditAlumnoL setData={datos} onSuccesst={onSuccesst} />
                      </Modal>
                      <Button
                        onClick={showModalT}
                        style={{
                          borderRadius: 50,
                          width: '100%',
                          padding: '1rem',
                          marginBottom: '1rem'
                        }}>
                        Topics
                      </Button>
                      <Modal title=""
                        footer={false}
                        visible={isModalVisibleT}
                        onOk={handleOkT}
                        onCancel={handleCancelT}>
                        <TopicsEdit setHome={home} editSucces={editTopics} />
                      </Modal>

                      <Button
                        onClick={showModalR}
                        style={{
                          borderRadius: 50,
                          width: '100%',
                          padding: '1rem',
                          marginBottom: '1rem'
                        }}>
                        Argo Academy
                      </Button>
                      <Modal
                        style={{
                          top: '1rem'
                        }}
                        footer={false}
                        visible={isModalVisibleR}
                        onOk={handleOkR}
                        onCancel={handleCancelR}>
                        {
                          home[home.length - 1].isStudentAcademy === false ?
                            <ConnectAcademy onSuccess={onSuccesst} user={user.userId} />
                            :
                            <ExistingAcademy onSuccess={onSuccesst} user={user.userId} />
                        }
                      </Modal>
                      <Button
                        onClick={handleLogout}
                        style={{
                          width: '100%',
                          padding: '1rem',
                          background: '#177AB3',
                          borderRadius: 50,
                          color: 'white',
                          marginTop: '45%'
                        }}>
                        Logout
                      </Button>
                    </Drawer>
                  </div>
                </Card.ImgOverlay>
              </Card>
              <div
                className="d-flex justify-content-center">
                <Button
                  className="
                btn__balance_alumno 
                d-flex align-items-center 
                heavy"
                  onClick={showModalAC}>
                  Buy more sessions
                </Button>
                <Modal
                  footer={false}
                  visible={isModalVisibleAC}
                  onOk={handleOkAC}
                  onCancel={handleCancelAC}
                  style={{ top: 10 }}>
                  <>
                    <Recharge recargar={showModalSU} setWallet={billetera} />
                  </>
                </Modal>
                <Modal
                  footer={false}
                  visible={isModalVisibleSU}
                  onOk={handleOkSU}
                  onCancel={handleCancelSU}>
                  <>
                    <div
                      className="
                  d-flex align-items-center
                  justify-content-center text-center">
                      <div>
                        <img src={Great} height='200px' />
                        <h2
                          className="heavy text-secundario">
                          Awesome!
                        </h2>
                        <h4
                          className="bold">
                          Check your email to complete your purchase of conversations
                        </h4>

                        <div className="divider__space"></div>
                      </div>
                    </div>
                  </>
                </Modal>
              </div>
            </Col>
            <Col lg="6"
              className="home__info home__info__alumno">
              <h1
                className="heavy">
                <text
                  className="home__hello">
                  Hello,
                </text>
                <br /> {datos.firstName}
              </h1>

              <Row>
                <Col>
                  <div className="text-center">
                    <Rate value={home[home.length - 1] && home[home.length - 1].rating} disabled />
                  </div>

                </Col>
                <Button
                  onClick={handleSession}
                  className="
                  btn-show-alumno 
                  shadow-lg
                  ">
                  <h2
                    className="text-white heavy">
                    Start session
                  </h2>
                </Button>
                <Modal
                  rounded
                  style={{
                    top: '0.1rem'
                  }}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}>
                  <StartSession
                    setHome={home[home.length - 1] && home[home.length - 1]}
                    setData={datos}
                    setStudenId={studenId}
                    setTutores={tutores}
                    onSearch={onSearchTtutores}
                    setIsModalVisibleddd={handleisModalVisibleddd}
                    refresh={tutoresdisponobles} />
                </Modal>
                <Modal
                  style={{
                    top: '2rem'
                  }}
                  visible={isModalVisibleddd}
                  onOk={handleOktutor}
                  onCancel={handleCanceltutor} a
                  footer={null}>
                  <StartSessionPerfil setLlamada={llamada} setData={datos} setStudenId={studenId} setAppointmenttt={setAppointmentId} onSuccess={close} />
                </Modal>
              </Row>

              <Row
                className="d-flex justify-content-center sessions-details">
                <Col xs="11" lg="3"
                  className="text-center card__data" style={{ backgroundColor: '#03b2cb' }}>
                  <h1 className="text-white bold">
                    {billetera.balance}
                  </h1>
                  <h5 className="text-white bold">Sessions availables</h5>
                </Col>
                <Col xs="5" lg="3"
                  className="text-center card__data">
                  <h1>
                    {booked.scheduledAppointments && booked.scheduledAppointments}
                  </h1>
                  <h6>Booked sessions</h6>
                </Col>
                <Col xs="5" lg="3"
                  className="text-center card__data">
                  <h1>
                    {monthSessions.monthSessions && monthSessions.monthSessions}
                  </h1>
                  <h6>Session this month</h6>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </div>


    </>
  );
}

export default HomeAlumno;