import firebase from "firebase";

export const inicializarFirebase = () => {
    firebase.initializeApp({
        apiKey: "AIzaSyAJpgpiiKpnRyx24va7swpGcbiqgkojt-8",
        authDomain: "argo-gt.firebaseapp.com",
        projectId: "argo-gt",
        storageBucket: "argo-gt.appspot.com",
        messagingSenderId: "567323604290",
        appId: "1:567323604290:web:eb5ce5293cff56fd6404db",
        measurementId: "G-H0PX8V7TPS"
    });
};

export const preguntarPermisos = async () => {
    try {
        const messaging = firebase.messaging();

        //await messaging.requestPermission();
        await Notification.requestPermission().then(async permission => {
            if (permission === "denied") {
                console.log("Permission wasn't granted. Allow a retry.");
                return;
            } else if (permission === "default") {
                console.log("The permission request was dismissed.");
                return;
            }
            const token = await messaging.getToken();
            console.log("user token: ", token);

            return token;
        });
    } catch (error) {
        console.error(error);
    }
};