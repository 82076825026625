import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Row, Avatar, Rate} from 'antd';
import { BASE_PATH } from '../constants'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext';
import { Button, TagGroup, Tag } from 'rsuite';

import ImgAvatar from '../../assets/images/avatar.jpg'

export const Solicitudes = (props) => {
    const { user } = useContext(AuthContext);
    const history = useHistory();
    const [apirtc, setApiRtc] = useState([]);
    const [data, setData] = useState([])
    useEffect(() => {
        dataUser()
        hacer()
    }, [])
    const dataUser = async () => {
        try {
            let res = await Axios.get(`${BASE_PATH}/Account/user/${user.userId}`)
            if (res.status === 200) {
                setData(res.data)
                }
            } catch (e) {
            console.log(e)
             }
        }
        const onSubmit = async () => {
            try {
                let disponible = await Axios.put(`${BASE_PATH}/Tutor/available`,
                {
                    "tutorId": props.setTutor.tutorId,
                    "available": false
                })
                if(disponible.status===200){
                let tutor = await Axios.put(`${BASE_PATH}/Tutor/appointment/${props.setSolicitudes.appointmentId}`,
                {
                    "appointmentId": props.setSolicitudes.appointmentId, 
                    "status": 2
                },{
                })
                    if(tutor.status===200){
                    const input =  props.setSolicitudes.firstName;
                    const [firtsname] = input.split(' ');
                    const inputA =  props.setSolicitudes.lastName;
                    const [lastname] = inputA.split(' ');
                    //const [firtsname, lastname] = input.split(' ');
                    const inputfirstName = data.firstName;
                    const [firtsnameA] = inputfirstName.split(' ');
                    const inputlastName = data.lastName;
                    const [lastnameA] = inputlastName.split(' ');
                    
                    console.log(firtsnameA, lastnameA)
                    let apiRtc  = await Axios.post(`https://apiargo.azurewebsites.net/api/Twilio/token`,
                    {
                       // "userId": data.id,
                      //  "username": `${data.firstName} ${data.lastName}`,
                       // "userEmail": data.email,
                       "room": `${firtsnameA}${lastnameA}_${firtsname}${lastname}`,
                       "username": `${data.firstName} ${data.lastName}`
                    })
                        if(apiRtc.status===200){
                          console.log(apiRtc.data)
                           let tiempo  = await Axios.get(`${BASE_PATH}/Conversations/stop/${apiRtc.data.logId}`)
                            if(tiempo.status===200){
                                history.replace({ pathname: "/call-tutor", tiempo: {tiempo: tiempo.data}, state: { data:  apiRtc.data }, id: { appointment: props.setSolicitudes }, tutor: {idTutor: props.setTutor}, room: {room: `${firtsnameA}${lastnameA}_${firtsname}${lastname}`} })
                            }
                          // setApiRtc(apiRtc.data);
                     //   history.replace({ pathname: "/call-tutor", state: { data: apiRtc.data }, id: { appointment: props.setSolicitudes }, tutor: {idTutor: props.setTutor} })
                        } 
                    }
                }
            }catch (e) { 
                console.log(e)
                //Alert.warning("Ingresar una imagen",1000)
            }
        }

        const onSubmitCancel = async (post) => {
            try {
                let tutor = await Axios.put(`${BASE_PATH}/Tutor/available`,
                {
                    "tutorId": props.setTutorpost.tutorId,
                    "available": false
                },{
                })
                    if(tutor.status===200){

                }
            }catch (e) { 
                console.log(e)
                //Alert.warning("Ingresar una imagen",1000)
            }
        }
       const [arrayy, setArray] = useState();
        const hacer = () => {
            if(props.setSolicitudes != undefined){
                const {themes} = props.setSolicitudes
                const array = themes === null ? null: themes.split(",");
                setArray(array)
            }else{
            }
        }

        function UrlPath() {
            if (props.setSolicitudes != undefined ) {
                    return (
                            <Row gutter={16} 
                            className="d-flex align-items-center
                            justify-content-center text-center">
                                <Col md={18} xs={22}>
                                    <Avatar size="lg"
                                    circle
                                    className="avatar__startsession"
                                    src={props.setSolicitudes.imageProfile&&props.setSolicitudes.imageProfile}/>
                                </Col>
                                <Col md={18} xs={22}
                                className="bold"
                                style={{
                                    fontSize: '18px',
                                    paddingTop:'1.5rem'
                                }}>
                                    {props.setSolicitudes.name}
                                </Col>
                                <Col md={22} xs={22}
                                style={{
                                    marginTop:'1rem',
                                    marginBottom:'1rem'
                                }}>
                                    <Rate disabled value={props.setSolicitudes.rating}/>
                                </Col>
                                Joe enjoys:
                                <Col md={22} xs={22}
                                style={{
                                    marginTop:'1rem',
                                    marginBottom:'1rem'
                                }}>
                                    <TagGroup>
                                       {arrayy && arrayy.map((item, index) => (
                                        <Tag
                                            key={index}
                                        >
                                            {item}
                                        </Tag>
                                        ))}
                                    </TagGroup>
                                </Col>
                                <Col md={22} xs={22}
                                style={{
                                marginTop:'1rem'
                                }}> 
                                    <Button onClick={()=>onSubmit()}
                                    className="
                                    btn__accept
                                    shadow-lg
                                    heavy">
                                        ¡Let´s go!
                                    </Button>
                                </Col>
                            </Row>
                    );  
                }else{
                    return (
                           <div></div>
                    );  
                  }
                
        }
        
       
        return (
        <div>
            <h3
            className="heavy text-center">
                ¡Time for a session!!
            </h3>
            <div className="
            d-flex justify-content-center">
                <UrlPath />
            
            </div>
        </div>
        )
}