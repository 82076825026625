import { Rate } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Panel, PanelGroup, Row, Col, Button, Avatar, Alert } from 'rsuite';
import { Input, Space, Modal } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import { BASE_PATH } from '../constants';
import StartSessionPerfil from './StartSessionPerfil';

import './StartSession.css';

export const StartSession = (props) => {
    const [show, setShow] = useState(false);
    const [appointmentt, setAppointmenttt] = useState([])
    useEffect(() => {
       
        //onSearch()
    }, [])
   
    const handelSession = (post) => {
       // props.cerrarModalListaTutores();
        props.setIsModalVisibleddd(post)
    }
    
    const { Search } = Input;
    const handlerefresh =() => {
        props.refresh()
    }
    const onSearch =async(value) => {
        if(value != ""){
            try {
                let res = await Axios.get(`${BASE_PATH }/Tutor/available?getNumber=3&search=${value}`,{ 
              
                })
                if(res.status===200){
                    props.onSearch(res.data)
                  //  console.log(res)
                }
            }catch (e){
                console.log(e)
            }
        }else {
        try {
            let res = await Axios.get(`${BASE_PATH }/Tutor/available?getNumber=3`,{ 
                  
            })
            if(res.status===200){
                props.onSearch(res.data)
              //  console.log(res)
            }
        }catch (e){
            console.log(e)
        }
        console.log(value)
        }
    }
/*onClick={()=> onSubmit(post)}*/
    return (
        <div
        style={{
            marginTop:'3rem'
        }}>
                   
            <Row>
                <Col>
                <Search
                    placeholder="Find your tutor"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={onSearch}
                    />
                </Col>
            </Row>
            { 
            props.setTutores.map((post) =>
                <Row key={post.id} className="mt-4">
                        <Col>
                            <Panel    
                            style={{
                                cursor:"pointer"
                            }} 
                            onClick={()=> handelSession(post)}
                            className="panel__startsession" >
                                <Row className="mt-4">
                                    <Col  lg="9" xs="12">
                                    <Avatar size="lg"
                                            circle 
                                            className="avatar__startsession"
                                            src={post.imageProfile&&post.imageProfile}
                                            />
                                    </Col>
                                    <Col lg="14"  xs="12">
                                        <p
                                        className="ficha__startsession">
                                        <h5
                                        className="heavy">
                                            {post.fullName}
                                        </h5>
                                        <br/>
                                        <Rate disabled defaultValue={post.rating} />
                                        <br/>
                                        <p
                                        style={{ 
                                            paddingTop:'0.4rem',
                                            marginBottom:'-1rem'}}>
                                                 { /* {post.themes} */ }
                                        </p>
                                        <br/>
                                        <text>
                                            {post.city}
                                        </text>
                                        </p>
                                    </Col> 
                                </Row>
                            </Panel>
                             
                        </Col>
                </Row>
            )}   
           <Row>
               <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center mt-4">
                    <Button  
                    className="heavy btn" color="blue" onClick={handlerefresh} >
                      Refresh
                    </Button>
               </Col>
           </Row> 
        </div>
    )
}