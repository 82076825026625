import axios from 'axios';
import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import { Col, ControlLabel, FlexboxGrid, Form, FormControl, Grid, Row, Schema, Alert } from 'rsuite'
import {StringType} from "schema-typed";
import { BASE_PATH } from '../constants';

export const NewConversation = (props) => {
    const formRef = useRef() 
    const [data, setdata] = useState({comment: ""})
    const [form, setForm] = useState(data)
    const model = Schema.Model({
        comment:StringType().isRequired("required."),
    })
    const onSend = async () => {
        if(!formRef.current.check()){
            Alert.warning("Empty required fields.",1000)
            return
        }else{
            try{
                let send = await axios.post(`${BASE_PATH}/User/tickets`,{
                    "studentId": props.setStudentId.studentId,
                    "detail": form.comment,
                    "canceled": true
                })
                if (send.status === 200) {
                    setdata({comment: ""})
                    setForm(data)
                    props.onSucces()
                    Alert.success('Message sent')
                }
            }catch (e){
                console.log(e)
            }
        }
    }
    return (
        <div>
            <Row justify="center">
                    <Form 
                      ref={formRef}           
                      onChange={(formValue)=>setForm(formValue)}
                     fluid
                     model={model}
                     formValue={form}>   
                       {/*
                            <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
                            <ControlLabel>Subject</ControlLabel>
                             <FormControl rows={1} name="comment" componentClass="textarea" />
                        </Col> 
                       */} 
                        <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
                            <ControlLabel>Comment</ControlLabel>
                             <FormControl rows={5} name="comment" componentClass="textarea" />
                        </Col>
                        <Col md={24} className="rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center mt-4">
                            <Button onClick={onSend}>Send</Button>
                        </Col>
                    </Form> 
            </Row> 
        </div>
    )
}
