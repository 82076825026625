import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom'
import { Switch } from 'antd';
import 'antd/dist/antd.css';
import { StringType, BooleanType, NumberType, DateType, HelpBlock } from "schema-typed";
import { Schema, Alert, Row, Col, Form, FormGroup, FormControl, ControlLabel } from 'rsuite';

//Importar Recursos
import logo from '../../assets/images/logo.png'
import axios from 'axios';
import { BASE_PATH } from '../constants';


const RecoveryPass = () => {

    const history = useHistory()
    const formRef = useRef()
    const [params, setParams] = useState({
        userId: '',
        code: ''
    })
    const [password, setPassword] = useState({
        pass1: '',
        pass2: ''
    })
    const model = Schema.Model({
        pass1: StringType().isRequired('This field is required.'),
        pass2: StringType()
            .addRule((value, data) => {

                if (value !== password.pass1) {
                    return false;
                }

                return true;
            }, 'The two passwords do not match')
            .isRequired('This field is required.')
    });

    const changePassword = async () => {
        if (formRef.current.check()) {
            try {
                let result = await axios.post(`${BASE_PATH}/Account/user/resetPassword`, {
                    appUserId: params.userId,
                    password: password.pass1,
                    confirmPassword: password.pass2,
                    code: params.code
                })
                console.log(result)

                if (result.status === 200) {
                    Alert.success('Your password has been changed successfully!!!, please login again', 2000)
                    history.push('/login')
                } else {
                    Alert.error('Server failure, please request the email again to reset your password', 2000)
                }
            } catch (e) {

                if (e.response.status === 404 || e.response.status === 400) {
                    if (e.response.data.errors != undefined) {
                        Alert.warning(e.response.data.errors[0], 3000)
                    }else{
                        Alert.error('Server failure, please request the email again to reset your password', 2000)
                    }
                }
            }
        }
    }

    const getParameter = () => {
        const urlParams = new URLSearchParams(window.location.search);
  
        if (urlParams.get('code') && urlParams.get('appUserId')) {
            setParams({
                userId: urlParams.get('appUserId'),
                code: getParameterByName('code')
            })
        } else {
            history.push('/login')
        }

    }

    function getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
        return results === null ? "" : decodeURIComponent(results[1]);
    }

    useEffect(() => {
        getParameter()
    }, [])

    return (
        <>
            <div className="d-flex justify-content-center
        d-flex align-items-center vh-100 login">
                <Card className="card border-0">
                    <Card.Body>
                        <img src={logo}
                            className="logo__login " alt="logo"
                            style={{ marginBottom: '1rem' }} />
                        <h4 className="text-center" style={{ width: '20rem' }}>
                            Change your password
                        </h4>
                        <Form
                            className="mt-5"
                            formValue={password}
                            model={model}
                            ref={formRef}
                            onChange={(value) => { setPassword(value) }}>
                            <FormGroup className="mb-3" >
                                <ControlLabel>Enter New Password</ControlLabel>
                                <FormControl type="password" placeholder="Password" name='pass1' />
                            </FormGroup>
                            <FormGroup className="mb-3" >
                                <ControlLabel>Repeat password</ControlLabel>
                                <FormControl type="password" placeholder="Password" name='pass2' />
                            </FormGroup>
                        </Form>
                        <Button style={{ marginBottom: '0.6rem' }}
                            className="btn-login shadow heavy"
                            type="submit"
                            onClick={changePassword}>
                            Save
                        </Button>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default RecoveryPass;