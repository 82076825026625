import React, { } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Switch } from 'antd';
import 'antd/dist/antd.css';

//Importar Recursos
import tuto1 from '../../assets/images/tuto1.png'

const Login = () => {
    return (
        <div className="d-flex justify-content-center
        d-flex align-items-center vh-100 login">
            <Card className="tutos border-0">
                <Card.Body>
                    <div
                    className="text-center">
                    <br/>
                        <h1>
                            Instant sessions
                        </h1>
                        <h4>
                            No previous appointment? No problem! 
                            Just hit the button and we'll find someone for you.
                        </h4>
                    <br/>
                        <Link
                        to="/register-alumno">
                            <Button className="btn" type="submit">
                                Register
                            </Button>
                        </Link>
                    </div>
                </Card.Body>
            </Card> 
        </div>
        )
    }
    
    export default Login;