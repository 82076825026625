import { Rate, Modal } from 'antd';
import React, { useState } from 'react'
import { Panel, Row, Col,  Avatar, Alert, TagGroup, Tag } from 'rsuite';
import { Badge, Button } from 'react-bootstrap';
import { BsFillCalendarFill, BsFillCaretRightFill } from "react-icons/bs";
import Axios from 'axios';
import { BASE_PATH } from '../constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BookSessions } from './BookSessions';

const StartSessionPerfil = (props) => {
    const history = useHistory()
    const [isModalVisibleS, setIsModalVisibleS] = useState(false);
    const showModalS = () => {
        setIsModalVisibleS(true);
    };

    const handleOkS = () => {
        setIsModalVisibleS(false);
    };

    const handleCancelS = () => {
        setIsModalVisibleS(false);
    };
    
    const onSuccess = () => {
        props.onSuccess();
        setIsModalVisibleS(false)
    }
    const onSubmit = async (post) => {
        try {
            let tutor = await Axios.post(`${BASE_PATH}/User/appointment`,
            {
                "appUserId": props.setData.id,
                "studentId": props.setStudenId.studentId,
                "appTypeId": 3,
                "status": 0,
                "tutorId": props.setLlamada.id,
                
            },{
            })
                if(tutor.status===200){
                    props.onSuccess();
                 //   props.alert();
                    Alert.success('session sent');
                    props.setAppointmenttt(tutor.data);
                    history.replace({ pathname: "/start-load", alumno: {alumno: props.setStudenId.studentId}, llamada: {llamada: tutor.data}})
                   // Alert.success("Paciente ingresado con éxito.", 1000);
                    
                } 
        }catch (e) { 
            console.log(e)
            if(e.response){
                if (e.response.status === 404 || e.response.status === 400) {
                  //  console.log(e.response.data.message);
                    Alert.warning(e.response.data.message,2000)
                }

            }
        }
    }
        
    const [arrayy, setArrayy] = useState([])
   const {themes} = props.setLlamada;
   const array = themes === null ? null: themes.split(",");
  // console.log(array)
   
   function Topic() {
    setArrayy(themes === null ? null: themes.split(","))
    return (
        <h1>{arrayy}</h1>
    );
   }
    return (
        <div
        className="d-flex justify-content-center text-center d-flex align-items-center">
            <section  style={{ paddingTop: '0px' }}
            className="text-center">
                <Row>
                    <Col>
                        <Avatar size="lg"
                        circle
                        className="avatar__startsession"
                        src={props.setLlamada.imageProfile&&props.setLlamada.imageProfile}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                <Col
                    className="ficha__startsession">
                    <h5
                    className="heavy">
                        {props.setLlamada.fullName}
                    </h5>
                    <br/>
                    <Rate disabled defaultValue={props.setLlamada.rating} />
                    <br/>
                    <br/>
                    Enjoys:
                    <h2
                    style={{
                    paddingTop:'0.4rem',
                    marginBottom:'-1rem',
                    width: '20rem'}}>
                        <TagGroup>
                           {array && array.map((item, index) => (
                            <Tag
                                key={index}
                            >
                                {item}
                            </Tag>
                            ))}
                        </TagGroup>
                    </h2>
                    <br/>
                    <br/>
                </Col>
                </Row>
                <div className="divider__space"></div>
                <Row
                className="d-flex justify-content-center">
                    <Col>
                        <Button
                        onClick={()=> onSubmit()}
                        className="
                        btn__go
                        shadow-lg
                        heavy">
                            Go
                            {' '}<BsFillCaretRightFill />
                        </Button>
                        <br/>
                        <Button
                        onClick={showModalS}
                        className="
                        heavy
                        btn__schedule
                        ">
                        <BsFillCalendarFill />{' '}
                         Schedule
                        </Button>
                    </Col>
                </Row>
                <Modal title="" 
                footer={false}
                visible={isModalVisibleS} 
                onOk={handleOkS} 
                onCancel={handleCancelS}>
                    <BookSessions setLlamada={props.setLlamada} setData={props.setData} setStudenId={props.setStudenId} onSuccess={onSuccess}/>
                </Modal>
            </section>    
        </div>
    )
}

export default StartSessionPerfil;